import React, { useState, createContext } from "react";

interface SearchProps {
  searchQuery: string;
  setSearchQuery: Function;
}

interface imageProps {
  photoDetail: any;
  setPhotoDetail: Function;
}
interface authProps {
  isAuthenticated: Boolean;
  setIsAuthenticated: Function;
}
interface userProps {
  user: any;
  setUser: Function;
}
interface publicUserProps {
  publicUser: any;
  setPublicUser: Function;
}
interface emailProps {
  availableEmail: string;
  setAvailableEmail: Function;
}

export const QueryContext = createContext<SearchProps>({} as SearchProps);
export const SelectedImageContext = createContext<imageProps>({} as imageProps);
export const AuthUser = createContext<userProps>({} as userProps);
export const PublicUser = createContext<publicUserProps>({} as publicUserProps);
export const UserEmail = createContext<emailProps>({} as emailProps);

export const ContextProvider = (props: any) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [photoDetail, setPhotoDetail] = useState({});
  const [user, setUser] = useState(null);
  const [publicUser, setPublicUser] = useState(null);
  const [availableEmail, setAvailableEmail] = useState("");

  return (
    <QueryContext.Provider value={{ searchQuery, setSearchQuery }}>
      <SelectedImageContext.Provider value={{ photoDetail, setPhotoDetail }}>
        <PublicUser.Provider value={{ publicUser, setPublicUser }}>
          <AuthUser.Provider value={{ user, setUser }}>
            <UserEmail.Provider value={{ availableEmail, setAvailableEmail }}>
              {props.children}
            </UserEmail.Provider>
          </AuthUser.Provider>
        </PublicUser.Provider>
      </SelectedImageContext.Provider>
    </QueryContext.Provider>
  );
};
