//@ts-nocheck
import React, { useState, useEffect } from "react";
import Navbar from "../common/Navbar";
import { Container, Spinner } from "react-bootstrap";
import Footer from "../common/Footer";
import { useHistory } from "react-router-dom";
import PhotoDetailsPopup from "./albums/PhotoDetailsPopup";
import { getPhotoDetails } from "../../api/PrivatePhotosAPI";
import { getFavourites, deleteFavourite } from "../../api/FavouriteAPI";
import axios from "axios";
import { getCSRFToken } from "../../auth/Authenticate";
import { MdClear } from "react-icons/md";
import ConfirmRemovePhotoDialog from "../common/ConfirmDialog";
import { toast } from "react-toastify";

const ProfileFavourites = () => {
  const [images, setImages] = useState([]);
  const [fullPhotoDetails, setFullPhotoDetails] = useState({});
  const [photoDetailsShow, setPhotoDetailsShow] = useState(false);
  const [lastObj, setLastObj] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [changedUser, setChangedUser] = useState(false);
  const [confirmRemovePhotoDialogShow, setConfirmRemovePhotoDialogShow] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  //@ts-ignore
  const history = useHistory();

  useEffect(() => {
    const myWidth = window.innerWidth;
    setWindowWidth(myWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const myWidth = window.innerWidth;
      setWindowWidth(myWidth);
    });
  }, [window]);

  useEffect(() => {
    getFavouriteImages();
  }, []);

  useEffect(() => {
    getThumbImage(images)
  }, [images]);

  const getFavouriteImages = () => {
    getFavourites(
      (response: any) => {
        let albumData = response.data;
        albumData.map(o => o.loading = true);
        setImages(albumData);
      },
      (error: any) => {
      }
    );
  }

  const getThumbImage = async (imagesArr) => {
    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    if (images.length > 0) {
      let i = 0;
      for (const photo of await images) {
        let getPhotosThumb = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/photos/${photo.id}/image?fullsize=false`,
          headerPhoto
        )
        .then((response) => {
          images[i].thumbnail = URL.createObjectURL(response.data);
        })
        .catch((error) => {
          images[i].thumbnail = (photo.public_urls.thumbnail);
        });
        images[i].loading = false;
        setLastObj(photo);
        i = i + 1;
      }
    }
  };

  const handleGetPhotoDetails = (photoId: string) => {
    getPhotoDetails(
      photoId,
      (response: any) => {
        let photoData = response.data;
        getPhotoData(
          photoId,
          true,
          (response: any) => {
            photoData["fullsize"] = URL.createObjectURL(response.data);
            setFullPhotoDetails(photoData);
          },
          (error: any) => {}
        );
      },
      (error: any) => {}
    );
  };

  const handlePhotoDetails = (id: string) => {
    history.push(`/photodetail/${id}`);
  };

  const handleRemovePhotoConfirmation = (photoId: any) => {
    if (photoId === "yes") {
      deleteFavourite(
        deletingId,
        (response: any) => {
          toast.dark("Photo removed from favourites");
          getFavouriteImages();
        },
        (error: any) => {
        }
      );
    } else {
      setDeletingId(photoId);
    }
    setConfirmRemovePhotoDialogShow(!confirmRemovePhotoDialogShow);
  };

  return (
    <div className="profile-area-main">
      <Navbar alwaysShow={true} hideSearch={true} navDropshadow={true} changedUser={changedUser}/>
      {/* <ProfileHeader changeUser={chnageUserStatus} /> */}
      <div className="search query-display" style={{marginTop:"100px", marginBottom:"0"}}>
        <h3>My Favourites</h3>
      </div>
      <Container>
        <div className="view-album-wrapper">
          <div className="album-all-images-display">
            {(images) ?
                images.map((photo) => (
                <div
                  style={{cursor:"auto"}}
                  className="pa-album-images-block"
                  key={photo.id}
                >
                  <div style={{display:"flex", justifyContent:"right", position:"absolute", width:"100%"}}>
                    <MdClear
                      className="addToCollectionsIcon"
                      style={{marginTop:"5px", marginLeft:"5px", color:"red", fontSize:"30px"}}
                      onClick={() => handleRemovePhotoConfirmation(photo.id)}
                    />
                  </div>
                    {photo.loading === false ? (
                    <>
                        <div style={{height:"100%", width:"100%", cursor:"pointer"}} onClick={() => handlePhotoDetails(photo.id)}><img src={photo.thumbnail} alt="pix album images" /></div>
                    </>
                    ) : (
                    <div className="album-spinner">
                      <Spinner
                        variant="dark"
                        animation="border"
                        role="status"
                        >
                      </Spinner>
                    </div>
                    )}
                </div>
                ))
                :
                null
            }
          </div>
        </div>
      </Container>

      <PhotoDetailsPopup
        handleGetPhotoDetails={handleGetPhotoDetails}
        // getAlbumData={getAlbumData}
        fullPhotoDetails={fullPhotoDetails}
        show={photoDetailsShow}
        onHide={() => {
          setPhotoDetailsShow(false);
          setFullPhotoDetails({});
        }}
      />
      <ConfirmRemovePhotoDialog
        title="Remove from favourite"
        message="Are you sure you want to remove this photo from favourites ?"
        // note="When an album is removed from the website all of its contents will be removed as well. The photos will not be visible to new users but users that have downloaded them will continue to have access."
        confirmBtnName="Remove"
        callback={handleRemovePhotoConfirmation}
        show={confirmRemovePhotoDialogShow}
        onHide={() => setConfirmRemovePhotoDialogShow(false)}
      />
      <Footer simpleFooter={true} />
    </div>
  );
};

export default ProfileFavourites;
