import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";
import qs from "qs";

export const addFavourite = (
  imageData: object,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/favourites`, qs.stringify(imageData), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getFavourites = (
    onSuccess: any,
    onError: any
  ) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/favourites`, {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  export const deleteFavourite = (
    photoId: string,
    onSuccess: any,
    onError: any
  ) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v1/favourites/?photo_id=${photoId}`, {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };