import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import Navbar from "../common/Navbar";
import ProfileHeader from "../common/ProfileHeader";
import { Container } from "react-bootstrap";
import "../../../styles/profile.css";
import ProfileAreaCollections from "./collections/ProfileAreaCollections";
import Footer from "../common/Footer";
import { AuthUser } from "../../context/ContextProvider";
import {
  getUserProfile,
} from "../../api/ProfileAreaAPIs";

const ProfileCollectionsArea = () => {
  const [albumCount, setAlbumCount] = useState("0");
  const { user, setUser } = useContext(AuthUser);
  const [changedUser, setChangedUser] = useState(false);
  const [role, setRole] = useState("");
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.user !== undefined && user === null) {
      let userData = JSON.parse(localStorage.user);
      setUser(userData);
    }
  }, [user]);

  const handleGetAlbumCount = (count: string) => {
    setAlbumCount(count);
  };

  const chnageUserStatus = () => {
    setChangedUser(!changedUser);
  };

  useEffect(() => {
    getUserProfile(
      (response: any) => {
        setRole(response.data.role);
        setUser(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
        setLoading(false);
      },
      (error: any) => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div>
        <div className="profile-area-main">
          <Navbar alwaysShow={true} hideSearch={true} navDropshadow={true} changedUser={changedUser} />
          {/* <ProfileHeader changeUser={chnageUserStatus}/> */}
          <div className="profile-area-wrapper" style={{marginTop:"100px"}}>
            <div className="search query-display">
              <h3>My Collections</h3>
            </div>
            <Container>
              <ProfileAreaCollections handleGetAlbumCount={handleGetAlbumCount} albumCount={albumCount} />
            </Container>
          </div>
        </div>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default ProfileCollectionsArea;
