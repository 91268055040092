import React, { useState } from "react";
import { Spinner, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { handleRequestPasswordReset } from "../../api/SignInAndSignUpAPI";

const RequestPasswordReset = (props: any) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState({
    emailError: false,
    emailErrorMsg: "",
  });

  const validate = () => {
    const mailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let status = {
      emailError: false,
      emailErrorMsg: "",
    };

    if (email === "") {
      status.emailError = true;
      status.emailErrorMsg = "Please enter your email address";
    } else if (!email.match(mailValidate)) {
      status.emailError = true;
      status.emailErrorMsg = "Please enter a valid email address";
    } else {
      status.emailError = false;
      status.emailErrorMsg = "";
    }

    if (status.emailError) {
      setErrors(status);
      return false;
    } else {
      setErrors(status);
      return true;
    }
  };

  const handleForgotPassword = () => {
    if (validate()) {
      setLoading(true);
      handleRequestPasswordReset(
        email,
        (response: any) => {
          setLoading(false);
          props.handleRequestSuccessful(email);
        },
        (error: any) => {
          setLoading(false);
          if (error.response && error.response.data) {
            const errorCode = error.response.data.code;
            setErrors({
              ...errors,
              emailError: true,
              emailErrorMsg:
                error.response.data.description +
                " | Error: " +
                error.response.data.code,
            });
          } else {
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        }
      );
    }
  };

  return (
    <div className="forgot-password-request-section">
      <h4>Forgot your password</h4>
      <p>
        In order to receive your access code by email, please enter the address
        you provided during the registration process.
      </p>

      <Form noValidate>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            placeholder="Enter email"
            required
            type="text"
            name="email"
            autoComplete="off"
            isInvalid={errors.emailError}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.emailErrorMsg}
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          className="pix-main-button"
          type="button"
          onClick={!isLoading ? handleForgotPassword : () => {}}
        >
          {isLoading ? (
            <>
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              ></Spinner>
              Please wait...
            </>
          ) : (
            "Request reset link"
          )}
        </Button>
      </Form>
    </div>
  );
};

export default RequestPasswordReset;
