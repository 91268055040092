import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { getCSRFToken } from "../../../auth/Authenticate";
import AlbumPlaceholder from "../../../../images/album-placeholder.jpg";
import Navbar from "../../common/Navbar";
import PublicProfileHeader from "./PublicProfileHeader";
import Footer from "../../common/Footer";

const PublicAreaAlbums = (props: any) => {
  //@ts-ignore
  const { id } = useParams();

  const [albums, setAlbums] = useState<any>([]);
  const [lastObj, setLastObj] = useState([]);

  const componentRef = useRef();

  useEffect(() => {
    handleGetPublicAlbums();
  }, []);

  const useContainerDimensions = (myRef: any) => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    });

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions());
      };

      if (myRef.current) {
        setDimensions(getDimensions());
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [myRef]);

    return dimensions;
  };

  const { width, height } = useContainerDimensions(componentRef);

  const handleGetPublicAlbums = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(`${process.env.REACT_APP_API_URL}/v1/public/albums?username=${id}`, header);
    //@ts-ignore
    let albumData = await getAlbum.data.filter((album) => album.thumbnail);
    setAlbums(albumData);

    if (albumData.length > 0) {
      for (const album of albumData) {
        let tempAlbum = album;

        if (!album.thumbnail_url) {
          let getPhotosThumb = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/public/photos/${album.thumbnail}/image?fullsize=false`,
            //@ts-ignore
            headerPhoto
          );
          tempAlbum["thumbnail"] = URL.createObjectURL(getPhotosThumb.data);
          tempAlbum["loading"] = false;
          setLastObj(tempAlbum);
        } else {
          tempAlbum["loading"] = false;
          setLastObj(tempAlbum);
        }
      }
    }
  };

  const albumThumbnail = (album: any) => {
    if (album.thumbnail_url) {
      return album.thumbnail_url;
    } else if (album.thumbnail) {
      return album.thumbnail;
    }
    return AlbumPlaceholder;
  };

  return (
    <div className="public-area-album-main">
      <Navbar alwaysShow={true} hideSearch={true} />
      <PublicProfileHeader publicArea={true} userId={id} />
      <Container>
        <div className="pa-all-albums-wrapper">
          {albums.length > 0
            ? albums.map((album: any) => (
                <Link
                  to={`/public/albums/view/${album.id}`}
                  key={album.id}
                  className="pa-available-albums-block"
                  //@ts-ignore
                  ref={componentRef}
                  style={
                    width === 0
                      ? //@ts-ignore
                        { height: componentRef?.current?.offsetWidth }
                      : { height: width }
                  }
                >
                  <div className="pa-available-albums-block-inner">
                    {album.deleted === true && album.loading === false ? <span className="deleted-album-text">Album Deleted</span> : null}
                    {album.loading === false ? (
                      <img src={albumThumbnail(album)} alt="Pix album thumbnail" />
                    ) : (
                      <div className="album-spinner">
                        <Spinner variant="dark" animation="border" role="status"></Spinner>
                      </div>
                    )}
                    <span className="album-name-display">{album.name}</span>
                  </div>
                </Link>
              ))
            : null}
        </div>
      </Container>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default PublicAreaAlbums;
