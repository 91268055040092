import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";
import { createCollection, addPhotosToACollection } from "../../../api/CollectionAPI";
import { MdInfoOutline } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import { getCSRFToken, isAuthenticated } from "../../../auth/Authenticate";
import { Link } from "react-router-dom";

const AddToCollectionModal = (props: any) => {
  const [newCollection, setNewCollection] = useState({
    name: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    titleError: false,
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [loadAlbum, setLoadAlbum] = useState(false);
  const [albumsLoading, setAlbumsLoading] = useState(true);
  const [albums, setAlbums] = useState<any>([]);
  const componentRef = useRef();

  useEffect(() => {
    if (isAuthenticated()) {
      handleGetCollections();
    }
  }, [props.photoId]);
  
  useEffect(() => {
    setNewCollection({
      name: "",
      description: "",
    });
    setLoading(false);
    setErrorStatus("");
    
  }, [props]);

  const validate = () => {
    let status = {
      titleError: false,
    };

    if (newCollection.name === "") {
      status.titleError = true;
    } else {
      status.titleError = false;
    }

    if (status.titleError) {
      setErrors(status);
      return false;
    } else {
      setErrors(status);
      return true;
    }
  };

  const handleAddPhotoToCollection = (collectionId : any) => {
    if (props.photoId) {
      setLoading(true);
      let imageInfo = {
        photos: props.photoId
      }
        addPhotosToACollection(
          collectionId,
          imageInfo,
          (response: any) => {
            setLoading(false);
            toast.success("Photo added to the collection");
            handleGetCollections();
            setTimeout(() => {
              props.onHide()
            }, 1000);
          },
          (error: any) => {
            setLoading(false);
            // if (error.response && error.response.data) {
            //   setErrorStatus(
            //     error.response.data.description +
            //       " | Error code: " +
            //       error.response.data.code
            //   );
            // } else {
            //   toast.error(`Something went wrong. Please try again. ${error}`);
            // }
          }
        );      
    }
  };

  const handleAddNewCollection = () => {
    if (validate()) {
      setLoading(true);
      let newCollectionData = newCollection;
        createCollection(
          newCollectionData,
          (response: any) => {
            setLoading(false);
            handleAddPhotoToCollection(response.data.id)
            handleGetCollections();
            setTimeout(() => {
              props.onHide()
            }, 1000);
          },
          (error: any) => {
            setLoading(false);
            // if (error.response && error.response.data) {
            //   setErrorStatus(
            //     error.response.data.description +
            //       " | Error code: " +
            //       error.response.data.code
            //   );
            // } else {
            //   toast.error(`Something went wrong. Please try again. ${error}`);
            // }
          }
        );
    }
  };

  const handleOnChange = (event: any) => {
    // @ts-ignore
    setNewCollection({
      ...newCollection,
      [event.target.name]: event.target.value,
    });
  };

  const useContainerDimensions = (myRef: any) => {
    const getDimensions = () => ({
      width: (myRef.current) ? myRef.current.offsetWidth : 0,
      height: (myRef.current) ? myRef.current.offsetHeight : 0,
    });

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions());
      };

      if (myRef.current) {
        setDimensions(getDimensions());
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [myRef]);

    return dimensions;
  };

  const { width, height } = useContainerDimensions(componentRef);
  const handleGetCollections = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/collections`,
      header
    );
    let albumData = await getAlbum.data;
    let tempAlbums = [];
    if (albumData.length > 0) {
      for (const album of albumData) {
          let tempAlbum = album;
          if (album.photos[0]) {
            tempAlbum['thumbnail'] = (album.photos && album.photos[album.photos.length - 1]) ? album.photos[album.photos.length - 1].public_urls.thumbnail : null;
          }
          tempAlbums.push(tempAlbum);
      }
      setAlbums(tempAlbums);
      setTimeout(() => {
        setLoadAlbum(false);
      }, 500);
    }
    setAlbumsLoading(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload-photo-popup"
    >
      <Modal.Header closeButton>
        Add To Collection
      </Modal.Header>
      <Modal.Body>
        {errorStatus !== "" ? (
          <Alert variant="danger">
            <MdInfoOutline /> {errorStatus}
          </Alert>
        ) : null}        

        <div>
          {!albumsLoading ? <div style={{minHeight:"50vh", maxHeight:"50vh", overflowY:"scroll"}}>
            {!loadAlbum ?
              <div className="pa-all-albums-wrapper">
                {albums.length > 0
                  ? albums.map((album: any) => (
                      <div
                        onClick={() => handleAddPhotoToCollection(album.id)}
                        key={album.id}
                        className={"pa-available-albums-block"}
                        //@ts-ignore
                        ref={componentRef}
                        style={
                          width === 0
                            ? //@ts-ignore
                              { height: componentRef?.current?.offsetWidth }
                            : { height: width }
                        }
                      >
                        <div className="pa-available-albums-block-inner">
                          {album.deleted === true && !loadAlbum ? (
                            <span className="deleted-album-text">Album Deleted</span>
                          ) : null}
                          {album.thumbnail && (
                            <img src={album.thumbnail} alt="PIX album thumbnail" />
                          )}
                          {loadAlbum && (
                            <div className="album-spinner">
                              <Spinner
                                variant="dark"
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          )}
                          <span className="album-name-display">{album.name}</span>
                        </div>
                      </div>
                    ))
                  : null}
              </div> :
              <div className="pa-all-albums-wrapper">
                {props.albumCount > 0
                  ? Array.apply(null, Array(props.albumCount)).map((album: any) => (
                      <div
                        className={"pa-available-albums-block"}
                        //@ts-ignore
                        ref={componentRef}
                        style={
                          width === 0
                            ? //@ts-ignore
                              { height: componentRef?.current?.offsetWidth }
                            : { height: width }
                        }
                      >
                        <div className="pa-available-albums-block-inner">
                          <div className="album-spinner">
                              <Spinner
                                variant="dark"
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            }          
          </div> :
          <div style={{minHeight:"50vh", maxHeight:"50vh", display:"flex", justifyContent:"center"}}>
            <Spinner
              variant="dark"
              animation="border"
              role="status"
            ></Spinner>
            </div>
          }
        </div>

        <div style={{marginTop:"10px", marginBottom:"30px"}}>
          <Link to="/profile/collections" className="view-collections-link">
            View my collections
          </Link>
        </div>

        <Form noValidate>
          <Form.Group>
            <Form.Label style={{fontWeight: "bold", color:"black"}}>Add to new collection</Form.Label>
            <Form.Control
              name="name"
              placeholder="Title"
              required
              type="text"
              autoComplete="off"
              isInvalid={errors.titleError}
              value={newCollection.name}
              onChange={(event) => handleOnChange(event)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide the Title for your collection
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            className="pix-main-button btn-normal-width"
            onClick={!isLoading ? handleAddNewCollection : () => {}}
          >
            {isLoading ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                ></Spinner>
                Please wait...
              </>
            ) : 
            "Create and Add"
            }
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddToCollectionModal;
