import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";
import { createANewAlbum, updateAlbum } from "../../../api/PrivateAlbumsAPI";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { SearchTagsAPI } from "../../../api/SearchAPI";
import { MdInfoOutline } from "react-icons/md";
import { toast } from "react-toastify";

const AddEditAlbumModal = (props: any) => {
  const [newAlbum, setNewAlbum] = useState({
    name: "",
    description: "",
    tags: "",
  });

  const [errors, setErrors] = useState({
    titleError: false,
  });

  const [tags, setTags] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [options, setOptions] = useState<any>([]);
  const [searchTagsLoading, setSearchTagsLoading] = useState(false);

  useEffect(() => {
    if (!props.isEditMode) {
      setNewAlbum({
        name: "",
        description: "",
        tags: "",
      });
      setLoading(false);
      setTags([]);
      setErrorStatus("");
    } else {
      setNewAlbum({
        name: props.albumData.name,
        description: props.albumData.description,
        tags: "",
      });
      setLoading(false);
      const properties =
        props.albumData.tags && props.albumData.tags.split(",");
      const tagsForEdit = properties ? properties : [];
      setTags(tagsForEdit);
    }
  }, [props.show]);

  const validate = () => {
    let status = {
      titleError: false,
    };

    if (newAlbum.name === "") {
      status.titleError = true;
    } else {
      status.titleError = false;
    }

    if (status.titleError) {
      setErrors(status);
      return false;
    } else {
      setErrors(status);
      return true;
    }
  };

  const handleAddNewAlbum = () => {
    if (validate()) {
      setLoading(true);
      let newAlbumData = newAlbum;
      const tempTags = tags
        .map((tag: any) => {
          if (tag.customOption === true) {
            return tag.label;
          } else {
            return tag;
          }
        })
        .toString();
      newAlbumData.tags = tempTags;

      if (!props.isEditMode) {
        createANewAlbum(
          newAlbumData,
          (response: any) => {
            setLoading(false);
            props.handleGetPrivateAlbums();
            setTimeout(() => {
              props.onHide()
            }, 1000);
          },
          (error: any) => {
            setLoading(false);
            if (error.response && error.response.data) {
              setErrorStatus(
                error.response.data.description
              );
            } else {
              toast.error(`Something went wrong. Please try again. ${error}`);
            }
          }
        );
      } else {
        const albumId = props.albumData.id;
        updateAlbum(
          albumId,
          newAlbumData,
          (response: any) => {
            setLoading(false);
            props.getAlbumData();
            setTimeout(() => {
              props.onHide()
            }, 1000);
          },
          (error: any) => {
            setLoading(false);
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        );
      }
    }
  };

  const handleOnChange = (event: any) => {
    // @ts-ignore
    setNewAlbum({
      ...newAlbum,
      [event.target.name]: event.target.value,
    });
  };

  const handleSearchTags = (query: any) => {
    setSearchTagsLoading(true);
    SearchTagsAPI(
      query,
      (response: any) => {
        setOptions(response.data);
        setSearchTagsLoading(false);
      },
      (error: any) => {
        setSearchTagsLoading(false);
      }
    );
  };

  const filterBy = () => true;

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload-photo-popup"
    >
      <Modal.Header closeButton>
        {props.isEditMode ? "Update Album" : "Add New Album"}
      </Modal.Header>
      <Modal.Body>
        {errorStatus !== "" ? (
          <Alert variant="danger">
            <MdInfoOutline /> {errorStatus}
          </Alert>
        ) : null}

        <Form noValidate>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="name"
              placeholder="Title"
              required
              type="text"
              autoComplete="off"
              isInvalid={errors.titleError}
              value={newAlbum.name}
              onChange={(event) => handleOnChange(event)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide the Title for your album
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description (Optional)</Form.Label>
            <Form.Control
              name="description"
              placeholder="Album Description"
              type="text"
              autoComplete="off"
              value={newAlbum.description}
              onChange={(event) => handleOnChange(event)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Tags (Optional)</Form.Label>
            <AsyncTypeahead
              allowNew
              multiple={true}
              filterBy={filterBy}
              id="select-photo-tags"
              isLoading={searchTagsLoading}
              minLength={2}
              onSearch={handleSearchTags}
              options={options}
              onChange={setTags}
              selected={tags}
              placeholder="Select tags"
            />
          </Form.Group>
        </Form>

        <div className="action-buttons">
          <Button
            className="pix-main-button btn-normal-width"
            onClick={!isLoading ? handleAddNewAlbum : () => {}}
          >
            {isLoading ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                ></Spinner>
                Please wait...
              </>
            ) : props.isEditMode ? (
              "Update Album"
            ) : (
              "Create Album"
            )}
          </Button>

          <Button variant="secondary" onClick={() => props.onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditAlbumModal;
