//@ts-nocheck
import React, { useState, useEffect } from "react";
import Navbar from "../../common/Navbar";
import ProfileHeader from "../../common/ProfileHeader";
import { Button, Container, Spinner, Tabs, Tab, Dropdown, DropdownButton, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  MdKeyboardArrowLeft,
} from "react-icons/md";
import {
  FaEllipsisH
} from "react-icons/fa";
import Footer from "../../common/Footer";
import PhotoUploadPopup from "./PhotoUploadPopup";
import { useParams } from "react-router-dom";
import { deleteAlbum } from "../../../api/PrivateAlbumsAPI";
import { getPhotoData } from "../../../api/PrivatePhotosAPI";
import AddEditAlbumModal from "./AddEditAlbumModal";
import { toast } from "react-toastify";
import ConfirmDialog from "../../common/ConfirmDialog";
import { useHistory } from "react-router-dom";
import PhotoDetailsPopup from "./PhotoDetailsPopup";
import { getPhotoDetails } from "../../../api/PrivatePhotosAPI";
import axios from "axios";
import { getCSRFToken } from "../../../auth/Authenticate";

const ViewTheAlbum = () => {
  const [modalShow, setModalShow] = useState(false);
  const [album, setAlbum] = useState({});
  const [editModalShow, setEditModalShow] = useState(false);
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [fullPhotoDetails, setFullPhotoDetails] = useState({});
  const [photoDetailsShow, setPhotoDetailsShow] = useState(false);
  const [lastObj, setLastObj] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [changedUser, setChangedUser] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(true);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [liveCount, setLiveCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [unpublishedCount, setUnpublishedCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [albumName, setAlbumName] = useState("");

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getAlbumData();
    const myWidth = window.innerWidth;
    setWindowWidth(myWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const myWidth = window.innerWidth;
      setWindowWidth(myWidth);
    });
  }, [window]);

  const getAlbumData = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/albums/${id}`,
      header
    );

    const albumData = await getAlbum.data;

    setAlbumName(getAlbum.data.name);

    let liveLength = albumData.photos.filter(
      (el) => el.status === "ACCEPTED"
    ).length;
    setLiveCount(liveLength);

    let reviewLength = albumData.photos.filter(
      (el) => el.status === "IN_REVIEW"
    ).length;
    setReviewCount(reviewLength);

    let unpublishedLength = albumData.photos.filter(
      (el) => el.status === "UNPUBLISHED"
    ).length;
    setUnpublishedCount(unpublishedLength);

    albumData.photos = albumData.photos.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    if (!albumData.deleted) {
      setDeleteStatus(false);
    }
    setAlbum(albumData);
    setPageLoading(false);

    if (albumData.photos.length > 0) {
      for (const photo of await albumData.photos) {
        let photoObj = {};
        photoObj = photo;

        let getPhotosThumb = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/photos/${photo.id}/image?fullsize=false`,
          headerPhoto
        );
        photoObj["thumbnail"] = URL.createObjectURL(getPhotosThumb.data);
        photoObj["loading"] = false;
        setLastObj(photoObj);
      }
    }
  };

  const handleAddNewPhotoToAlbum = async (uploadedPhoto) => {
    let currentAlbum = Object.assign({}, album);
    currentAlbum.photos.push(uploadedPhoto);
    setAlbum(currentAlbum);

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let photoObj = {};
    photoObj = uploadedPhoto;

    let getPhotosThumb = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/photos/${uploadedPhoto.id}/image?fullsize=false`,
      headerPhoto
    );
    photoObj["thumbnail"] = URL.createObjectURL(getPhotosThumb.data);
    photoObj["loading"] = false;

    let newAlbum = currentAlbum;
    let filtered = newAlbum.photos.filter(
      (photo) => photo.id !== uploadedPhoto.id
    );
    filtered.unshift(photoObj);
    const final = filtered.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    newAlbum.photos = final;
    setAlbum(newAlbum);
    setLastObj(photoObj);
  };

  const handleConfirmation = (yesOrNo: any) => {
    if (yesOrNo === "yes") {
      deleteAlbum(
        album.id,
        (response: any) => {
          toast.dark("Album Deleted successfully");
          history.push("/profile/albums");
        },
        (error: any) => {
          toast.error(`Something went wrong. Please try again. ${error}`);
        }
      );
    }
    setConfirmDialogShow(!confirmDialogShow);
  };

  const handleGetPhotoDetails = (photoId: string) => {
    getPhotoDetails(
      photoId,
      (response: any) => {
        let photoData = response.data;
        getPhotoData(
          photoId,
          true,
          (response: any) => {
            photoData["fullsize"] = URL.createObjectURL(response.data);
            setFullPhotoDetails(photoData);
          },
          (error: any) => {}
        );
      },
      (error: any) => {}
    );
  };

  const handlePhotoDetails = (photoDetails: object) => {
    history.push("/photo_detail_view/"+photoDetails.id);
  };

  const chnageUserStatus = () => {
    setChangedUser(!changedUser);
  };

  const navigateToReviewTab = () => {
    getAlbumData();
    document.getElementById("user-area-settings-tab-statistics").click();
  }

  return (
    <div className="profile-area-main">
      <Navbar alwaysShow={true} hideSearch={true} navDropshadow={true} changedUser={changedUser}/>
      <ProfileHeader changeUser={chnageUserStatus} />
      <Container>
        <div className="view-album-wrapper">
          <div className="view-album-header mb-3">
            <Link to="/profile/albums" className="back-to-albums-btn">
              <MdKeyboardArrowLeft /> Back
            </Link>
          </div>
          <Breadcrumb className="breadcrumb-div">
            <Breadcrumb.Item onClick={()=> history.replace(`/profile/albums`)}>Albums</Breadcrumb.Item>
            <Breadcrumb.Item active>{albumName}</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{background:"#f2f2f2", padding:"10px", marginBottom:"30px", borderRadius:"6px"}}>
            {pageLoading ?
              <div style={{display:"flex", justifyContent: "center"}}>
                <Spinner
                  animation="border"
                  className="home-page-loading-spinner"
                ></Spinner>
              </div>
              :
              <div style={{display: "flex", justifyContent:"space-between"}}>
                <div>
                  <h6 className="album-info-sec-title">{album?.name}</h6>
                  <p className="mb-3" style={{fontSize:"13px"}}>
                    {album && album.photos
                      ? (liveCount+reviewCount) + " Photo(s)"
                      : null}
                  </p>
                  {album?.tags &&
                    album?.tags.split(",").map((tag, i) => (
                      <span key={i} className="image-details-tags-display">
                        {tag}
                      </span>
                    ))
                  }
                </div>
                <div>
                  <DropdownButton
                    id="user-profile-dropdown"
                    title={<FaEllipsisH style={{color:"black"}}/>}
                    className="nav-links dropdown-arrow-hide"
                    style={{background:"#bdbdbd", borderRadius:"6px", color:"black", padding:"0px", paddingLeft:"5px", paddingRight:"5px"}}
                  >
                    <>
                      <Dropdown.Item eventKey="1" onClick={() => setEditModalShow(true)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="2" onClick={() => handleConfirmation("Yes or No")}>
                        Remove Album
                      </Dropdown.Item>
                    </>
                  </DropdownButton>
                </div>
              </div>
            }
            {showActionButtons ?
              <div className="private-image-details-action-buttons">
                <button
                  className="btn btn-outline-secondary mr-2"
                  onClick={() => setEditModalShow(true)}
                  style={{ width: 100 }}
                >
                  Edit
                </button>

                {(album && deleteStatus) ?
                  null
                  :
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => handleConfirmation("Yes or No")}
                    style={{ width: 100 }}
                  >
                    Delete
                  </button>
                }
              </div> : null }
          </div>
          <div className="add-photo-div">
            <Button onClick={() => history.push(`/profile/album/view/${album.id}/${album.name}`)} variant="link" style={{padding:"0", boxShadow:"none"}} className="upload-img-link">
              Add Photo to Album
            </Button>
          </div>
          <Tabs defaultActiveKey="albums" id="user-area-settings">
            <Tab eventKey="albums" title={`Live (${liveCount})`}>
              <div className="album-all-images-display">
                {(album && album.photos) ?
                  (album.photos).filter(opt => opt.status === "ACCEPTED").map((photo) => (
                    <div
                      className="pa-album-images-block"
                      key={photo.id}
                      onClick={() => handlePhotoDetails(photo)}
                    >
                      {photo.loading === false ? (
                        <>
                          <img src={photo.thumbnail} alt="pix album images" />
                        </>
                      ) : (
                        <div className="album-spinner">
                          <Spinner
                            variant="dark"
                            animation="border"
                            role="status"
                          ></Spinner>
                        </div>
                      )}
                    </div>
                  ))
                  :
                  null
                }
              </div>
            </Tab>
            <Tab eventKey="statistics" title={`In Review (${reviewCount})`}>
              <div className="album-all-images-display">
                {(album && album.photos) ?
                  (album.photos).filter(opt => opt.status === "IN_REVIEW").map((photo) => (
                    <div
                      className="pa-album-images-block"
                      key={photo.id}
                      onClick={() => handlePhotoDetails(photo)}
                    >
                      {photo.loading === false ? (
                        <>
                          <img src={photo.thumbnail} alt="pix album images" />
                        </>
                      ) : (
                        <div className="album-spinner">
                          <Spinner
                            variant="dark"
                            animation="border"
                            role="status"
                          ></Spinner>
                        </div>
                      )}
                    </div>
                  ))
                  :
                  null
                }
              </div>
            </Tab>
            {/* <Tab eventKey="payouts" title={`Unpublished (${unpublishedCount})`}>
              <div className="album-all-images-display">
                {(album && album.photos) ?
                  (album.photos).filter(opt => opt.status === "UNPUBLISHED").map((photo) => (
                    <div
                      className="pa-album-images-block"
                      key={photo.id}
                      onClick={() => handlePhotoDetails(photo)}
                    >
                      {photo.loading === false ? (
                        <>
                          <img src={photo.thumbnail} alt="pix album images" />
                        </>
                      ) : (
                        <div className="album-spinner">
                          <Spinner
                            variant="dark"
                            animation="border"
                            role="status"
                          ></Spinner>
                        </div>
                      )}
                    </div>
                  ))
                  :
                  null
                }
              </div>
            </Tab> */}
          </Tabs>
        </div>
      </Container>

      <PhotoDetailsPopup
        handleGetPhotoDetails={handleGetPhotoDetails}
        getAlbumData={getAlbumData}
        fullPhotoDetails={fullPhotoDetails}
        show={photoDetailsShow}
        onHide={() => {
          setPhotoDetailsShow(false);
          setFullPhotoDetails({});
        }}
      />
      <PhotoUploadPopup
        getAlbumData={getAlbumData}
        albumId={album?.id}
        show={modalShow}
        albumName={album?.name}
        handleAddNewPhotoToAlbum={handleAddNewPhotoToAlbum}
        onHide={() => setModalShow(false)}
        navigateToReviewTab={() => navigateToReviewTab()}
      />
      <AddEditAlbumModal
        getAlbumData={getAlbumData}
        isEditMode={true}
        albumData={album}
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      />
      <ConfirmDialog
        title={(liveCount+reviewCount) > 0 ? "Remove Album" : "Delete Album"}
        message={(liveCount+reviewCount) > 0 ? "Are you sure you want to remove this album and its contents from the website?" : "Are you sure you want to delete this album?"}
        note={(liveCount+reviewCount) > 0 ? "When an album is removed from the website all of its contents will be removed as well. The photos will not be visible to new users but users that have downloaded them will continue to have access." : ""}
        confirmBtnName="Delete"
        callback={handleConfirmation}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
      />
      <Footer simpleFooter={true} />
    </div>
  );
};

export default ViewTheAlbum;
