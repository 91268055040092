//@ts-nocheck
import React, { useState, useEffect } from "react";
import Navbar from "../../common/Navbar";
import ProfileHeader from "../../common/ProfileHeader";
import moment from "moment";
import { Alert, Container, Spinner, Badge, Dropdown, DropdownButton, Breadcrumb, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft, MdRemoveRedEye, MdInfoOutline, MdFileDownload, MdSend, MdUpdate, MdDelete, MdShare } from "react-icons/md";
import { FaEllipsisH } from "react-icons/fa";
import { ReactComponent as CameraIcon } from "../../../../images/icons/camera.svg";
import Footer from "../../common/Footer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getPhotoData, getPhotoDetails, deleteAPhoto, submitPhotosForReview, unpublishAPhotoAPI } from "../../../api/PrivatePhotosAPI";
import axios from "axios";
import { getCSRFToken } from "../../../auth/Authenticate";
import ConfirmDialog from "../../common/ConfirmDialog";
import UpdatePhotoDetailsModal from "./UpdatePhotoDetailsModal";
import ShareModal from "../../common/ShareModal";
import { Helmet } from "react-helmet";

const PhotoDetailView = () => {
  const [editModalShow, setEditModalShow] = useState(false);
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [changedUser, setChangedUser] = useState(false);
  const [fullPhotoDetails, setFullPhotoDetails] = useState({});
  const [albumId, setAlbumId] = useState("");
  const [albumName, setAlbumName] = useState("");
  const [photoName, setPhotoName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [action, setAction] = useState<string>("");
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [isShare, setIsShare] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const handleConfirmation = (yesOrNo: any) => {
    if (action === "Delete") {
      if (yesOrNo === "yes") {
        setLoading(true);
        deleteAPhoto(
          fullPhotoDetails.id,
          (response: any) => {
            setLoading(false);
            toast.dark("Photo Deleted successfully");
            history.push(`/profile/album/view/${albumId}`);
          },
          (error: any) => {
            if (error.response.data.code == 1306) {
              setLoading(false);
              toast.error(`You should have minimum 5 free photos in your account. Please upload another free photo and try again`);
            } else {
              setLoading(false);
              toast.error(`Something went wrong. Please try again. ${error}`);
            }
          }
        );
      }
    } else if (action === "Unpublish") {
      if (yesOrNo === "yes") {
        setLoading(true);
        unpublishAPhotoAPI(
          fullPhotoDetails.id,
          (response: any) => {
            setLoading(false);
            toast.dark("Photo Unpublished successfully");
            history.push(`/profile/album/view/${albumId}`);
          },
          (error: any) => {
            setLoading(false);
            if (error.response.data.code == 1306) {
              toast.error(`You should have minimum 5 free photos in your account. Please upload another free photo and try again`);
            } else {
              toast.error(`Something went wrong. Please try again. ${error}`);
            }
          }
        );
      }
    }
    setConfirmDialogShow(!confirmDialogShow);
  };

  //@ts-ignore

  useEffect(() => {
    handleGetPhotoDetails(id);
  }, [id]);

  useEffect(() => {
    if (albumId) {
      getAlbumData();
    }
  }, [albumId]);

  const getAlbumData = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(`${process.env.REACT_APP_API_URL}/v1/albums/${albumId}`, header);
    const albumData = await getAlbum.data;
    setAlbumName(albumData.name);
  };

  const getFocalLen = (lens: any) => {
    if (lens) {
      return `• ${lens}mm`;
    } else {
      return false;
    }
  };

  const getFnum = (fnum: any) => {
    if (fnum) {
      return `f/${fnum} `;
    } else {
      return false;
    }
  };

  const getISO = (iso: any) => {
    if (iso) {
      return `• ISO ${iso} `;
    } else {
      return false;
    }
  };

  const calcExposureTime = (expoTime: any) => {
    if (expoTime && expoTime >= 1) {
      return `• ${expoTime}`;
    } else if (expoTime && expoTime < 1) {
      let time = (1 / expoTime).toFixed();
      return `• 1/${time}s `;
    } else {
      return false;
    }
  };

  const handleGetPhotoDetails = (photoId: string) => {
    setPhotoName(photoId);
    setIsLoading(true);
    getPhotoDetails(
      photoId,
      (response: any) => {
        let photoData = response.data;
        getPhotoData(
          photoId,
          true,
          (response: any) => {
            photoData["fullsize"] = URL.createObjectURL(response.data);
            setFullPhotoDetails(photoData);
            if (photoData.name) {
              setPhotoName(photoData.name);
            }
            setAlbumId(photoData["album_id"]);
            setIsLoading(false);
          },
          (error: any) => {
            setIsLoading(false);
          }
        );
      },
      (error: any) => {}
    );
  };

  const chnageUserStatus = () => {
    setChangedUser(!changedUser);
  };

  const handleSubmitForReview = () => {
    setLoading(true);
    const imageId = [fullPhotoDetails.id];
    submitPhotosForReview(
      imageId,
      (response: any) => {
        setLoading(false);
        handleGetPhotoDetails(fullPhotoDetails.id);
        getAlbumData();
        toast.dark("Photo has been submitted to review");
      },
      (error: any) => {
        setLoading(false);
        if (error.response && error.response.data) {
          setErrorStatus(error.response.data.description);
        } else {
          toast.error(`Something went wrong. Please try again. ${error}`);
        }
      }
    );
  };

  const handleImageOperations = (action: string) => {
    setAction(action);
    handleConfirmation("Yes or No");
  };

  return (
    <div className="profile-area-main">
      <Helmet>
        <meta property="og:image" content={fullPhotoDetails?.public_urls?.thumbnail} />
      </Helmet>

      <Navbar alwaysShow={true} hideSearch={true} navDropshadow={true} changedUser={changedUser} />
      <ProfileHeader changeUser={chnageUserStatus} />
      {isLoading ? (
        <div className="album-spinner">
          <Spinner variant="dark" animation="border" role="status"></Spinner>
        </div>
      ) : (
        <Container>
          <div className="view-album-wrapper">
            <div className="view-album-header mb-3">
              <Link to={`/profile/album/view/${albumId}`} className="back-to-albums-btn">
                <MdKeyboardArrowLeft /> Back
              </Link>
            </div>

            <div className="image_info_wrapper">
              <Breadcrumb className="breadcrumb-div">
                <Breadcrumb.Item onClick={() => history.replace(`/profile/albums`)}>Albums</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.replace(`/profile/album/view/${albumId}`)}>{albumName}</Breadcrumb.Item>
                <Breadcrumb.Item style={{ fontFamily: "monoSpace" }} active>
                  {photoName}
                </Breadcrumb.Item>
              </Breadcrumb>

              {fullPhotoDetails?.status === "ACCEPTED" && (
                <div className="share_feature">
                  <Button className="share-photo-button" onClick={() => setIsShare(true)}>
                    <MdShare /> Share
                  </Button>
                </div>
              )}
            </div>
            <div>
              <div className="private-image-view">
                <img src={fullPhotoDetails.fullsize} alt="Pix free photos" className="img-fluid" style={{ width: "100%" }} />
              </div>
              <div style={{ background: "#f2f2f2", padding: "10px", marginBottom: "10px", marginTop: "10px", borderRadius: "6px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    {fullPhotoDetails.name ? (
                      <div>
                        <h6 style={{ fontWeight: "bold" }}>{fullPhotoDetails.name}</h6>
                      </div>
                    ) : null}
                    <div>
                      {fullPhotoDetails.status === "IN_REVIEW" ? (
                        <Badge pill bg="success" style={{ background: "#4169e1", color: "#ffff" }}>
                          In Review
                        </Badge>
                      ) : null}
                      {fullPhotoDetails.status === "PROCESSING" ? (
                        <Badge pill bg="success" style={{ background: "#219653", color: "#ffff" }}>
                          {fullPhotoDetails.status}
                        </Badge>
                      ) : null}
                      {fullPhotoDetails.status === "UPLOADED" ? (
                        <Badge pill bg="success" style={{ background: "#219653", color: "#ffff" }}>
                          {fullPhotoDetails.status}
                        </Badge>
                      ) : null}
                      {fullPhotoDetails.status === "ACCEPTED" ? (
                        <Badge pill bg="success" style={{ background: "#219653", color: "#ffff" }}>
                          Published
                        </Badge>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    {/* <FaEllipsisH  title="Actions" onClick={() => setShowActionButtons(!showActionButtons)} style={{cursor:"pointer", background:"#bdbdbd", padding:"6px", borderRadius:"6px", fontSize: "28px"}} /> */}
                    <DropdownButton
                      align="end"
                      id="user-profile-dropdown"
                      title={<FaEllipsisH style={{ color: "black" }} />}
                      className="nav-links dropdown-arrow-hide"
                      style={{ background: "#bdbdbd", borderRadius: "6px", color: "black", padding: "0px", paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <>
                        <Dropdown.Item eventKey="1" onClick={() => setEditModalShow(true)}>
                          Edit
                        </Dropdown.Item>
                        {fullPhotoDetails.status !== "UNPUBLISHED" ? (
                          fullPhotoDetails.status === "ACCEPTED" ? (
                            <Dropdown.Item eventKey="2" onClick={() => handleImageOperations("Unpublish")}>
                              Remove from Website
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item eventKey="2" onClick={() => handleImageOperations("Delete")}>
                              Remove from Website
                            </Dropdown.Item>
                          )
                        ) : null}
                      </>
                    </DropdownButton>
                  </div>
                </div>
                <div>
                  {fullPhotoDetails.status === "IN_REVIEW" || fullPhotoDetails.status === "PROCESSING" || fullPhotoDetails.status === "UPLOADED" ? null : (
                    <div className="photo-detail-using-count">
                      {fullPhotoDetails ? (
                        <div className="download-count">
                          <span>
                            <MdFileDownload />
                          </span>
                          {fullPhotoDetails.download_count}
                        </div>
                      ) : null}
                      {fullPhotoDetails.view_count ? (
                        <div className="download-count">
                          <span>
                            <MdRemoveRedEye />
                          </span>
                          {fullPhotoDetails.view_count}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
                {showActionButtons ? (
                  <div className="private-image-details-action-buttons">
                    {fullPhotoDetails.status === "IN_REVIEW" || fullPhotoDetails.status === "PROCESSING" ? (
                      <Alert variant="info">
                        <MdInfoOutline /> {fullPhotoDetails.status === "IN_REVIEW" ? "Review in Process" : fullPhotoDetails.status === "PROCESSING" ? "Your Photo is in Publishing Process" : null}
                      </Alert>
                    ) : (
                      <>
                        {fullPhotoDetails.status === "UPLOADED" ? (
                          <button className="btn submit-for-review-btn" onClick={handleSubmitForReview}>
                            <MdSend /> Submit for Review
                          </button>
                        ) : null}

                        <button className="btn btn-outline-secondary" onClick={() => setEditModalShow(true)}>
                          <MdUpdate /> Update Info
                        </button>
                        {fullPhotoDetails.status == "UPLOADED" ? (
                          <button className="btn btn-outline-danger" onClick={!isLoading ? () => handleImageOperations("Delete") : () => {}}>
                            {isLoading ? (
                              <>
                                <Spinner animation="border" role="status" variant="light" size="sm"></Spinner>
                                Please wait...
                              </>
                            ) : (
                              <>
                                <MdDelete /> Delete
                              </>
                            )}
                          </button>
                        ) : null}

                        {fullPhotoDetails.status === "ACCEPTED" ? (
                          <button className="btn btn-outline-danger" onClick={!isLoading ? () => handleImageOperations("Unpublish") : () => {}}>
                            {isLoading ? (
                              <>
                                <Spinner animation="border" role="status" variant="light" size="sm"></Spinner>
                                Please wait...
                              </>
                            ) : (
                              <>
                                <MdDelete /> Delete
                              </>
                            )}
                          </button>
                        ) : null}
                      </>
                    )}
                  </div>
                ) : null}
              </div>
              <div>
                <div style={{ fontWeight: "bold", marginTop: "20px" }}>Price</div>
                <div className="">{fullPhotoDetails.free_download ? "Free" : "Paid"}</div>
              </div>
              <div>
                <div style={{ fontWeight: "bold", marginTop: "20px" }}>Album</div>
                <div className="">{albumName}</div>
              </div>
              {fullPhotoDetails.description ? (
                <div>
                  <div style={{ fontWeight: "bold", marginTop: "10px" }}>Description</div>
                  <div className="">{fullPhotoDetails.description}</div>
                </div>
              ) : null}
              <div className="pd-keywords">
                <div style={{ fontWeight: "bold", marginTop: "10px" }}>Keywords</div>
                <div className="pd-keywords-wrapper">
                  {fullPhotoDetails.tags &&
                    fullPhotoDetails.tags.map((el: any, i: any) => (
                      <div className="pd-keyword-item" key={i}>
                        {el}
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <div style={{ fontWeight: "bold", marginTop: "10px" }}>Category</div>
                <div className="pd-keywords-wrapper">{fullPhotoDetails.category ? fullPhotoDetails.category.name : ""}</div>
              </div>
              <div className="pd-metadata-wrapper" style={{ marginTop: "10px", marginBottom: "20px" }}>
                {(fullPhotoDetails.metadata && fullPhotoDetails.metadata.camera_make) || (fullPhotoDetails.metadata && fullPhotoDetails.metadata.camera_model) ? (
                  <div className="pd-metadata-header">
                    <div className="pd-metadata-header-icon">
                      <CameraIcon />
                    </div>

                    <div className="pd-metadata-header-info">
                      <div className="pd-metadata-header-info-title">
                        {fullPhotoDetails.metadata && fullPhotoDetails.metadata.camera_make && fullPhotoDetails.metadata.camera_make} $
                        {fullPhotoDetails.metadata && fullPhotoDetails.metadata.camera_model && fullPhotoDetails.metadata.camera_model}
                      </div>
                      {(fullPhotoDetails.metadata && fullPhotoDetails.metadata.focal_length_35) ||
                      (fullPhotoDetails.metadata && fullPhotoDetails.metadata.fnum) ||
                      (fullPhotoDetails.metadata && fullPhotoDetails.metadata.iso) ||
                      (fullPhotoDetails.metadata && fullPhotoDetails.metadata.exposure_time) ? (
                        <div className="pd-metadata-header-info-subinfo">
                          {getFnum(fullPhotoDetails.metadata && fullPhotoDetails.metadata.fnum && fullPhotoDetails.metadata.fnum)}
                          {getISO(fullPhotoDetails.metadata && fullPhotoDetails.metadata.iso && fullPhotoDetails.metadata.iso)}
                          {calcExposureTime(fullPhotoDetails.metadata && fullPhotoDetails.metadata.exposure_time && fullPhotoDetails.metadata.exposure_time)}
                          {getFocalLen(fullPhotoDetails.metadata && fullPhotoDetails.metadata.focal_length_35 && fullPhotoDetails.metadata.focal_length_35)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="pd-metadata-full-details">
                  <table style={{ fontSize: "16px" }}>
                    <tr style={{ borderBottom: "none" }}>
                      <td>Resolution</td>
                      <td>
                        {fullPhotoDetails.metadata && fullPhotoDetails.metadata.width} x {fullPhotoDetails.metadata && fullPhotoDetails.metadata.height}
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "none" }}>
                      <td>Image Type</td>
                      <td>JPEG</td>
                    </tr>
                    <tr style={{ borderBottom: "none" }}>
                      <td>Created</td>
                      <td>{moment.utc(fullPhotoDetails.created_at).local().format("MMM DD, YYYY")}</td>
                    </tr>
                    <tr style={{ borderBottom: "none" }}>
                      <td>Views</td>
                      <td>{fullPhotoDetails.view_count}</td>
                    </tr>
                    <tr style={{ borderBottom: "none" }}>
                      <td>Downloads</td>
                      <td>{fullPhotoDetails.download_count}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      <ConfirmDialog
        title={fullPhotoDetails.status === "ACCEPTED" ? "Remove Photo" : "Delete Photo"}
        message={fullPhotoDetails.status === "ACCEPTED" ? " Are you sure you want to remove this photo from the website?" : "Are you sure you want to delete this photo?"}
        note={
          fullPhotoDetails.status === "ACCEPTED"
            ? "When a photo is removed from the website new users will not be able to view or download it but users who have downloaded it before will continue to have access to it."
            : ""
        }
        confirmBtnName="Delete"
        callback={handleConfirmation}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
      />

      <UpdatePhotoDetailsModal handleGetPhotoDetails={handleGetPhotoDetails} isEditMode={true} photoData={fullPhotoDetails} show={editModalShow} onHide={() => setEditModalShow(false)} />

      <ShareModal show={isShare} onHide={() => setIsShare(false)} title="Share" size="md" photoData={fullPhotoDetails} />

      <Footer simpleFooter={true} />
    </div>
  );
};

export default PhotoDetailView;
