import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { getCSRFToken } from "../../../auth/Authenticate";
import AddEditCollectionModal from "./AddEditCollectionModal";

const ProfileAreaCollections = (props: any) => {
  const [modalShow, setModalShow] = useState(false);
  const [loadAlbum, setLoadAlbum] = useState(false);
  const [albums, setAlbums] = useState<any>([]);

  const componentRef = useRef();

  useEffect(() => {
    setLoadAlbum(true);
    handleGetCollections();
  }, []);

  const useContainerDimensions = (myRef: any) => {
    const getDimensions = () => ({
      width: (myRef.current) ? myRef.current.offsetWidth : 0,
      height: (myRef.current) ? myRef.current.offsetHeight : 0,
    });

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions());
      };

      if (myRef.current) {
        setDimensions(getDimensions());
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [myRef]);

    return dimensions;
  };

  const { width, height } = useContainerDimensions(componentRef);

  const handleGetCollections = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/collections`,
      header
    );
    let albumData = await getAlbum.data;
    // let activeAlbumData = albumData.filter(
    //   (el: { deleted: boolean; }) => el.deleted === false
    // );
    props.handleGetAlbumCount(albumData.length);

    let tempAlbums = [];
    if (albumData.length > 0) {
      for (const album of albumData) {
          let tempAlbum = album;
          if (album.photos[0]) {
            tempAlbum['thumbnail'] = (album.photos && album.photos[album.photos.length - 1]) ? album.photos[album.photos.length - 1].public_urls.thumbnail : null;
          }
          tempAlbums.push(tempAlbum);
      }
      setAlbums(tempAlbums);
      setTimeout(() => {
        setLoadAlbum(false);
      }, 500);
    }
  };

  return (
    <div>
      {!loadAlbum ?
        <div className="pa-all-albums-wrapper">
          <div
            className="pa-add-new-album-block"
            onClick={() => setModalShow(true)}
            style={
              width === 0
                ? //@ts-ignore
                  { height: componentRef?.current?.offsetWidth }
                : { height: width }
            }
          >
            + Add New Collection
          </div>
          {albums.length > 0
            ? albums.map((album: any) => (
                <Link
                  to={`/profile/collection/view/${album.id}`}
                  key={album.id}
                  className={"pa-available-albums-block"}
                  //@ts-ignore
                  ref={componentRef}
                  style={
                    width === 0
                      ? //@ts-ignore
                        { height: componentRef?.current?.offsetWidth }
                      : { height: width }
                  }
                >
                  <div className="pa-available-albums-block-inner">
                    {album.deleted === true && !loadAlbum ? (
                      <span className="deleted-album-text">Album Deleted</span>
                    ) : null}
                    {album.thumbnail && (
                      <img src={album.thumbnail} alt="PIX album thumbnail" />
                    )}
                    {loadAlbum && (
                      <div className="album-spinner">
                        <Spinner
                          variant="dark"
                          animation="border"
                          role="status"
                        ></Spinner>
                      </div>
                    )}
                    <span className="album-name-display">{album.name}</span>
                  </div>
                </Link>
              ))
            : null}
        </div> :
        <div className="pa-all-albums-wrapper">
          <div
            className="pa-add-new-album-block"
            onClick={() => setModalShow(true)}
            style={
              width === 0
                ? //@ts-ignore
                  { height: componentRef?.current?.offsetWidth }
                : { height: width }
            }
          >
            + Add New Collection
          </div>
          {props.albumCount > 0
            ? Array.apply(null, Array(props.albumCount)).map((album: any) => (
                <div
                  className={"pa-available-albums-block"}
                  //@ts-ignore
                  ref={componentRef}
                  style={
                    width === 0
                      ? //@ts-ignore
                        { height: componentRef?.current?.offsetWidth }
                      : { height: width }
                  }
                >
                  <div className="pa-available-albums-block-inner">
                    <div className="album-spinner">
                        <Spinner
                          variant="dark"
                          animation="border"
                          role="status"
                        ></Spinner>
                      </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      }
      <AddEditCollectionModal
        handleGetCollections={handleGetCollections}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ProfileAreaCollections;
