import React from 'react'
import Footer from '../common/Footer'
import Navigation from '../common/Navbar'
import { Gallery } from '../home/Gallery'
import JoinUs from '../home/JoinUs'
import MainComponent from '../home/MainComponent'

const HomePageComponent = () => {
    return (
        <>
            <Navigation />
            <MainComponent />
            <Gallery />
            <JoinUs />
            <Footer />
        </>
    )
}

export default HomePageComponent
