//@ts-nocheck
import React, { useEffect, useState } from "react";
import "../../../../styles/profile.css";
import userImage from "../../../../images/user.jpg";
import userBlank from "../../../../images/user-blank.jpg";
import { Spinner } from "react-bootstrap";
import { getPublicUserProfile } from "../../../api/PublicProfileAreaAPIs";

const PublicProfileHeader = (props: any) => {
  const [publicUser, setPublicUser] = useState([]);
  const [profileLoading, setProfileLoading] = useState(true);

  useEffect(() => {
    if (props.userId) {
      getPublicUser();
    }
  }, [props.userId]);

  const getPublicUser = () => {
    getPublicUserProfile(
      props.userId,
      (response: any) => {
        setPublicUser(response.data);
        setProfileLoading(false);
      },
      (error: any) => {
        setProfileLoading(false);
      }
    );
  };

  return (
    <div className="profile-header-wrapper">
      <div
        className="profile-header-main"
        style={{
          backgroundImage: `url(${
            publicUser && publicUser.cover_photo
              ? `${publicUser.cover_photo}?fullsize=true`
              : null
          })`,
        }}
      ></div>

      <div className="profile-user">
        <div className="profile-user-image-main">
          {profileLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner animation="border"></Spinner>
            </div>
          )}
          <img
            src={
              publicUser && publicUser.profile_photo
                ? `${publicUser.profile_photo}?fullsize=true`
                : profileLoading
                ? userBlank
                : userImage
            }
            alt="pix user image"
            className="profile-user-image"
          />
        </div>
        {publicUser && publicUser.first_name && (
          <h5 className="profile-user-name">
            {publicUser && publicUser?.first_name ? publicUser.first_name : "" + " " + publicUser?.last_name ? publicUser.last_name : ""}
          </h5>
        )}
      </div>
    </div>
  );
};

export default PublicProfileHeader;
