import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import {
    checkUsernameAvailability,
  } from "../../api/SignInAndSignUpAPI";
import {
  getUserProfile,
  updateUserName,
} from "../../api/ProfileAreaAPIs";

const ChangeUserDetailsModal = (props: any) => {
  const [user, setUser] = useState({
    userName:"",
    firstName:"",
    lastName:"",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userNameError, setUserNameError] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [userExists, setUserExists] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      let userObjStr = localStorage.getItem("user");
      if (userObjStr) {
        const obj = JSON.parse(userObjStr);
        setUser({
          ...user,
          userName: obj.username,
          firstName: obj.first_name,
          lastName: obj.last_name,
        });
        setUserNameError("");
        setFirstNameError("");
        setLastNameError("");
      }
    }
  }, [props]);

  useEffect(() => {
    const delayCheckUsernameFn = setTimeout(() => {
      setUserNameError("");
      checkUsernameAvailability(
        user.userName,
        (response: any) => {
          if (!response.data.value) {
            setUserNameError("Usename exists");
            setUserExists(true);
          } else {
            setUserExists(false);
          }
        },
        (error: any) => {}
      );
    }, 1000);
    return () => clearTimeout(delayCheckUsernameFn);
  }, [user.userName]);

  const validate = () => {
    setLastNameError("");
    setFirstNameError("");
    let isInvalid = false;
    if (user.userName == "") {
      isInvalid = true;
      setUserNameError("Username is required");
    }
    if (user.firstName == "") {
      isInvalid = true;
      setFirstNameError("First Name is required");
    }
    if (user.lastName == "") {
      isInvalid = true;
      setLastNameError("Last Name is required");
    }
    if (isInvalid || userExists) {
      return false;
    } else {
      return true;
    }
  };

  const changeUserName = (event: any) => {
    setUser({
        ...user,
        [event.target.name]: event.target.value,
    });
    // setUserNameError("");
    // checkUsernameAvailability(
    //   event.target.value,
    //     (response: any) => {
    //       if (!response.data.value) {
    //         setUserNameError("Usename exists");
    //       }
    //     },
    //     (error: any) => {}
    //   );
  };

  const handleEditUser = () => {
    if (validate()) {
      setIsLoading(true);
      updateUserName(
        {username: user.userName},
        (response: any) => {
          toast.success("Username updated successfully");
          setTimeout(() => {
            props.onHide()
          }, 1000);
          getUserProfile(
            (response: any) => {
              localStorage.setItem("user", JSON.stringify(response.data));
              props.changedUser();
            },
            (error: any) => {
            }
            );
          setIsLoading(false);
        },
        (error: any) => {
          setIsLoading(false);
          toast.error(`Something went wrong. Please try again. ${error}`);
        }
      );
    } else {
      setIsLoading(false);
    }
  };

  const handleOnChange = (event: any) => {
    // @ts-ignore
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload-photo-popup"
    >
      <Modal.Header closeButton>
        Change User Details
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              name="userName"
              placeholder="Username"
              required
              type="text"
              autoComplete="off"
              isInvalid={(userNameError) ? true : false}
              value={user.userName}
              onChange={(event) => changeUserName(event)}
            />
            <Form.Control.Feedback type="invalid">
              {userNameError}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="firstName"
              placeholder="First Name"
              type="text"
              autoComplete="off"
              isInvalid={(firstNameError) ? true : false}
              value={user.firstName}
              onChange={(event) => handleOnChange(event)}
            />
            <Form.Control.Feedback type="invalid">
              {firstNameError}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="lastName"
              placeholder="Last Name"
              type="text"
              autoComplete="off"
              value={user.lastName}
              isInvalid={(lastNameError) ? true : false}
              onChange={(event) => handleOnChange(event)}
            />
            <Form.Control.Feedback type="invalid">
              {lastNameError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>

        <div className="action-buttons">
          <Button
            className="pix-main-button btn-normal-width"
            onClick={!isLoading ? handleEditUser : () => {}}
          >
            {isLoading ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                ></Spinner>
                Please wait...
              </>
            ) : ("Update User")
            }
          </Button>

          <Button variant="secondary" onClick={() => props.onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeUserDetailsModal;
