// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  MdInfo,
  MdSend,
  MdRemoveRedEye,
  MdFileDownload,
  MdUpdate,
  MdDelete,
  MdInfoOutline,
} from "react-icons/md";
import { FaEyeSlash } from "react-icons/fa";
import {
  deleteAPhoto,
  submitPhotosForReview,
  unpublishAPhotoAPI,
} from "../../../api/PrivatePhotosAPI";
import ConfirmDialog from "../../common/ConfirmDialog";
import moment from "moment";
import UpdatePhotoDetailsModal from "./UpdatePhotoDetailsModal";
import userImage from "../../../../images/user.png";

const PhotoDetailsPopup = (props: any) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [action, setAction] = useState<string>("");

  const handleConfirmation = (yesOrNo: any) => {
    if (action === "Delete") {
      if (yesOrNo === "yes") {
        setLoading(true);
        deleteAPhoto(
          props.fullPhotoDetails.id,
          (response: any) => {
            setLoading(false);
            toast.dark("Photo Deleted successfully");
            props.getAlbumData();
            props.onHide();
          },
          (error: any) => {
            setLoading(false);
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        );
      }
    } else if (action === "Unpublish") {
      if (yesOrNo === "yes") {
        setLoading(true);
        unpublishAPhotoAPI(
          props.fullPhotoDetails.id,
          (response: any) => {
            setLoading(false);
            toast.dark("Photo Unpublished successfully");
            props.getAlbumData();
            props.onHide();
          },
          (error: any) => {
            setLoading(false);
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        );
      }
    }
    setConfirmDialogShow(!confirmDialogShow);
  };

  const handleSubmitForReview = () => {
    setLoading(true);
    const imageId = [props.fullPhotoDetails.id];
    submitPhotosForReview(
      imageId,
      (response: any) => {
        setLoading(false);
        props.handleGetPhotoDetails(props.fullPhotoDetails.id);
        props.getAlbumData();
        toast.dark("Photo has been submitted to review");
      },
      (error: any) => {
        setLoading(false);
        if (error.response && error.response.data) {
          setErrorStatus(
            error.response.data.description
          );
        } else {
          toast.error(`Something went wrong. Please try again. ${error}`);
        }
      }
    );
  };

  const handleImageOperations = (action: string) => {
    setAction(action);
    handleConfirmation("Yes or No");
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="photo-details-popup"
      >
        <Modal.Header closeButton>
          <h6>{props.fullPhotoDetails.name}</h6>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(props.fullPhotoDetails).length == 0 ? (
            <div className="photo-details-spinner">
              <Spinner
                variant="dark"
                animation="border"
                role="status"
              ></Spinner>
            </div>
          ) : (
            <>
              <div className="private-image-view">
                <img
                  src={props.fullPhotoDetails.fullsize}
                  alt="Pix free photos"
                  className="img-fluid"
                />
                {props.fullPhotoDetails.status === "IN_REVIEW" ||
                props.fullPhotoDetails.status === "PROCESSING" ||
                props.fullPhotoDetails.status === "UPLOADED" ? null : (
                  <div className="image-view-simple-info">
                    <h6 className="info-view-count-small">
                      <MdRemoveRedEye />
                      {props.fullPhotoDetails &&
                        props.fullPhotoDetails.view_count}{" "}
                      views
                    </h6>
                    <h6 className="info-view-count-small">
                      <MdInfoOutline />
                      {props.fullPhotoDetails && props.fullPhotoDetails.status}
                    </h6>
                  </div>
                )}
              </div>

              <div className="private-image-details-action-buttons">
                {props.fullPhotoDetails.status === "IN_REVIEW" ||
                props.fullPhotoDetails.status === "PROCESSING" ? (
                  <Alert variant="info">
                    <MdInfoOutline />{" "}
                    {props.fullPhotoDetails.status === "IN_REVIEW"
                      ? "Review in Process"
                      : props.fullPhotoDetails.status === "PROCESSING"
                      ? "Your Photo is in Publishing Process"
                      : null}
                  </Alert>
                ) : (
                  <>
                    {props.fullPhotoDetails.status === "UPLOADED" ? (
                      <button
                        className="btn submit-for-review-btn"
                        onClick={handleSubmitForReview}
                      >
                        <MdSend /> Submit for Review
                      </button>
                    ) : null}

                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => setEditModalShow(true)}
                    >
                      <MdUpdate /> Update Info
                    </button>

                    {props.fullPhotoDetails.status == "UPLOADED" ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={
                          !isLoading
                            ? () => handleImageOperations("Delete")
                            : () => {}
                        }
                      >
                        {isLoading ? (
                          <>
                            <Spinner
                              animation="border"
                              role="status"
                              variant="light"
                              size="sm"
                            ></Spinner>
                            Please wait...
                          </>
                        ) : (
                          <>
                            <MdDelete /> Delete
                          </>
                        )}
                      </button>
                    ) : null}

                    {props.fullPhotoDetails.status === "ACCEPTED" ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={
                          !isLoading
                            ? () => handleImageOperations("Unpublish")
                            : () => {}
                        }
                      >
                        {isLoading ? (
                          <>
                            <Spinner
                              animation="border"
                              role="status"
                              variant="light"
                              size="sm"
                            ></Spinner>
                            Please wait...
                          </>
                        ) : (
                          <>
                            <MdDelete /> Delete
                          </>
                        )}
                      </button>
                    ) : null}
                  </>
                )}
              </div>

              <div className="private-image-details">
                <h6 className="private-image-details-title">
                  <MdInfo /> Info
                </h6>
                <div className="image-details-card-main">
                  <div className="image-details-card-main-inner">
                    <div className="image-details-photographer image-details-card">
                      <h6 className="image-details-card-title">Photographer</h6>
                      <div className="image-details-card-profile">
                        <div className="image-details-card-profile-photo">
                          {props.fullPhotoDetails?.uploader?.profile_photo ? (
                            <img
                              src={
                                props.fullPhotoDetails.uploader.profile_photo
                              }
                              alt="pix profile photo"
                            />
                          ) : (
                            <img src={userImage} alt="pix profile photo" />
                          )}
                        </div>
                        <div>
                          <h6 class="image-details-card-inner-title">
                            {props.fullPhotoDetails?.uploader?.first_name}{" "}
                            {props.fullPhotoDetails?.uploader?.last_name}
                          </h6>
                          <h6 class="image-details-card-inner-subtitle">
                            {props.fullPhotoDetails?.uploader?.username}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="image-details-card">
                      <h6 className="image-details-card-title">Statistics</h6>
                      <div className="image-details-card-image-info">
                        <div className="info-view-count">
                          <MdRemoveRedEye />
                          <div className="statistics-view-count">
                            {props.fullPhotoDetails?.view_count}
                          </div>
                        </div>
                        <div className="statistics-download-count">
                          <MdFileDownload />
                          {props.fullPhotoDetails?.download_count}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="image-details-card-main-inner">
                    <div className="image-details-card">
                      <h6 className="image-details-card-title">
                        Photo Information
                      </h6>
                      <div className="photo-info-wrapper">
                        <h6 class="image-details-card-inner-title">
                          {props.fullPhotoDetails?.name}
                        </h6>

                        <h6 class="image-details-card-inner-subtitle-2">
                          {props.fullPhotoDetails?.description}
                        </h6>

                        <h6 class="image-details-card-inner-subtitle">
                          Category: {props.fullPhotoDetails?.category?.name}
                        </h6>

                        <h6 class="image-details-card-inner-subtitle-2">
                          {props.fullPhotoDetails?.category?.desription}
                        </h6>

                        <h6 class="image-details-card-inner-subtitle">
                          Tags:{" "}
                          {props.fullPhotoDetails?.tags?.map((tag) => (
                            <span className="image-details-tags-display">
                              {tag}
                            </span>
                          ))}
                        </h6>

                        <h6 class="image-details-card-inner-subtitle">
                          Uploaded on{" "}
                          {moment(props.fullPhotoDetails?.created_at).format(
                            "MMMM Do YYYY"
                          )}
                        </h6>
                      </div>

                      <div className="image-details-card-image-info">
                        <div className="image-details-card-image-info-inner">
                          <table>
                            <tr>
                              <td class="image-details-card-inner-subtitle">
                                Resolution
                              </td>
                              <td class="image-details-card-inner-title">
                                {props.fullPhotoDetails?.metadata?.width}
                                px x&nbsp;
                                {props.fullPhotoDetails?.metadata?.height}
                                px
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <ConfirmDialog
        title="Delete Photo"
        message="Are you sure you want to delete this photo? "
        confirmBtnName="Delete"
        callback={handleConfirmation}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
      />

      <UpdatePhotoDetailsModal
        handleGetPhotoDetails={props.handleGetPhotoDetails}
        isEditMode={true}
        photoData={props.fullPhotoDetails}
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      />
    </>
  );
};

export default PhotoDetailsPopup;
