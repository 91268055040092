// @ts-nocheck
import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";
import qs from "qs";

export const getPublicUserProfile = (userId: any, onSuccess: any, onError: any) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/public/users/${userId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getPublicPhotoDetails = (
  photoId: string,
  onSuccess: any,
  onError: any
) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/public/photos/${photoId}`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getPublicPhotoData = (
  photoId: string,
  imgFullsize: boolean,
  onSuccess: any,
  onError: any
) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/public/photos/${photoId}/image?fullsize=${imgFullsize}`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
        responseType: "blob",
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};