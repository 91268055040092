import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmDialog = (props: any) => {
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="confirm-action-dialog"
    >
      <Modal.Header>
        <h4>{props.title}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="dialog">
          <div className="confirm-dialog-meg">{props.message}</div>
          {props.note ? <div className="confirm-dialog-meg">Note: {props.note}</div> : null}
          <div className="action-buttons-center">
            <Button variant="danger" onClick={() => props.callback("yes")}>
              {props.confirmBtnName}
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => props.callback("no")}
              title="Cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDialog;
