import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";

export const getGenericCsrf = (onSuccess: any, onError: any) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/generic/refresh_csrf`, {
      withCredentials: true,
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const genericFileUpload = (
  uploadData: any,
  uploadProgress: any,
  onSuccess: any,
  onError: any
) => {
  let config = {
    onUploadProgress: (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);

      if (percent < 100) {
        uploadProgress(percent);
      }
    },
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-csrf-token": getCSRFToken(),
    },
  };
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/generic/upload`,
      uploadData,
      config
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const becomeCreator = (
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/become_creator`,
      {},
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};