// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { SearchTagsAPI } from "../../api/SearchAPI";

const Search = (props: any) => {
  const [select, setSelect] = useState("");
  const [query, setQuery] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [showTags, setshowTags] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const handleSearchInput = (e: any) => {
    setQuery(e.target.value);

    if (e.target.value) {
      SearchTagsAPI(
        e.target.value,
        (data: any) => {
          setSearchTags(data.data);
          setshowTags(true);
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      setSearchTags([]);
      setshowTags(false);
    }
  };

  const handleOnSearchClick = () => {
    if (query) {
      history.push(`/searchresults?q=${query}`);
    }
  };

  const handleTagClick = (tag) => {
    setSelect(tag);
    window.location.href = `/searchresults?q=${tag}`;
  };

  const handleEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      // Trigger the button element with a click
      let x = document.getElementById("search-icon");
      console.log(document.getElementById("search-icon"));

      if (x) {
        x.click();
      }
    }
  };

  const boldString = (tag, key) => {
    let strRegExp = new RegExp(key, "g");
    return tag.replace(strRegExp, `<b style="color: #000">` + key + `</b>`);
  };

  return (
    <>
      <div
        id={props.navSearch ? "nav-search" : "search"}
        style={
          props.navSearch && scrollTop === 0 ? { background: "#e9ecef" } : {}
        }
      >
        <input
          type="text"
          id="search-input"
          list="searchTags"
          name="searchTags"
          placeholder="Search images"
          value={query}
          onChange={(e) => handleSearchInput(e)}
          onKeyUp={handleEnterKey}
          autoComplete="off"
          onBlur={() => {
            setTimeout(() => {
              if (!select) {
                setshowTags(false);
              }
            }, 100);
          }}
          onFocus={() => setshowTags(true)}
          // autofocus="true"
        />
        {showTags && searchTags.length > 0 && (
          <div className="search-tags-results-div">
            {searchTags.map((el: any, i: any) => (
              <span
                className="search-tag-links"
                key={i}
                dangerouslySetInnerHTML={{ __html: boldString(el, query) }}
                onClick={() => handleTagClick(el)}
              />
            ))}
          </div>
        )}

        <button type="button" id="search-icon" onClick={handleOnSearchClick}>
          <FaSearch />
        </button>
      </div>
    </>
  );
};

export default Search;
