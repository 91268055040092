import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";
import { createCollection } from "../../../api/CollectionAPI";
import { MdInfoOutline } from "react-icons/md";
import { toast } from "react-toastify";

const AddEditCollectionModal = (props: any) => {
  const [newCollection, setNewCollection] = useState({
    name: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    titleError: false,
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<string>("");

  useEffect(() => {
    if (!props.isEditMode) {
      setNewCollection({
        name: "",
        description: "",
      });
      setLoading(false);
      setErrorStatus("");
    } else {
      setNewCollection({
        name: props.collectionData.name,
        description: props.collectionData.description,
      });
      setLoading(false);
    }
  }, [props.show]);

  const validate = () => {
    let status = {
      titleError: false,
    };

    if (newCollection.name === "") {
      status.titleError = true;
    } else {
      status.titleError = false;
    }

    if (status.titleError) {
      setErrors(status);
      return false;
    } else {
      setErrors(status);
      return true;
    }
  };

  const handleAddNewCollection = () => {
    if (validate()) {
      setLoading(true);
      let newCollectionData = newCollection;
      createCollection(
        newCollectionData,
        (response: any) => {
          setLoading(false);
          props.handleGetCollections();
          setTimeout(() => {
            props.onHide()
          }, 1000);
        },
        (error: any) => {
          setLoading(false);
          if (error.response && error.response.data) {
            setErrorStatus(
              error.response.data.description
            );
          } else {
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        }
      );
    }
  };

  const handleOnChange = (event: any) => {
    // @ts-ignore
    setNewCollection({
      ...newCollection,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload-photo-popup"
    >
      <Modal.Header closeButton>
        {props.isEditMode ? "Update Collection" : "Add New Collection"}
      </Modal.Header>
      <Modal.Body>
        {errorStatus !== "" ? (
          <Alert variant="danger">
            <MdInfoOutline /> {errorStatus}
          </Alert>
        ) : null}

        <Form noValidate>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="name"
              placeholder="Title"
              required
              type="text"
              autoComplete="off"
              isInvalid={errors.titleError}
              value={newCollection.name}
              onChange={(event) => handleOnChange(event)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide the Title for your collection
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description (Optional)</Form.Label>
            <Form.Control
              name="description"
              placeholder="Collection Description"
              type="text"
              autoComplete="off"
              value={newCollection.description}
              onChange={(event) => handleOnChange(event)}
            />
          </Form.Group>
        </Form>

        <div className="action-buttons">
          <Button
            className="pix-main-button btn-normal-width"
            onClick={!isLoading ? handleAddNewCollection : () => {}}
          >
            {isLoading ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                ></Spinner>
                Please wait...
              </>
            ) : props.isEditMode ? (
              "Update Collection"
            ) : (
              "Create Collection"
            )}
          </Button>
          <Button variant="secondary" onClick={() => props.onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditCollectionModal;
