import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";

export const PhotosDetailAPI = (id: any, onSuccess: any, onError: any) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/public/photos/${id}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
