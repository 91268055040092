import React, { useContext, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";

const TermsAndConditions = () => {
  return (
    <div className="signup-page-wrapper">
      <Navbar alwaysShow={true} innerPage={true} hideSearch={true} navDropshadow={true} />

      <div className="terms-div">
        <Container>
          <div>
            <div className="terms-heading">Photo Upload Guideline</div>
            <div className="terms-sub-heading">Last updated : 04 April 2022</div>
            <Row xs={12} sm={10} md={8} className="cus-container" style={{ background: "white" }}>
              <div className="terms-section">
                <p>Images uploaded to Pix.lk should follow certain guidelines. These guidelines are mentioned in this document.</p>
                <div>
                  <ol className="non-styles">
                    <li>
                      <h2>File Size and Dimensions</h2>
                      <ul>
                        <li>All photographic images must be in JPEG format.</li>
                        <li>The images uploaded should be at least 2 Megapixels in size and set at the highest quality setting.</li>
                        <li>Both the height and width of the images should be more than 1080 pixels</li>
                        <li>The minimum file size accepted by Pix.lk is 1MB and the maximum file size accepted is 64MB.</li>
                        <li>All uploaded images to Pix.lk should contain the MetaData (EXIF Data) to ensure authenticity. </li>
                      </ul>
                    </li>

                    <li>
                      <h2>Image Quality</h2>
                      <ul>
                        <li>
                          On a basic level, images must be in focus, properly colour and contrast balanced, and free from blemishes such as lens flare, colour fringing, sensor dust, excessive noise
                          and so on.
                        </li>
                      </ul>
                    </li>

                    <li>
                      <h2>Image Size</h2>
                      <ul>
                        <li>Although we accept images as small as the above mentioned sizes, images stand a much better chance of being downloaded/purchased if they are of a higher resolution.</li>
                        <li>Higher resolution images have a much wider range of applications and consequently better chances of becoming bestsellers.</li>
                      </ul>
                    </li>

                    <li>
                      <h2>Image Rotation and Watermarks</h2>
                      <ul>
                        <li>All submitted images must have the correct rotation which means they should appear the right way around, with what is up in the image facing up on the screen.</li>
                        <li>
                          We cannot accept any photos that have a date imprint, signature or watermark embedded in the image area. We will also reject images that have titles or captions added to the
                          image area, unless the words are an integral part of your photo/illustration/design.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>

                <p style={{marginBottom: '2rem'}}><b>Most Importantly, we only accept images that have a Sri Lankan touch. Any image of Sri Lanka from wildlife to traditional events to historical places to current events are all accepted.</b></p>
              </div>
            </Row>
          </div>
        </Container>
      </div>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default TermsAndConditions;
