import React, { useContext, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="signup-page-wrapper">
      <Navbar alwaysShow={true} innerPage={true} hideSearch={true} navDropshadow={true} />

      <div className="privacy-div">
        <Container>
          <div>
            <div className="privacy-heading">Privacy Policy</div>
            <div className="privacy-sub-heading">Last updated : 04 April 2022</div>
            <Row xs={12} sm={10} md={8} className="cus-container" style={{ background: "white" }}>
              <div className="terms-section">
                <div>
                  <p>
                    The website, Pix.lk <b>[the “Site”/ “Pix.lk]</b> is owned by Serendib Systems Pty Ltd which, together with its affiliate partner <b>[“We”, “Us”, or “Our”]</b> and third-party
                    licensors provide an online platform where you as a user <b>[“You”]</b> may upload or purchase quality, royalty-free stock images with a Sri Lankan touch.
                  </p>
                  <p>Our Privacy Policy statement explains the following:</p>

                  <ol className="alpha" style={{ paddingLeft: 35 }}>
                    <li style={{ marginBottom: 0 }}>What personal information and data may be provided by You as a user of the Site, </li>
                    <li style={{ marginTop: 0, marginBottom: 0 }}>
                      What information and data do we gather or collect from visitors to, and users (including You) of, the Site by the use of cookies and other technologies, and
                    </li>
                    <li style={{ marginTop: 0, marginBottom: 0 }}>How we store, use, share, disclose, and protect such information and data.</li>
                  </ol>

                  <ol>
                    <li>
                      <h2>We Respect Your Privacy</h2>
                      <ol className="alpha">
                        <li>When You use Pix.lk, You trust us with your information. We are committed to keeping that trust. That starts with helping You understand Our privacy practices.</li>
                        <li>We adhere to the Australian National Privacy Principles established by the Privacy Act 1988. This policy sets out how we collect and treat your personal information.</li>
                      </ol>
                    </li>

                    <li>
                      <h2>What Information Do We Collect?</h2>
                      <p>
                        Information is automatically collected from You when you access and use the site and its related services and when you enter and/or upload information on the site. This
                        information pertains to your location, your usage of our services, your transaction history on our Site, Information about your device/s and your communication data.
                      </p>
                    </li>

                    <li>
                      <h2>Your Consent and Authorisation</h2>
                      <ol className="alpha">
                        <li>
                          By accessing and using Pix.lk you consent and authorise us to collect, use, share, disclose, and publish your personal information and data in accordance with Our Privacy
                          Policy.
                        </li>
                        <li>
                          Information so collected will be retained by us for such a period of time as we are permitted under law and or relevant regulations (45 days), notwithstanding that You have
                          ceased to be a user of Pix.lk and its services.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h2>How We Use Your Information</h2>
                      <p>We may use the information that We collect in the following manner:</p>
                      <ol className="alpha">
                        <li>
                          to serve You better in various ways including,
                          <ol type="I">
                            <li>Providing information and updates about Our services and of new opportunities.</li>
                            <li>Improving Our response to Users’ service requests and Our services to be more aligned with the needs of Our users.</li>
                          </ol>
                        </li>
                        <li>
                          for purposes of research, marketing, product development and planning in order to improve the services provided by Pix.lk in a manner that meets your expectations of a
                          customized and productive service.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h2>Cookies and Third-Party Technologies</h2>
                      <p>
                        Cookies are small text files that are stored on your browser or device by websites, apps, online media and advertisements. Pix.lk uses cookies and similar technologies for
                        purposes such as:
                      </p>
                      <ol className="alpha">
                        <li>Authenticating users.</li>
                        <li>Remembering user preferences and settings.</li>
                        <li>Determining the popularity of content.</li>
                        <li>Delivering and measuring the effectiveness of advertising campaigns.</li>
                        <li>Analysing site traffic and trends.</li>
                      </ol>
                    </li>

                    <li>
                      <h2>Information Sharing and Disclosure</h2>
                      <ol className="alpha">
                        <li>
                          We do not sell, trade, or otherwise transfer your personal information to outside parties. This does not include website hosting partners and other parties who assist Us in
                          operating Pix.lk, carrying out Our business, or serving Our users. Our user information is shared with such parties under an agreement to keep such information confidential.
                        </li>
                        <li>
                          We may disclose and provide your information including your personal information in order to comply with the law or any regulations, to enforce Our Site policies, and or to
                          safeguard and protect Our rights or the rights of others including property rights and public safety.
                        </li>
                        <li>We may provide information on visitors to Our Site, which does not however disclose their identity, to third parties for marketing, advertising or other uses.</li>
                      </ol>
                    </li>

                    <li>
                      <h2>Security of your Personal Information</h2>
                      <ol className="alpha">
                        <li>
                          Your personal information is protected behind secured networks and is only accessible by authorized persons who have special access rights to such systems, and who are
                          required to keep such information confidential.
                        </li>
                        <li>All transactions are processed through a gateway provider and are not stored or processed on our servers.</li>
                        <li>
                          Necessary security measures are in place and get activated whenever a user submits or accesses their information on the Site. This is to safeguard the personal information of
                          users of Pix.lk. against unauthorized access.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h2>Third-Party Products & Services</h2>
                      <p>
                        Pix.lk may include the products and services of third parties. The websites of these third parties will have their own separate and independent privacy policies. We have no
                        responsibility or liability for the content and activities of these linked sites.
                      </p>
                    </li>

                    <li>
                      <h2>Amendments to the Privacy Policy</h2>
                      <p>
                        This Privacy Policy may be modified and updated from time to time at our sole discretion and without notice to you. The amended Privacy Policy will be effective immediately
                        upon posting on Pix.lk Your continued access and use of the Site after the date of posting shall be deemed to constitute your acceptance of the amended Privacy Policy.
                      </p>
                    </li>

                    <li>
                      <h2>How to Contact Us</h2>
                      <p>
                        If you have any questions about this Privacy Policy or the services provided by using Pix.lk, please contact us directly via <a href="mailto:support@pix.lk">support@pix.lk</a>
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </div>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default PrivacyPolicy;
