import React from "react";
import HomePageComponent from "./components/views/homepage/HomePageComponent";
import SignUp from "./components/views/signup/SignUp";
import Login from "./components/views/login/Login";
import SearchResults from "./components/views/searchresults/SearchResults";
import PhotoDetail from "./components/views/searchresults/PhotoDetail";
import Purchase from "./components/views/purchase/Purchase";
import ResetPassword from "./components/views/login/PasswordReset";
import Download from "./components/views/purchase/Download";
import AccountActivation from "./components/views/common/AccountActivation";
import { ContextProvider } from "./components/context/ContextProvider";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./components/routes/PrivateRoute";
import ProfileAlbumsArea from "./components/views/profile/ProfileArea";
import ProfileCollectionsArea from "./components/views/profile/ProfileCollectionsArea";
import ViewTheAlbum from "./components/views/profile/albums/ViewTheAlbum";
import PhotoUpload from "./components/views/profile/albums/PhotoUpload/PhotoUpload";
import ViewTheCollection from "./components/views/profile/collections/ViewTheCollection";
import PublicAreaAlbums from "./components/views/public/albums/PublicAreaAlbums";
import ViewThePublicAlbum from "./components/views/public/albums/ViewThePublicAlbum";
import PhotoDetailView from "./components/views/profile/albums/PhotoDetailView";
import ProfileFavourites from "./components/views/profile/ProfileFavourites";
import TermsAndConditions from "./components/views/common/TermsAndConditions";
import PrivacyPolicy from "./components/views/common/PrivacyPolicy";
import PhotoUploadGuideline from "./components/views/common/PhotoUploadGuideline";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles/main.css";

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Router>
        <Switch>
          <ContextProvider>
            <Route path="/" exact component={HomePageComponent} />
            <Route path="/signup" exact component={SignUp} />
            <Route path="/login" exact component={Login} />
            <Route path="/searchresults" exact component={SearchResults} />
            <Route path="/photodetail/:photoId" exact component={PhotoDetail} />
            <Route path="/purchase" exact component={Purchase} />
            <Route path="/update_password" exact component={ResetPassword} />
            <Route path="/activated" exact component={AccountActivation} />
            <Route path="/checkout" exact component={Download} />
            <Route
              path="/public/albums/:id"
              exact
              component={PublicAreaAlbums}
            />
            <Route
              path="/public/albums/view/:id"
              exact
              component={ViewThePublicAlbum}
            />
            <Route
              path="/public/albums/view/:albumId/:photoId"
              exact
              component={PhotoDetail}
            />
            <PrivateRoute
              path="/profile/albums"
              exact
              component={ProfileAlbumsArea}
            />
            <PrivateRoute
              path="/profile/collections"
              exact
              component={ProfileCollectionsArea}
            />
            <PrivateRoute
              path="/profile/album/view/:id"
              exact
              component={ViewTheAlbum}
            />
            <PrivateRoute
              path="/profile/album/view/:id/:name"
              exact
              component={PhotoUpload}
            />
            <PrivateRoute
              path="/profile/collection/view/:id"
              exact
              component={ViewTheCollection}
            />
            <Route
              path="/photo_detail_view/:id"
              exact
              component={PhotoDetailView}
            />
            <PrivateRoute
              path="/profile/favourites"
              exact
              component={ProfileFavourites}
            />
            <Route path="/terms-conditions" exact component={TermsAndConditions} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/photo-upload-guideline" exact component={PhotoUploadGuideline} />
            {/* <Redirect exact to="/" /> */}
          </ContextProvider>
        </Switch>
      </Router>
    </>
  );
}

export default App;
