import React, { useState, useEffect } from "react";

import { FeaturedPhotosAPI } from "../../api/FeaturedPhotosAPI";

function MesonaryGallery() {
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    FeaturedPhotosAPI(
      (data: any) => {
        let len = data.data.slice(0, 9);
        let photos = gettingImages(len);
        setImages(photos);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, []);

  const gettingImages = (len: any) => {
    const photos = [];

    for (let i = 0; i < len.length; i++) {
      let obj = {
        id: len[i].id,
        urls: {
          thumb: `${len[i].public_urls.thumbnail}?fullsize=false`,
        },
        uploader: {
          name: len[i].uploader.first_name + " " + len[i].uploader.last_name,
          profile_photo: len[i].uploader.profile_photo,
        },
      };
      photos.push(obj);
    }

    return photos;
  };

  return (
    <div className="signup-gallery">
      {images.map((el: any, i: any) => (
        <div className="pix-signup-card" key={i}>
          <img src={el.urls.thumb} alt="Pix Masonry Gallery" />
        </div>
      ))}
    </div>
  );
}

export default MesonaryGallery;
