import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";
import qs from "qs";

export const uploadPhotoToAlbum = (
  photoData: object,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/photos`,
      qs.stringify(photoData),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getPhotoData = (
  photoId: string,
  imgFullsize: boolean,
  onSuccess: any,
  onError: any
) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/photos/${photoId}/image?fullsize=${imgFullsize}`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
        responseType: "blob",
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getPhotoDetails = (
  photoId: string,
  onSuccess: any,
  onError: any
) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/photos/${photoId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const deleteAPhoto = (photoId: string, onSuccess: any, onError: any) => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/v1/photos/${photoId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const updatePhotoDetails = (
  photoId: string,
  imageData: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .put(
      `${process.env.REACT_APP_API_URL}/v1/photos/${photoId}`,
      qs.stringify(imageData),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const submitPhotosForReview = (
  imageData: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/photos/review`, imageData, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const unpublishAPhotoAPI = (
  imageId: string,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/photos/${imageId}/unpublish`,
      {},
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
