import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";
import qs from "qs";

export const handleUserSignUp = (
  userData: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/register`,
      qs.stringify(userData),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const handleCreateAUserSession = (
  sessionData: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/sessions`,
      qs.stringify(sessionData),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const handleUserLogout = (onSuccess: any, onError: any) => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/v1/sessions`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const handleRequestPasswordReset = (
  userEmail: String,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/request_password_reset`,
      qs.stringify({ email: userEmail }),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const handlePasswordReset = (
  newPasswordData: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/reset_password`,
      qs.stringify(newPasswordData),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const checkEmailAvailability = (
  email: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/email_available?email=${email}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const checkUsernameAvailability = (
  username: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/username_available?username=${username}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const resendActivationMail = (
  userEmail: String,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/resend_activation`,
      qs.stringify({ email: userEmail }),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};