import React, { useEffect, useState } from "react";
import { Form, ProgressBar, Button, Spinner } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { MdHighlightOff } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";
import { FaRegCheckCircle } from "react-icons/fa";
import { SearchTagsAPI } from "../../../../api/SearchAPI";

import "react-bootstrap-typeahead/css/Typeahead.css";
import { genericFileUpload } from "../../../../api/GenericAPI";

interface IProps {
  removeImage: (id: number) => void;
  handleUploadSuccess: (index: number, name: string) => void;
  handleUploadFailure: (index: number, error: any) => void;
  handleUploadPhotoToAlbum: (index: number, id: string) => void;
  handleOnChange: (event: any, id: string) => void;
  handleOnCheckboxChange: (val: boolean, id: string) => void;
  onInputTagChange: (val: any, id: number) => void;
  onInputChange: (val: string, id: number) => void;
  fileItem: any;
  photoStatus: any;
  photoInfo: any;
  fileIndex: number;
  isReviewLoading: boolean;
  shouldDisabled: boolean;
  disabledStatus: boolean;
  tagsRef: any;
}

const SinglePhotoHandler = ({
  removeImage,
  fileItem,
  photoStatus,
  fileIndex,
  photoInfo,
  handleUploadSuccess,
  handleUploadFailure,
  shouldDisabled,
  disabledStatus,
  handleOnChange,
  handleOnCheckboxChange,
  onInputTagChange,
  onInputChange,
  handleUploadPhotoToAlbum,
  isReviewLoading,
  tagsRef,
}: IProps) => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchTagsLoading, setSearchTagsLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);

  const filterBy = () => true;

  useEffect(() => {
    if (photoStatus?.uploadStatus === "pending") {
      setLoading(true);
      let imageFile = new FormData();
      imageFile.append("file", fileItem.file);

      // API
      genericFileUpload(
        imageFile,
        (uploadProgress: number) => {
          setProgress(uploadProgress);
        },
        (data: any) => {
          setProgress(100);
          setLoading(false);
          handleUploadSuccess(fileItem.id, data.data.name);
        },
        (error: any) => {
          setLoading(false);
          handleUploadFailure(fileItem.id, error);
        }
      );
    }
  }, [photoStatus?.id]);

  const handleSearchTags = (query: string) => {
    setSearchTagsLoading(true);
    SearchTagsAPI(
      query,
      (response: any) => {
        setOptions(response.data);
        setSearchTagsLoading(false);
      },
      (error: any) => {
        console.log(error);
      }
    );
  };

  return (
    <div className="thumbs-container">
      {photoStatus?.errors.length ? (
        <div className="thumb">
          <i className="clear-selected-image" onClick={() => removeImage(fileItem.id)}>
            <MdHighlightOff />
          </i>
          <div className="error-message">
            <span className="upload-status-icon">
              <RiErrorWarningLine />
            </span>
            <div>
              <h6>Upload Error</h6>
              <p> {photoStatus.errors.map((error: any) => error.message)}</p>
            </div>
          </div>
          <div className="thumb-inner">
            <img src={fileItem.file.preview} alt="" className="thumb-inner-img" />
          </div>
        </div>
      ) : (
        <div className="thumb">
          {photoStatus?.uploadStatus !== "pending" && (
            <i className="clear-selected-image" onClick={() => removeImage(fileItem.id)}>
              <MdHighlightOff />
            </i>
          )}

          {photoStatus?.uploadStatus === "completed" ? (
            <div className="success-message">
              <span className="upload-status-icon">
                <FaRegCheckCircle />
              </span>
              <div>
                <h6>Uploaded</h6>
                <p>Image has been submitted for review.</p>
              </div>
            </div>
          ) : null}

          <div className="thumb-inner">
            <img src={fileItem.file.preview} className="thumb-inner-img" />
          </div>

          {progress < 100 && photoStatus?.uploadStatus === "pending" ? (
            <div className="uploading-image-progressbar">
              {progress < 100 ? <span>{"Uploading " + progress + "%"}</span> : null}
              {progress < 100 ? <ProgressBar now={progress} /> : null}
            </div>
          ) : null}
        </div>
      )}

      <div className="image-details-container">
        <Form>
          <Form.Group>
            <Form.Label>Title (Optional)</Form.Label>
            <Form.Control
              placeholder="Enter your image title"
              value={photoStatus?.uploadStatus !== "failed" ? photoInfo?.imageDetails?.name : ""}
              name="name"
              onChange={photoStatus?.uploadStatus !== "failed" ? (event) => handleOnChange(event, fileItem.id) : () => {}}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Tags (Optional)</Form.Label>
            <AsyncTypeahead
              ref={tagsRef}
              allowNew
              multiple={true}
              filterBy={filterBy}
              id="select-photo-tags"
              isLoading={searchTagsLoading}
              minLength={2}
              onSearch={handleSearchTags}
              options={options}
              onChange={
                photoStatus?.uploadStatus !== "failed"
                  ? (selected) => {
                      onInputTagChange(selected, fileItem.id);
                    }
                  : () => {}
              }
              selected={photoStatus?.uploadStatus !== "failed" ? photoInfo?.imageDetails?.tags : ""}
              placeholder="Select tags"
              onInputChange={(query) => onInputChange(query, fileItem.id)}
              newSelectionPrefix={"Add Tag : "}
              onKeyDown={(e: any) => {
                var a = e.key;
                if ([",", " "].indexOf(a) > -1 && e.target?.value.length == 0) {
                  e.preventDefault();
                }
                if (["@", "$", "+", "&", "|", "!", "(", ")", "{", "}", "[", "]", "^", "~", "*", "?", ":"].indexOf(a) > -1) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor={`allow_free_${fileIndex}`}>Free / Paid</Form.Label>
            <Form.Check
              id={`allow_free_${fileIndex}`}
              type="checkbox"
              label="Free Photo"
              checked={photoStatus?.uploadStatus !== "failed" ? photoInfo?.imageDetails?.allow_free : undefined}
              disabled={disabledStatus}
              name="allow_free"
              onChange={photoStatus?.uploadStatus !== "failed" ? () => handleOnCheckboxChange(!photoInfo?.imageDetails?.allow_free, fileItem.id) : () => {}}
            />
            <Form.Label className="checkbox-info">(You need to have minimum of 5 free photos in your account always)</Form.Label>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description (Optional)</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={photoStatus?.uploadStatus !== "failed" ? photoInfo?.imageDetails?.description : ""}
              name="description"
              onChange={photoStatus?.uploadStatus !== "failed" ? (event) => handleOnChange(event, fileItem.id) : () => {}}
            />
          </Form.Group>
        </Form>

        {photoStatus?.uploadStatus !== "failed" && !isLoading ? (
          <Button
            type="button"
            className="pix-main-button"
            disabled={photoStatus?.uploadStatus === "completed" || shouldDisabled}
            onClick={!isReviewLoading ? () => handleUploadPhotoToAlbum(fileIndex, fileItem.id) : () => {}}
          >
            {isReviewLoading ? (
              <>
                <Spinner animation="border" role="status" variant="light" size="sm"></Spinner>
                Please wait...
              </>
            ) : (
              "Submit for Review"
            )}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default SinglePhotoHandler;
