import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";

import facebook from "../../../images/icons/social/facebook.png";
import twitter from "../../../images/icons/social/twitter.png";
import pinterest from "../../../images/icons/social/pinterest.png";
import linkedin from "../../../images/icons/social/linkedin.png";
import mail from "../../../images/icons/social/mail.png";
import userPlaceholder from "../../../images/user.jpg";

const ShareModal = (props: any) => {
  const { size, photoData } = props;

  const [shareLink, setShareLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    setIsCopied(true);
  };

  useEffect(() => {
    // setShareLink(
    //   `${process.env.REACT_APP_WEB_URL}/photodetail/${photoData.id}`
    // );
    setShareLink(
      `${process.env.REACT_APP_WEB_URL}/photodetail/${photoData.ext_id}`
    );
  }, [photoData.id]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 6000);
    }
  }, [isCopied]);

  return (
    <Modal
      {...props}
      size={size}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="photo-share-popup"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="share-profile-header">
          <h6 className="heade_photo_title">{photoData?.name}</h6>
          <img
            src={
              photoData?.uploader?.profile_photo
                ? photoData.uploader.profile_photo
                : userPlaceholder
            }
            alt=""
          />
          <h6 className="photo_by_share">
            A Photo by{" "}
            <span>{`${photoData?.uploader?.first_name} ${
              photoData?.uploader?.last_name ? photoData.uploader.last_name : ""
            }`}</span>
          </h6>
        </div>

        <div className="share_options">
          <h6 className="share_section_title">Share on Social Media</h6>

          <div className="social_wrapper">
            <div className="social_item">
              <FacebookShareButton
                url={`${process.env.REACT_APP_WEB_URL}/photodetail/${photoData.id}`}
              >
                <img src={facebook} alt="" />
              </FacebookShareButton>
              <h6>Facebook</h6>
            </div>

            <div className="social_item">
              <TwitterShareButton
                url={`${process.env.REACT_APP_WEB_URL}/photodetail/${photoData.id}`}
                title={photoData?.name ?? ""}
              >
                <img src={twitter} alt="" />
              </TwitterShareButton>
              <h6>Twitter</h6>
            </div>

            <div className="social_item">
              <PinterestShareButton
                url={String(
                  `${process.env.REACT_APP_WEB_URL}/photodetail/${photoData.id}`
                )}
                media={photoData?.public_urls?.thumbnail}
              >
                <img src={pinterest} alt="" />
              </PinterestShareButton>
              <h6>Pinterest</h6>
            </div>

            <div className="social_item">
              <LinkedinShareButton
                url={`${process.env.REACT_APP_WEB_URL}/photodetail/${photoData.id}`}
              >
                <img src={linkedin} alt="" />
              </LinkedinShareButton>
              <h6>LinkedIn</h6>
            </div>

            <div className="social_item">
              <EmailShareButton
                url={`${process.env.REACT_APP_WEB_URL}/photodetail/${photoData.id}`}
                subject={`Pix.lk | ${
                  photoData?.name ? photoData?.name : "Shared Photo"
                }`}
                body="body"
                className="Demo__some-network__share-button"
              >
                <img src={mail} alt="" />
              </EmailShareButton>
              <h6>Email</h6>
            </div>
          </div>
        </div>

        <div className="share_copy_link">
          <h6 className="share_section_title_link">
            Use this link to post anywhere
          </h6>

          <div className="copy_link_wrapper pix-tooltip">
            <span className="tooltiptext">
              {isCopied ? "Copied!" : "Copy URL"}
            </span>
            <input
              type="text"
              className="form-control"
              value={shareLink}
              readOnly
            />
            <button
              className={
                isCopied ? "btn copy_link_btn copied-btn" : "btn copy_link_btn"
              }
              onClick={copyToClipboard}
            >
              {isCopied ? <MdCheckCircle /> : "Copy"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
