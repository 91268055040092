import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";
import qs from "qs";

export const createCollection = (
  collectionData: object,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/collections`, qs.stringify(collectionData), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getCollections = (
    onSuccess: any,
    onError: any
  ) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/collections`, {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  export const addPhotosToACollection = (
    id: any,
    albumData: object,
    onSuccess: any,
    onError: any
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/collections/${id}/photos`,
      qs.stringify(albumData),
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  export const deleteCollection = (
    albumId: string,
    onSuccess: any,
    onError: any
  ) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v1/collections/${albumId}`, {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

export const removePhotoFromCollection = (
    albumId: string,
    photoId: string,
    onSuccess: any,
    onError: any
  ) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v1/collections/${albumId}/photos?photos=${photoId}`, {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };
