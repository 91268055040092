import { Button, Spinner } from "react-bootstrap";
import { handleRequestPasswordReset } from "../../api/SignInAndSignUpAPI";
import { toast } from "react-toastify";
import { useState } from "react";

const RequestSuccess = (props: any) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleForgotPassword = () => {
    setLoading(true);
    handleRequestPasswordReset(
      props.retryEmail,
      (response: any) => {
        setLoading(false);
        toast.dark("Password reset email sent successful");
      },
      (error: any) => {
        setLoading(false);
        toast.error(
          error.response && error.response.data
            ? error.response.data.description +
                ` Error: ` +
                error.response.data.code
            : `Something went wrong. Please try again. ${error}`
        );
      }
    );
  };

  return (
    <div className="forgot-password-request-success-section">
      <h4>Password reset email sent successful</h4>
      <p>
        Password reset request email has been sent to your email. Please check
        your email inbox and follow the instructions
      </p>

      <Button
        className="pix-main-button"
        type="button"
        onClick={!isLoading ? handleForgotPassword : () => {}}
      >
        {isLoading ? (
          <>
            <Spinner
              animation="border"
              role="status"
              variant="light"
              size="sm"
            ></Spinner>
            Please wait...
          </>
        ) : (
          "Resend activation email"
        )}
      </Button>
    </div>
  );
};

export default RequestSuccess;
