//@ts-nocheck
import React, { useState, useEffect } from "react";
import Navbar from "../../common/Navbar";
import { Button, Container, Spinner, Tabs, Tab, Dropdown, DropdownButton, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft, MdClear } from "react-icons/md";
import { FaEllipsisH } from "react-icons/fa";
import Footer from "../../common/Footer";
import { useParams } from "react-router-dom";
import { deleteCollection, removePhotoFromCollection } from "../../../api/CollectionAPI";
import { getPhotoData } from "../../../api/PrivatePhotosAPI";
import { toast } from "react-toastify";
import ConfirmDialog from "../../common/ConfirmDialog";
import ConfirmRemovePhotoDialog from "../../common/ConfirmDialog";
import { useHistory } from "react-router-dom";
import { getPhotoDetails } from "../../../api/PrivatePhotosAPI";
import axios from "axios";
import { getCSRFToken } from "../../../auth/Authenticate";
import AlbumPlaceholder from "../../../../images/album-placeholder.jpg";

const ViewTheAlbum = () => {
  const [album, setAlbum] = useState({});
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [confirmRemovePhotoDialogShow, setConfirmRemovePhotoDialogShow] = useState(false);
  const [fullPhotoDetails, setFullPhotoDetails] = useState({});
  const [lastObj, setLastObj] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [changedUser, setChangedUser] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [albumName, setAlbumName] = useState("");
  const [deletingId, setDeletingId] = useState("");
  //@ts-ignore
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getAlbumData();
    const myWidth = window.innerWidth;
    setWindowWidth(myWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const myWidth = window.innerWidth;
      setWindowWidth(myWidth);
    });
  }, [window]);

  const getAlbumData = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(`${process.env.REACT_APP_API_URL}/v1/collections/${id}`, header);

    const albumData = await getAlbum.data;

    setAlbumName(getAlbum.data.name);

    albumData.photos = albumData.photos.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    if (!albumData.deleted) {
      setDeleteStatus(false);
    }
    setAlbum(albumData);
    setPageLoading(false);

    if (albumData.photos.length > 0) {
      for (const photo of await albumData.photos) {
        let photoObj = {};
        photoObj = photo;

        if (photo?.public_urls?.thumbnail) {
          photoObj["thumbnail"] = photo.public_urls.thumbnail;
        } else {
          let getPhotosThumb = await axios
            .get(`${process.env.REACT_APP_API_URL}/v1/photos/${photo.id}/image?fullsize=false`, headerPhoto)
            .then((response) => {
              photoObj["thumbnail"] = URL.createObjectURL(getPhotosThumb.data);
            })
            .catch((error) => {
              photoObj["thumbnail"] = photo.public_urls.thumbnail;
            });
        }
        photoObj["loading"] = false;
        setLastObj(photoObj);
      }
    }
  };

  const handleConfirmation = (yesOrNo: any) => {
    if (yesOrNo === "yes") {
      deleteCollection(
        album.id,
        (response: any) => {
          toast.dark("Collection Deleted successfully");
          history.push("/profile/collections");
        },
        (error: any) => {
          toast.error(`Something went wrong. Please try again. ${error}`);
        }
      );
    }
    setConfirmDialogShow(!confirmDialogShow);
  };

  const handleRemovePhotoConfirmation = (photoId: any) => {
    setDeletingId(photoId);
    if (photoId === "yes") {
      removePhotoFromCollection(
        album.id,
        deletingId,
        (response: any) => {
          toast.dark("Photo removed from the collection");
          getAlbumData();
        },
        (error: any) => {
          toast.error(`Something went wrong. Please try again. ${error}`);
        }
      );
    }
    setConfirmRemovePhotoDialogShow(!confirmRemovePhotoDialogShow);
  };

  const handleGetPhotoDetails = (photoId: string) => {
    getPhotoDetails(
      photoId,
      (response: any) => {
        let photoData = response.data;
        getPhotoData(
          photoId,
          true,
          (response: any) => {
            photoData["fullsize"] = URL.createObjectURL(response.data);
            setFullPhotoDetails(photoData);
          },
          (error: any) => {}
        );
      },
      (error: any) => {}
    );
  };

  const handlePhotoDetails = (id: string) => {
    history.push(`/photodetail/${id}`);
  };

  const chnageUserStatus = () => {
    setChangedUser(!changedUser);
  };

  return (
    <div className="profile-area-main">
      <Navbar alwaysShow={true} hideSearch={true} navDropshadow={true} changedUser={changedUser} />
      {/* <ProfileHeader changeUser={chnageUserStatus} /> */}
      <Container>
        <div className="view-album-wrapper" style={{ marginTop: "100px" }}>
          <div className="search query-display">
            <h3>My Collections</h3>
          </div>
          <div className="view-album-header mb-3">
            <Link to="/profile/collections" className="back-to-albums-btn">
              <MdKeyboardArrowLeft /> Back
            </Link>
          </div>
          <Breadcrumb className="breadcrumb-div">
            <Breadcrumb.Item onClick={() => history.replace(`/profile/collections`)}>Collections</Breadcrumb.Item>
            <Breadcrumb.Item active>{albumName}</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ background: "#f2f2f2", padding: "10px", marginBottom: "30px", borderRadius: "6px" }}>
            {pageLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spinner animation="border" className="home-page-loading-spinner"></Spinner>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h6 className="album-info-sec-title">{album?.name}</h6>
                  <p className="mb-3" style={{ fontSize: "13px" }}>
                    {album && album.description ? album.description : null}
                  </p>
                  <p className="mb-3" style={{ fontSize: "13px" }}>
                    {album && album.photos ? (album.photos.length == 1 ? "1 Photo" : album.photos.length + " Photos") : null}
                  </p>
                  {album?.tags &&
                    album?.tags.split(",").map((tag, i) => (
                      <span key={i} className="image-details-tags-display">
                        {tag}
                      </span>
                    ))}
                </div>
                <div>
                  <DropdownButton
                    id="user-profile-dropdown"
                    title={<FaEllipsisH style={{ color: "black" }} />}
                    className="nav-links dropdown-arrow-hide"
                    style={{ background: "#bdbdbd", borderRadius: "6px", color: "black", padding: "0px", paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <>
                      {/* <Dropdown.Item eventKey="1" onClick={() => setEditModalShow(true)}>
                        Edit
                      </Dropdown.Item> */}
                      <Dropdown.Item eventKey="2" onClick={() => handleConfirmation("Yes or No")}>
                        Remove Collection
                      </Dropdown.Item>
                    </>
                  </DropdownButton>
                </div>
              </div>
            )}
          </div>
          <div className="album-all-images-display">
            {album && album.photos
              ? album.photos.map((photo) => (
                  <div className="pa-album-images-block" key={photo.id}>
                    <div style={{ display: "flex", justifyContent: "right", position: "absolute", width: "100%" }}>
                      <MdClear
                        className="addToCollectionsIcon"
                        style={{ marginTop: "5px", marginLeft: "5px", color: "red", fontSize: "30px" }}
                        onClick={() => handleRemovePhotoConfirmation(photo.id)}
                      />
                    </div>

                    {photo.loading === false ? (
                      <>
                        <div style={{ height: "100%", width: "100%", cursor: "pointer" }} onClick={() => handlePhotoDetails(photo.id)}>
                          <img src={photo?.thumbnail ? photo.thumbnail : AlbumPlaceholder} alt="pix album images" />
                        </div>
                      </>
                    ) : (
                      <div className="album-spinner">
                        <Spinner variant="dark" animation="border" role="status"></Spinner>
                      </div>
                    )}
                  </div>
                ))
              : null}
          </div>
        </div>
      </Container>

      {/* <PhotoDetailsPopup
        handleGetPhotoDetails={handleGetPhotoDetails}
        getAlbumData={getAlbumData}
        fullPhotoDetails={fullPhotoDetails}
        show={photoDetailsShow}
        onHide={() => {
          setPhotoDetailsShow(false);
          setFullPhotoDetails({});
        }}
      />
      <AddEditAlbumModal
        getAlbumData={getAlbumData}
        isEditMode={true}
        albumData={album}
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      /> */}
      <ConfirmDialog
        title="Remove Collection"
        message="Are you sure you want to remove this collection and its contents ?"
        // note="When an album is removed from the website all of its contents will be removed as well. The photos will not be visible to new users but users that have downloaded them will continue to have access."
        confirmBtnName="Delete"
        callback={handleConfirmation}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
      />
      <ConfirmRemovePhotoDialog
        title="Remove Photo"
        message="Are you sure you want to remove this photo from the collection ?"
        // note="When an album is removed from the website all of its contents will be removed as well. The photos will not be visible to new users but users that have downloaded them will continue to have access."
        confirmBtnName="Remove"
        callback={handleRemovePhotoConfirmation}
        show={confirmRemovePhotoDialogShow}
        onHide={() => setConfirmRemovePhotoDialogShow(false)}
      />
      <Footer simpleFooter={true} />
    </div>
  );
};

export default ViewTheAlbum;
