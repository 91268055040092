import React, { useState, useEffect } from "react";
import qs from "qs";
import NumberFormat from "react-number-format";

import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
// import { Link } from "react-router-dom";
import { MdLock, MdKeyboardArrowLeft } from "react-icons/md";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import "../../../styles/purchase.css";
// import { SelectedImageContext } from "../../context/ContextProvider";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../auth/Authenticate";
import Payhere from "../../../images/icons/payment_methods/payhere.png";
import Visa from "../../../images/icons/payment_methods/visa.png";
import Mastercard from "../../../images/icons/payment_methods/mastercard.png";
import Discover from "../../../images/icons/payment_methods/discover.png";
import Amex from "../../../images/icons/payment_methods/amex.png";
import Paypal from "../../../images/icons/payment_methods/paypal.png";
import Stripe from "../../../images/icons/payment_methods/stripe.png";

import { PhotosDetailAPI } from "../../api/PhotosDetailAPI";
import { PhotoWithWaterMarkAPI } from "../../api/PhotoWithWaterMarkAPI";
import { CheckoutSessionAPI, CheckoutFreeSessionAPI, PreCheckoutFreeSessionAPI } from "../../api/CheckoutSessionAPI";
import { getParams } from "../../../methods/getParams";
import { toast } from "react-toastify";
import ConfirmDialog from "../common/ConfirmCommonDialog";

declare global {
  interface Window {
    Stripe: any;
  }
}

const Purchase = () => {
  const [form, setForm] = useState<any>({
    fName: "",
    lName: "",
    email: "",
    conEmail: "",
    fNameErr: "",
    lNameErr: "",
    emailErr: "",
    conEmailErr: "",
  });
  const [photoDetail, setPhotoDetail] = useState<any>({});
  const [photo, setPhoto] = useState<any>("");
  const [checkOutData, setCheckOutData] = useState({ ipg: "", amount: 0, currency: "" });
  const [loading, setLoading] = useState<Boolean>(false);
  const [confirmDialogShow, setConfirmDialogShow] = useState<Boolean>(false);
  const [sessionId, setSessionId] = useState<any>("");
  const [imageId, setImageId] = useState<any>("");
  const history = useHistory();

  useEffect(() => {
    const id = getParams("id");

    PhotosDetailAPI(
      id,
      (data: any) => {
        setPhotoDetail(data.data);
      },
      (error: any) => {
        console.log(error);
      }
    );

    const reqPayload = qs.stringify({
      price_check: true,
    });

    CheckoutSessionAPI(
      id,
      reqPayload,
      (data: any) => {
        setCheckOutData(data.data);
      },
      (error: any) => {
        console.log(error);
      }
    );

    getPhotoWithWaterMark(id);

    loadScripts();
  }, []);

  // full photo with watermark
  const getPhotoWithWaterMark = (id: any) => {
    PhotoWithWaterMarkAPI(
      id,
      (data: any) => {
        const full = URL.createObjectURL(data.data);
        setPhoto(full);
      },
      (error: any) => {
        console.log(error);
      }
    );
  };

  const loadScripts = () => {
    // stripe
    const stripeScript = document.createElement("script");

    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;

    document.body.appendChild(stripeScript);
  };

  const handleOnChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value.trim(),
      [e.target.name + "Err"]: "",
    });
  };

  // validate
  const validate = () => {
    let fNameErr = "";
    let lNameErr = "";
    let emailErr = "";
    let conEmailErr = "";

    if (!form.fName) {
      fNameErr = "Please enter your first name";
    } else if (form.fName.length < 2) {
      fNameErr = "First name is too short";
    } else if (!form.fName.match(/^[a-zA-Z]+$/)) {
      fNameErr = "Please enter a valid first name";
    }

    if (!form.lName) {
      lNameErr = "Please enter your last name";
    } else if (form.lName.length < 2) {
      lNameErr = "First name is too short";
    } else if (!form.lName.match(/^[a-zA-Z]+$/)) {
      lNameErr = "Please enter a valid last name";
    }

    if (!form.email) {
      emailErr = "Please enter your email address";
    } else if (!form.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      emailErr = "Please enter a valid email address";
    }

    if (!form.conEmail) {
      conEmailErr = "Please confirm your email address";
    } else if (form.email !== form.conEmail) {
      conEmailErr = "Emails should match";
    } else if (!form.conEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      conEmailErr = "Please enter a valid email address";
    }

    if (fNameErr || lNameErr || emailErr || conEmailErr) {
      setForm({
        ...form,
        fNameErr,
        lNameErr,
        emailErr,
        conEmailErr,
      });
      return false;
    }

    return true;
  };

  const handleCheckout = (id: any) => {
    if (validate()) {
      setLoading(true);

      const reqPayload = qs.stringify({
        name: form.fName + " " + form.lName,
        email: form.email,
        price_check: false,
      });

      CheckoutSessionAPI(
        id,
        reqPayload,
        (data: any) => {
          if (data.data.is_free) {
            setConfirmDialogShow(true);
            setSessionId(data.data.id);
            setImageId(id);
          } else {
            redirect(data.data.stripe_params.session_id, data.data.ipg);
          }
        },
        (error: any) => {
          setLoading(false);
          console.log(error);
        }
      );
    }
  };

  const handleFreeDownload = (id: any) => {
    setLoading(true);

    const reqPayload = qs.stringify({
      price_check: false,
    });

    PreCheckoutFreeSessionAPI(
      id,
      reqPayload,
      (data: any) => {
        setConfirmDialogShow(true);
        setSessionId(data.data.id);
        setImageId(id);
      },
      (error: any) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const handleLogedUserDownload = (id: any) => {
    setLoading(true);

    const reqPayload = qs.stringify({
      price_check: false,
    });

    PreCheckoutFreeSessionAPI(
      id,
      reqPayload,
      (data: any) => {
        if (data.data.is_free) {
          setConfirmDialogShow(true);
          setSessionId(data.data.id);
          setImageId(id);
        } else {
          redirect(data.data.stripe_params.session_id, data.data.ipg);
        }
      },
      (error: any) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const confirmGetFreePhoto = (yesOrNo: any) => {
    if (yesOrNo == "yes") {
      const freePayload = qs.stringify({
        session_id: sessionId,
      });
      CheckoutFreeSessionAPI(
        imageId,
        freePayload,
        (response: any) => {
          toast.success("Image downloading link sent to your email address");
          setLoading(false);
          history.push("/");
        },
        (error: any) => {
          setLoading(false);
          setConfirmDialogShow(false);
          toast.error("Please try again later");
          console.log(error);
        }
      );
    } else {
      setLoading(false);
      setConfirmDialogShow(false);
    }
  };

  const redirect = (id: any, ipg: any) => {
    console.log(process.env);
    console.log(process.env.REACT_APP_STRIPE_KEY);
    const stripeId = process.env.REACT_APP_STRIPE_KEY;

    var stripe = window.Stripe(stripeId);

    // this.setState({ loadingCard: true });

    if (ipg === "STRIPE") {
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: id,
        })
        .then(function (result: any) {
          setLoading(false);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
        });
    }
  };

  const renderPurchaseDetails = () => {
    if (isAuthenticated() && checkOutData.ipg === "FREE") {
      return false
    }

    if (isAuthenticated() && checkOutData.ipg !== "FREE") {
      return false
    }

    if (!isAuthenticated()) {
      return true
    }
  }

  const renderPurchaseButton = () => {
    if (isAuthenticated() && checkOutData.ipg === "FREE") {
      return (
        <button type="button" className="button-regular purchase-btn" onClick={() => handleFreeDownload(photoDetail.id)}>
          {loading ? <Spinner animation="border" role="status" variant="light" size="sm"></Spinner> : "Complete Downloading"}
        </button>
      );
    }

    if (isAuthenticated() && checkOutData.ipg !== "FREE") {
      return (
        <button type="button" className="button-regular purchase-btn" onClick={() => handleLogedUserDownload(photoDetail.id)}>
          {loading ? (
            <Spinner animation="border" role="status" variant="light" size="sm"></Spinner>
          ) : (
            <>
              <MdLock />
              Confirm Purchase
            </>
          )}
        </button>
      );
    }

    if (!isAuthenticated()) {
      return (
        <button type="button" className="button-regular purchase-btn" onClick={() => handleCheckout(photoDetail.id)}>
          {loading ? (
            <Spinner animation="border" role="status" variant="light" size="sm"></Spinner>
          ) : (
            <>
              <MdLock />
              Confirm Purchase
            </>
          )}
        </button>
      );
    }
  };

  return (
    <div className="purchase-main-wrapper">
      <Navbar alwaysShow={true} innerPage={true} navDropshadow={true} hideSearch={true} />
      <div className="purchase-wrapper">
        <Container>
          <span className="back-to-results-btn" onClick={() => history.goBack()}>
            <MdKeyboardArrowLeft style={{ fontSize: 26, marginLeft: "-10px" }} />
            Back
          </span>

          <div className="purchase-inner">
            <h4 className="purchase-title">Checkout</h4>

            <Row>
              <Col lg={6} md={6}>
                <div className="image-display-purchase">
                  <img
                    src={photo}
                    // alt={photoDetail.alt}
                  />
                </div>
              </Col>
              <Col lg={6} md={6}>
                {renderPurchaseDetails() ? (
                  <div className="purchase-description-wrapper">
                    <h6 className="purchase-user-details-title">Your Details</h6>
                    <Form>
                      <Form.Group>
                        <Form.Control name="fName" placeholder="First Name" required type="text" autoComplete="off" isInvalid={form.fNameErr} value={form.fName} onChange={handleOnChange} />
                        <Form.Control.Feedback type="invalid">{form.fNameErr}</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Control name="lName" placeholder="Last Name" required type="text" autoComplete="off" isInvalid={form.lNameErr} value={form.lName} onChange={handleOnChange} />
                        <Form.Control.Feedback type="invalid">{form.lNameErr}</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Control
                          name="email"
                          placeholder="Email"
                          required
                          type="text"
                          autoComplete="off"
                          isInvalid={form.emailErr}
                          value={form.email}
                          onChange={handleOnChange}
                          onCut={(e: any) => e.preventDefault()}
                          onCopy={(e: any) => e.preventDefault()}
                          onPaste={(e: any) => e.preventDefault()}
                        />
                        <Form.Control.Feedback type="invalid">{form.emailErr}</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Control
                          name="conEmail"
                          placeholder="Confirm Email"
                          required
                          type="text"
                          autoComplete="off"
                          isInvalid={form.conEmailErr}
                          value={form.conEmail}
                          onChange={handleOnChange}
                          onCut={(e: any) => e.preventDefault()}
                          onCopy={(e: any) => e.preventDefault()}
                          onPaste={(e: any) => e.preventDefault()}
                        />
                        <Form.Control.Feedback type="invalid">{form.conEmailErr}</Form.Control.Feedback>
                      </Form.Group>

                      {/* <div className="payment-method-wrapper">
                      <h6 className="purchase-user-details-title">
                        Select Payment Method
                      </h6>
                      <fieldset>
                        <Form.Group as={Row}>
                          <Col>
                            <Form.Check
                              type="radio"
                              label="Sri Lankan Credit/Debit Card"
                              name="paymentMethod"
                              id="paymentMethodOne"
                            />
                            <Form.Check
                              type="radio"
                              label="Non Sri Lankan Credit/Debit Card"
                              name="paymentMethod"
                              id="paymentMethodTwo"
                            />
                            <Form.Check
                              type="radio"
                              label="EZ Cash / MCash / FriMi / Dialog Genie / Sampath Vishwa"
                              name="paymentMethod"
                              id="paymentMethodThree"
                            />
                          </Col>
                        </Form.Group>
                      </fieldset>
                    </div>
                    */}
                    </Form>
                  </div>
                ) : null}
              </Col>
            </Row>

            <div className="invoice-wrapper">
              <div className="invoice-wrapper-inner">
                <h5 className="purchase-invoice-title">Invoice</h5>
                <div className="checkout-invoice-main">
                  <div className="purchase-item">
                    <div className="purchase-item-details">
                      <h6>{photoDetail.name}</h6>
                      <p>
                        {photoDetail.metadata && photoDetail.metadata.width} x {photoDetail.metadata && photoDetail.metadata.height} pixels | JPEG
                      </p>
                    </div>
                    <div className="purchase-item-price">
                      <h6>
                        Rs. <NumberFormat value={checkOutData.amount.toFixed(2)} displayType={"text"} thousandSeparator={true} />
                      </h6>
                    </div>
                  </div>

                  {/* <div className="purchase-item-promo-code">Add Promo Code</div> */}
                  <div className="purchase-invoice-total-main">
                    <div className="purchase-invoice-total">Total Due</div>
                    <div className="purchase-invoice-total-price">
                      Rs. <NumberFormat value={checkOutData.amount.toFixed(2)} displayType={"text"} thousandSeparator={true} />
                    </div>
                  </div>
                </div>
              </div>
              <p className="purchase-license-note">
                Includes our <span className="span-link">standard license</span> for royalty free use.
              </p>
            </div>

            <div className="purchase-action-container">{renderPurchaseButton()}</div>
          </div>

          <div className="payment-methods-icon-wrapper">
            <h6>Payments are securely processed by</h6>
            <div className="payment-methods-icon-inner">
              {/* <img src={Payhere} alt="Payhere" />
              <img src={Visa} alt="Visa" />
              <img src={Mastercard} alt="Mastercard" />
              <img src={Discover} alt="Discover" />
              <img src={Amex} alt="Amex" />
              <img src={Paypal} alt="Paypal" /> */}
              <img src={Stripe} alt="Stripe" />
            </div>
          </div>
        </Container>
      </div>
      <ConfirmDialog
        title="Download free photo"
        message="Are you sure you want to download this photo?"
        confirmBtnName="Yes"
        callback={confirmGetFreePhoto}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
      />
      <Footer simpleFooter={true} />
    </div>
  );
};

export default Purchase;
