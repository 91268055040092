import React, { useEffect, useState } from "react";
import { Spinner, Button, Container, Alert } from "react-bootstrap";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { MdInfoOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";

const AccountActivation = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const status: string | null = url.searchParams.get("success");

    if (status === "true") {
      setIsSuccess(true);
    }
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <div className="account-activation-wrapper">
      <Navbar
        alwaysShow={true}
        innerPage={true}
        hideSearch={true}
        navDropshadow={true}
      />
      <Container>
        <div className="account-activation-inner">
          {isLoading ? (
            <>
              <Spinner animation="border" role="status"></Spinner>
              <span className="spinner-text">Please wait...</span>
            </>
          ) : isSuccess ? (
            <>
              <h5>Your account has been activated</h5>
              <p>You can upload your photos now.</p>
              <Button
                className="pix-main-button"
                type="button"
                onClick={() => history.push("/")}
              >
                Go to Home page
              </Button>
            </>
          ) : (
            <>
              <Alert variant="danger">
                <span><MdInfoOutline /></span> Account activation failed
                <p>Please go to your activation email and click the link again.</p>
              </Alert>
            </>
          )}
        </div>
      </Container>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default AccountActivation;
