import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.css";
import axios from "axios";
import { getGenericCsrf } from "./components/api/GenericAPI";
import { handleUserLogout } from "./components/api/SignInAndSignUpAPI";

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (
      error.response?.status === 401 &&
      error.response.request.responseURL !==
        "https://api-dev.pix.lk/v1/sessions" &&
      error.response.status === 401 &&
      error.response.request.responseURL !== "https://api.pix.lk/v1/sessions"
    ) {
      handleUserLogout(
        (data: any) => {
          document.cookie =
            "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          window.location.href = "/login";
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      if (
        error.config &&
        error.response &&
        error.response.status === 403 &&
        error.response.data.code === 1005
      ) {
        return getGenericCsrf(
          (data: any) => {
            if (
              window.location.hostname === "localhost" ||
              window.location.hostname === "127.0.0.1"
            ) {
              localStorage.setItem("CSRF_token", data.id);
            }

            error.config.headers["x-csrf-token"] = data.id;
            return axios.request(error.config);
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
