//@ts-nocheck
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "../../../common/Navbar";
import { v4 as uuidv4 } from "uuid";
import { Button, Spinner, Container } from "react-bootstrap";
import { uploadPhotoToAlbum, submitPhotosForReview } from "../../../../api/PrivatePhotosAPI";
import { getUserProfile } from "../../../../api/ProfileAreaAPIs";
import { Link, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Footer from "../../../common/Footer";
import SinglePhotoHandler from "./SinglePhotoHandler";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 8,
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  justifyContent: "center",
  marginBottom: 15,
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const PhotoUpload = () => {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [imageInfo, setImageInfo] = useState([]);
  const [isReviewLoading, setReviewLoading] = useState(null);
  const [isLoadingAll, setLoadingAll] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(false);

  const tagsRef = React.createRef<any>();
  const { id: albumId, name: albumName } = useParams();

  useEffect(() => {
    getUserProfile(
      (response: any) => {
        setDisabledStatus(!response.data.verified);
        localStorage.setItem("user", JSON.stringify(response.data));
      },
      (error: any) => {}
    );
  }, []);

  const Previews = () => {
    const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: "image/jpeg",
      onDrop: async (acceptedFiles, fileRejections) => {
        const mappedAcceptedFiles = acceptedFiles.map(
          (file) => (
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
            {
              file,
              id: uuidv4(),
            }
          )
        );

        const initialStatus = mappedAcceptedFiles.map((file) => {
          const data = {
            id: file.id,
            uploadStatus: "pending",
            errors: [],
          };
          return data;
        });

        const imageData = mappedAcceptedFiles.map((file) => {
          const data = {
            id: file.id,
            uploadStatus: "pending",
            imageDetails: {
              album: albumId,
              filename: "",
              name: "",
              tags: [],
              description: "",
              allow_free: true,
            },
          };
          return data;
        });

        setImageInfo((curr) => [...imageData, ...curr]);
        setFiles((curr) => [...mappedAcceptedFiles, ...curr]);
        setUploadStatus((curr) => [...initialStatus, ...curr]);
      },
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <section className="image-upload-container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} multiple={true} />
          <p>Drag and drop your images here</p>
          <span className="image-upload-or">or</span>
          <Button className="pix-main-button btn-normal-width" onClick={open}>
            Browse
          </Button>
        </div>
      </section>
    );
  };

  const handleUploadPhotoToAlbum = (index, fileId) => {
    setReviewLoading(index);

    const fileIndex = uploadStatus.findIndex((currFile) => currFile.id === fileId);
    const infoIndex = imageInfo.findIndex((currFile) => currFile.id === fileId);

    const selectedImageData = imageInfo[infoIndex].imageDetails;
    const imageDetails = {
      album: selectedImageData.album,
      filename: selectedImageData.filename,
      tags: selectedImageData.tags
        .map((tag: any) => {
          if (tag.customOption === true) {
            return tag.label;
          } else {
            return tag;
          }
        })
        .toString(),
      name: selectedImageData.name,
      description: selectedImageData.description,
      allow_free: selectedImageData.allow_free,
    };

    //API
    uploadPhotoToAlbum(
      imageDetails,
      (data: any) => {
        submitPhotosForReview(
          [data.data.id],
          (response: any) => {
            const currFiles = [...uploadStatus];
            currFiles[fileIndex].uploadStatus = "completed";
            setUploadStatus(currFiles);

            const currentStatus = [...imageInfo];
            currentStatus[infoIndex].uploadStatus = "completed";
            setImageInfo(currentStatus);

            setReviewLoading(null);
          },
          (error: any) => {
            setReviewLoading(null);
            if (error.response && error.response.data) {
              const currFiles = [...uploadStatus];
              currFiles[fileIndex].uploadStatus = "failed";
              currFiles[fileIndex].errors = [{ message: error?.response?.data?.description ? error.response.data.description : `Something went wrong. Please try again. ${error}` }];
              setUploadStatus(currFiles);

              const currentStatus = [...imageInfo];
              currentStatus[infoIndex].uploadStatus = "failed";
              setImageInfo(currentStatus);
            } else {
              const currFiles = [...uploadStatus];
              currFiles[fileIndex].uploadStatus = "failed";
              setUploadStatus(currFiles);

              const currentStatus = [...imageInfo];
              currentStatus[infoIndex].uploadStatus = "failed";
              setImageInfo(currentStatus);
            }
          }
        );
      },
      (error: any) => {
        setReviewLoading(null);
        if (error.response && error.response.data) {
          const currFiles = [...uploadStatus];
          currFiles[fileIndex].uploadStatus = "failed";
          currFiles[fileIndex].errors = [
            { message: error.response.data.code == 1205 ? "Image metadata is missing. Please include all Exif tags when uploading images" : error.response.data.description },
          ];
          setUploadStatus(currFiles);

          const currentStatus = [...imageInfo];
          currentStatus[infoIndex].uploadStatus = "failed";
          setImageInfo(currentStatus);
        } else {
          const currFiles = [...uploadStatus];
          currFiles[fileIndex].uploadStatus = "failed";
          currFiles[fileIndex].errors = [{ message: `Something went wrong. Please try again. ${error}` }];
          setUploadStatus(currFiles);

          const currentStatus = [...imageInfo];
          currentStatus[infoIndex].uploadStatus = "failed";
          setImageInfo(currentStatus);
        }
      }
    );
  };

  const handleUploadAllPhotosToAlbum = () => {
    for (const imageData of imageInfo) {
      if (imageData.uploadStatus === "uploaded") {
        const fileIndex = uploadStatus.findIndex((currFile) => currFile.id === imageData.id);
        const infoIndex = imageInfo.findIndex((currFile) => currFile.id === imageData.id);

        const imageDetails = {
          album: imageData.imageDetails.album,
          filename: imageData.imageDetails.filename,
          tags: imageData.imageDetails.tags
            .map((tag: any) => {
              if (tag.customOption === true) {
                return tag.label;
              } else {
                return tag;
              }
            })
            .toString(),
          name: imageData.imageDetails.name,
          description: imageData.imageDetails.description,
          allow_free: imageData.imageDetails.allow_free,
        };

        setLoadingAll(true);
        //API
        uploadPhotoToAlbum(
          imageDetails,
          (data: any) => {
            submitPhotosForReview(
              [data.data.id],
              (response: any) => {
                const currFiles = [...uploadStatus];
                currFiles[fileIndex].uploadStatus = "completed";
                setUploadStatus(currFiles);

                const currentStatus = [...imageInfo];
                currentStatus[infoIndex].uploadStatus = "completed";
                setImageInfo(currentStatus);

                setLoadingAll(false);
              },
              (error: any) => {
                setLoadingAll(false);

                const currFiles = [...uploadStatus];
                currFiles[fileIndex].uploadStatus = "failed";
                currFiles[fileIndex].errors = [{ message: error?.response?.data?.description ? error.response.data.description : `Something went wrong. Please try again. ${error}` }];
                setUploadStatus(currFiles);

                const currentStatus = [...imageInfo];
                currentStatus[infoIndex].uploadStatus = "failed";
                setImageInfo(currentStatus);
              }
            );
          },
          (error: any) => {
            setLoadingAll(false);

            if (error.response && error.response.data) {
              const currFiles = [...uploadStatus];
              currFiles[fileIndex].uploadStatus = "failed";
              currFiles[fileIndex].errors = [
                { message: error.response.data.code == 1205 ? "Image metadata is missing. Please include all Exif tags when uploading images" : error.response.data.description },
              ];
              setUploadStatus(currFiles);

              const currentStatus = [...imageInfo];
              currentStatus[infoIndex].uploadStatus = "failed";
              setImageInfo(currentStatus);
            } else {
              const currFiles = [...uploadStatus];
              currFiles[fileIndex].uploadStatus = "failed";
              currFiles[fileIndex].errors = [{ message: `Something went wrong. Please try again. ${error}` }];
              setUploadStatus(currFiles);

              const currentStatus = [...imageInfo];
              currentStatus[infoIndex].uploadStatus = "failed";
              setImageInfo(currentStatus);
            }
          }
        );
      }
    }
  };

  const onInputChange = (query, imageId) => {
    if (query.length > 1) {
      const fileIndex = imageInfo.findIndex((currFile) => currFile.id === imageId);
      if (query.charAt(query.length - 1) == "," || query.charAt(query.length - 1) == " ") {
        const currentFiles = [...imageInfo];
        currentFiles[fileIndex].imageDetails.tags = [...currentFiles[fileIndex].imageDetails.tags, query.substring(0, query.length - 1)];
        setImageInfo(currentFiles);
        tagsRef.current.clear();
      }
    }
  };

  const onInputTagChange = (query, imageId) => {
    const fileIndex = imageInfo.findIndex((currFile) => currFile.id === imageId);
    const currentFiles = [...imageInfo];
    currentFiles[fileIndex].imageDetails.tags = query;
    setImageInfo(currentFiles);
  };

  const handleOnChange = (event, imageId) => {
    const fileIndex = imageInfo.findIndex((currFile) => currFile.id === imageId);
    const currentFiles = [...imageInfo];
    currentFiles[fileIndex].imageDetails[event.target.name] = event.target.value;
    setImageInfo(currentFiles);
  };

  const handleOnCheckboxChange = (value, imageId) => {
    const fileIndex = imageInfo.findIndex((currFile) => currFile.id === imageId);
    const currentFiles = [...imageInfo];
    currentFiles[fileIndex].imageDetails.allow_free = value;
    setImageInfo(currentFiles);
  };

  const handleUploadFailure = (photoId, error) => {
    const currFiles = [...uploadStatus];
    const fileIndex = uploadStatus.findIndex((currFile) => currFile.id === photoId);
    currFiles[fileIndex].uploadStatus = "failed";
    const errors = [{ message: error.response?.data?.description ? error.response.data.description : "Error while uploading image. Please try again." }];
    currFiles[fileIndex].errors = errors;
    setUploadStatus(currFiles);

    const currentImageInfo = [...imageInfo];
    delete currentImageInfo[imageInfo.findIndex((currFile) => currFile.id === photoId)];
    setImageInfo(currentImageInfo.filter((n) => n));
  };

  const handleUploadSuccess = (photoId, photoName) => {
    const currFiles = [...uploadStatus];
    const fileIndex = uploadStatus.findIndex((currFile) => currFile.id === photoId);
    currFiles[fileIndex].uploadStatus = "uploaded";
    setUploadStatus(currFiles);

    const infoIndex = imageInfo.findIndex((currFile) => currFile.id === photoId);
    const currentStatus = [...imageInfo];
    currentStatus[infoIndex].imageDetails.filename = photoName;
    currentStatus[infoIndex].uploadStatus = "uploaded";
    setImageInfo(currentStatus);
  };

  const removeImage = (photoId) => {
    const fileIndex = files.findIndex((currFile) => currFile.id === photoId);
    const infoIndex = imageInfo.findIndex((currFile) => currFile.id === photoId);
    const statusIndex = uploadStatus.findIndex((currFile) => currFile.id === photoId);

    const currentFiles = [...files];
    delete currentFiles[fileIndex];
    setFiles(currentFiles.filter((n) => n));

    const currentImageInfo = [...imageInfo];
    delete currentImageInfo[infoIndex];
    setImageInfo(currentImageInfo.filter((n) => n));

    const currentStatus = [...uploadStatus];
    delete currentStatus[statusIndex];
    setUploadStatus(currentStatus.filter((n) => n));
  };

  const haandlePhotoStatus = (id) => {
    const data = uploadStatus.filter((data) => data.id === id);
    return data[0];
  };

  const haandlePhotoData = (id) => {
    const data = imageInfo.filter((data) => data.id === id);
    return data[0];
  };

  return (
    <div className="profile-area-main">
      <Navbar alwaysShow={true} hideSearch={true} navDropshadow={true} changedUser={false} />
      <Container>
        <div className="photo-upload-main">
          <div className="photo_upload_title">
            <h5>Upload Photos</h5>
            <h6>Add new photos to "{albumName}" album</h6>
          </div>

          <div className="about_upload_note">
            <h6>About your uploads</h6>
            <ul>
              <li>
                You can see royalty percentages and details in our <Link to="/terms-conditions">Terms and Conditions</Link>.
              </li>
              <li>
                Please make sure your photos meet the required criteria described in the <Link to="/photo-upload-guideline">photo upload guideline.</Link>
              </li>
              <li>Every photo you upload will be reviewed before being published live on Pix.lk. You will receive a notification when a photo is accepted or rejected.</li>
            </ul>
          </div>

          <Previews />

          <div className="new-image-upload-section">
            {files.map((fileItem, index) => (
              <SinglePhotoHandler
                fileItem={fileItem}
                key={fileItem.id}
                fileIndex={index}
                handleUploadSuccess={handleUploadSuccess}
                handleUploadFailure={handleUploadFailure}
                disabledStatus={disabledStatus}
                handleOnChange={handleOnChange}
                handleOnCheckboxChange={handleOnCheckboxChange}
                onInputTagChange={onInputTagChange}
                onInputChange={onInputChange}
                handleUploadPhotoToAlbum={handleUploadPhotoToAlbum}
                removeImage={removeImage}
                isReviewLoading={isReviewLoading === index}
                photoStatus={haandlePhotoStatus(fileItem.id)}
                photoInfo={haandlePhotoData(fileItem.id)}
                tagsRef={tagsRef}
              />
            ))}
          </div>

          <div className="add-to-album-button">
            <Button
              type="button"
              className="pix-main-button upload-img-btn"
              disabled={!uploadStatus.some((file) => file.uploadStatus === "uploaded")}
              onClick={!isLoadingAll ? handleUploadAllPhotosToAlbum : () => {}}
            >
              {isLoadingAll ? (
                <>
                  <Spinner animation="border" role="status" variant="light" size="sm"></Spinner>
                  Please wait...
                </>
              ) : (
                "Submit all for Review"
              )}
            </Button>
          </div>
        </div>
      </Container>

      <Footer simpleFooter={true} />
    </div>
  );
};

export default PhotoUpload;
