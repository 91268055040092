import React, { useState, useEffect } from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { useHistory } from "react-router-dom";
import "../../../styles/searchresults.css";
import { Spinner } from "react-bootstrap";
import { SearchAPI } from "../../api/SearchAPI";
import { getParams } from "../../../methods/getParams";
import { Link } from "react-router-dom";
import imagesLoaded from "imagesloaded";
import {
  MdFileDownload,
  MdRemoveRedEye,
  MdAddCircleOutline,
  MdThumbUp,
  MdFavorite,
  MdCreateNewFolder,
  MdFavoriteBorder,
} from "react-icons/md";
import userImage from "../../../images/user.jpg";
import { isAuthenticated } from "../../auth/Authenticate";
import {
  addFavourite,
  getFavourites,
  deleteFavourite,
} from "../../api/FavouriteAPI";
import AddToCollectionModal from "../profile/collections/AddToCollectionModal";
import { PhotosDetailAPI } from "../../api/PhotosDetailAPI";
import { getGenericCsrf } from "../../api/GenericAPI";
import LoginModal from "../login/LoginModal";

function resizeMasonryItem(item: any) {
  if (document.getElementsByClassName("masonry")[0] !== undefined) {
    let grid = document.getElementsByClassName("masonry")[0],
      rowGap = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-row-gap") //10
      ),
      rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-auto-rows") //0
      );
    let rowSpan = Math.ceil(
      (item.querySelector(".masonry-content").getBoundingClientRect().height +
        rowGap) /
        (rowHeight + rowGap)
    );
    item.style.gridRowEnd = "span " + rowSpan;
  }
}

function resizeAllMasonryItems() {
  let allItems = document.getElementsByClassName("masonry-brick");
  for (let i = 0; i < allItems.length; i++) {
    resizeMasonryItem(allItems[i]);
  }
}

function waitForImages() {
  let allItems = document.getElementsByClassName("masonry-brick");
  for (let i = 0; i < allItems.length; i++) {
    imagesLoaded(allItems[i], (instance: any) => {
      const item = instance.elements[0];
      const cardForegroundEl =
        instance.images[0].img.parentElement.parentElement.querySelector(
          ".image-card-fg"
        );
      item.style.display = "block";
      let t = setTimeout(() => {
        cardForegroundEl.classList.add("hide");
        clearTimeout(t);
      }, 200 + +cardForegroundEl.parentElement.getAttribute("data-card-index") * 120);
      resizeMasonryItem(item);
    });
  }
}

const events = ["load", "resize"];
events.forEach((event) => {
  window.addEventListener(event, resizeAllMasonryItems);
});

const SearchResults = (props: any) => {
  const [query, setQuery] = useState<any>("");
  const [searchedImages, setSearchedImages] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [state, setState] = useState<string>("loading");
  const [modalShow, setModalShow] = useState(false);
  const [addToCollectionPhotoId, setAddToCollectionPhotoId] = useState("");
  const [favourites, setFavourites] = useState<any>([]);
  // const [isFavourite, setIsFavourite] = useState<boolean>(false);
  const [modalShowLogin, setModalShowLogin] = useState<boolean>(false);

  const history = useHistory();

  const q = getParams("q");

  useEffect(() => {
    if (isAuthenticated()) {
      getFavourites(
        (response: any) => {
          const favouritesIds = response.data.map((a: any) => {
            return a.id;
          });
          setFavourites(favouritesIds);
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }, []);

  useEffect(() => {
    setQuery(q);
    setState("loading");
    SearchAPI(
      q,
      (data: any) => {
        setLoading(false);
        setSearchedImages(data.data);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [q]);

  useEffect(waitForImages, [searchedImages]);

  useEffect(() => {
    let loaded = 0;
    let cards = document.getElementsByClassName("image-card");
    for (let i = 0; i < cards.length; i++) {
      // eslint-disable-next-line no-loop-func
      imagesLoaded(cards[i], (instance: any) => {
        if (instance.isComplete) loaded++;
        if (loaded) setState("loaded");
      });
    }
  }, [searchedImages]);

  const handleAddToCollectionButton = (imageId: any) => {
    if (isAuthenticated()) {
      setAddToCollectionPhotoId(imageId);
      setModalShow(true);
    } else {
      // history.push("/signup");
      setModalShowLogin(true);
    }
  };

  // useEffect(() => {
  //   if (isAuthenticated()) {
  //     getFavourites(
  //       (response: any) => {
  //         const favouritesIds = response.data.map((a: any) => {
  //           return a.id;
  //         });
  //         setFavourites(favouritesIds);
  //         setIsFavourite(favouritesIds.includes(getParams("id")));
  //       },
  //       (error: any) => {
  //         console.log(error);
  //       }
  //     );
  //   }
  // }, []);

  ////////////////////////////////////////////////////////////////////////////////

  // const handleAddToFavouritesButton = (imageId: any) => {
  //   let imageData = { photo_id: imageId };
  //   if (isAuthenticated()) {
  //     setIsFavourite(true);
  //     setFavourites([...favourites, imageId]);
  //     addFavourite(
  //       imageData,
  //       (response: any) => {},
  //       (error: any) => {
  //         let tempFavourites = favourites.filter(
  //           (el: string) => el !== imageId
  //         );
  //         setFavourites(tempFavourites);
  //       }
  //     );
  //   } else {
  //     // history.push("/signup");
  //     setModalShowLogin(true);
  //   }
  // };

  // const handleRemoveFromFavouritesButton = (imageId: any) => {
  //   let imageData = { photo_id: imageId };
  //   if (isAuthenticated()) {
  //     setIsFavourite(false);
  //     deleteFavourite(
  //       imageId,
  //       (response: any) => {
  //         let tempFavourites = favourites.filter(
  //           (el: string) => el !== imageId
  //         );
  //         setFavourites(tempFavourites);
  //       },
  //       (error: any) => {}
  //     );
  //   } else {
  //     // history.push("/signup");
  //     setModalShowLogin(true);
  //   }
  // };
  const handleAddToFavouritesButton = (imageId: any) => {
    let imageData = { photo_id: imageId };
    if (isAuthenticated()) {
      setFavourites([...favourites, imageId]);
      addFavourite(
        imageData,
        (response: any) => {},
        (error: any) => {
          let tempFavourites = favourites.filter(
            (el: string) => el !== imageId
          );
          setFavourites(tempFavourites);
        }
      );
    } else {
      setModalShowLogin(true);
    }
  };

  const handleRemoveFromFavouritesButton = (imageId: any) => {
    let imageData = { photo_id: imageId };
    if (isAuthenticated()) {
      deleteFavourite(
        imageId,
        (response: any) => {
          let tempFavourites = favourites.filter(
            (el: string) => el !== imageId
          );
          setFavourites(tempFavourites);
        },
        (error: any) => {}
      );
    } else {
      setModalShowLogin(true);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="search-results-wrapper">
      <Navbar alwaysShow={true} innerPage={true} navDropshadow={true} />

      <div className="search-results-page">
        <div className="search query-display">
          <h3>{query}</h3>
          <p>{searchedImages.length} Photos</p>
        </div>
        {loading ? (
          <div className="image-loading-display">
            <Spinner
              animation="border"
              className="home-page-loading-spinner"
            ></Spinner>
          </div>
        ) : (
          <div className="masonry">
            {searchedImages.map((item: any, index: any) => (
              // <div
              //   className="results-masonry-item"
              //   key={index}
              //   onClick={() => history.push(`/photodetail?id=${item.id}`)}
              // >
              //   <img
              //     className="results-masonry-img"
              //     src={item.public_urls.thumbnail}
              //     // alt={item.alt}
              //   />

              //   <div className="result-info-bar">
              //     <h5 style={{ marginBottom: 0 }}>
              //       {item.uploader.first_name} {item.uploader.last_name}
              //     </h5>
              //     {/* <h6>{item.user}</h6> */}
              //   </div>
              // </div>
              <div className="masonry-brick" style={{ display: "none" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    position: "absolute",
                  }}
                >
                  <MdCreateNewFolder
                    className="addToCollectionsIcon"
                    style={{
                      marginTop: "5px",
                      marginLeft: "5px",
                      color: "#ffff",
                      cursor: "pointer",
                    }}
                    onClick={() => handleAddToCollectionButton(item.id)}
                    title="Add to collections"
                  />
                  {favourites.includes(item.id) ? (
                    <MdFavorite
                      title="Remove from favourites"
                      className="addToCollectionsIcon"
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                        color: "#e64a19",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleRemoveFromFavouritesButton(item.id);
                      }}
                    />
                  ) : (
                    <MdFavoriteBorder
                      title="Add to favourites"
                      className="addToCollectionsIcon"
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                        color: "#ffff",
                        cursor: "pointer",
                      }}
                      onClick={() => handleAddToFavouritesButton(item.id)}
                    />
                  )}
                  {/* {isFavourite ? (
                    <MdFavorite
                      title={
                        favourites.includes(item.id)
                          ? "Remove from favourites"
                          : "Add to favourites"
                      }
                      className="addToCollectionsIcon"
                      style={
                        favourites.includes(item.id)
                          ? {
                              marginTop: "5px",
                              marginLeft: "5px",
                              color: "#e64a19",
                              cursor: "pointer",
                            }
                          : {
                              marginTop: "5px",
                              marginLeft: "5px",
                              color: "#ffff",
                              cursor: "pointer",
                            }
                      }
                      onClick={
                        favourites.includes(item.id)
                          ? () => handleRemoveFromFavouritesButton(item.id)
                          : () => handleAddToFavouritesButton(item.id)
                      }
                    />
                  ) : (
                    <MdFavoriteBorder
                      title={
                        favourites.includes(item.id)
                          ? "Remove from favourites"
                          : "Add to favourites"
                      }
                      className="addToCollectionsIcon"
                      style={
                        favourites.includes(item.id)
                          ? {
                              marginTop: "5px",
                              marginLeft: "5px",
                              color: "#e64a19",
                              cursor: "pointer",
                            }
                          : {
                              marginTop: "5px",
                              marginLeft: "5px",
                              color: "#ffff",
                              cursor: "pointer",
                            }
                      }
                      onClick={
                        favourites.includes(item.id)
                          ? () => handleRemoveFromFavouritesButton(item.id)
                          : () => handleAddToFavouritesButton(item.id)
                      }
                    />
                  )} */}
                </div>
                <Link to={`/photodetail/${item.id}`}>
                  <div
                    className="image-card masonry-content"
                    data-card-index={`${index}`}
                  >
                    <img
                      className="image"
                      src={item.public_urls.thumbnail}
                      alt={item.alt}
                    />
                    <div className="image-card--clickable-area" />
                    <div className="image-card--options">
                      <div className="user-info">
                        <div className="photo-detail-icons">
                          <div>
                            <img
                              src={
                                item.uploader.profile_photo
                                  ? `${item.uploader.profile_photo}?w=35&h=35`
                                  : userImage
                              }
                              alt={item.uploader.name}
                            />
                          </div>
                          <div className="user-name-div">
                            {item.uploader.username}
                          </div>
                        </div>
                        <div className="photo-detail-icons">
                          <div>
                            <MdThumbUp /> {item.like_count}{" "}
                          </div>
                          <div className="download-icon-div">
                            <MdRemoveRedEye /> {item.view_count}{" "}
                          </div>
                          <div className="download-icon-div">
                            {" "}
                            <MdFileDownload /> {item.download_count}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="image-card-fg" />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
      <AddToCollectionModal
        //handleGetCollections={handleGetCollections}
        show={modalShow}
        onHide={() => setModalShow(false)}
        photoId={addToCollectionPhotoId}
      />
      <LoginModal
        show={modalShowLogin}
        onHide={() => {
          setModalShowLogin(false);
        }}
      ></LoginModal>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default SearchResults;
