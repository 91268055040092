import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";

export const PhotoWithWaterMarkAPI = (
  id: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/public/photos/${id}/image?fullsize=true`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
        responseType: "blob",
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
