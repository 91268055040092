//@ts-nocheck
import React, { useState, useEffect } from "react";
import Navbar from "../../common/Navbar";
import PublicProfileHeader from "./PublicProfileHeader";
import { Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Footer from "../../common/Footer";
import { useParams } from "react-router-dom";
// import { getPhotoData } from "../../../api/PrivatePhotosAPI";
import { useHistory } from "react-router-dom";
// import PhotoDetailsPopup from "./PublicPhotoDetailsPopup";
import {
  getPublicPhotoDetails,
  getPublicPhotoData,
} from "../../../api/PublicProfileAreaAPIs";
import axios from "axios";
import { getCSRFToken } from "../../../auth/Authenticate";

const ViewThePublicAlbum = () => {
  // const [modalShow, setModalShow] = useState(false);
  const [album, setAlbum] = useState({});
  // const [fullPhotoDetails, setFullPhotoDetails] = useState({});
  // const [photoDetailsShow, setPhotoDetailsShow] = useState(false);
  const [lastObj, setLastObj] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  //@ts-ignore
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getAlbumData();
    const myWidth = window.innerWidth;
    setWindowWidth(myWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const myWidth = window.innerWidth;
      setWindowWidth(myWidth);
    });
  }, [window]);

  const getAlbumData = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/public/albums/${id}`,
      header
    );

    const albumData = await getAlbum.data;
    albumData.photos = albumData.photos.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    setAlbum(albumData);

    if (albumData.photos.length > 0) {
      for (const photo of await albumData.photos) {
        let photoObj = {};
        photoObj = photo;

        if (!photo?.public_urls?.thumbnail) {
          let getPhotosThumb = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/public/photos/${photo.id}/image?fullsize=false`,
            headerPhoto
          );
          photoObj["thumbnail"] = URL.createObjectURL(getPhotosThumb.data);
          photoObj["loading"] = false;
          setLastObj(photoObj);
        } else {
          photoObj["loading"] = false;
          setLastObj(photoObj);
        }
      }
    }
  };

  // const handleGetPhotoDetails = (photoId: string) => {
  //   getPublicPhotoDetails(
  //     photoId,
  //     (response: any) => {
  //       let photoData = response.data;
  //       getPublicPhotoData(
  //         photoId,
  //         true,
  //         (response: any) => {
  //           photoData["fullsize"] = URL.createObjectURL(response.data);
  //           setFullPhotoDetails(photoData);
  //         },
  //         (error: any) => {}
  //       );
  //     },
  //     (error: any) => {}
  //   );
  // };

  const handlePhotoDetails = (photoId: object) => {
    // handleGetPhotoDetails(photoDetails.id);
    // setPhotoDetailsShow(true);
    history.push(`/photodetail/${photoId}`);
  };

  const albumThumbnail = (album: any) => {
    if (album?.public_urls?.thumbnail) {
      return album?.public_urls?.thumbnail;
    } else if (album.thumbnail) {
      return album.thumbnail;
    }
    return AlbumPlaceholder;
  };

  return (
    <div className="profile-area-main">
      <Navbar alwaysShow={true} hideSearch={true} />
      <PublicProfileHeader
        publicArea={true}
        userId={album && album.photos && album.photos[0].uploader.username}
      />

      <Container>
        <div className="view-album-wrapper">
          <div className="view-album-header view-public-album-header ">
            {album && album.photos && (
              <Link
                to={"/public/albums/" + album.photos[0].uploader.username}
                className="back-to-albums-btn"
              >
                <MdKeyboardArrowLeft /> Back to Albums
              </Link>
            )}

            <div className="album-info-sec">
              <h6 className="album-info-sec-title">{album?.name}</h6>
              {album?.tags &&
                album?.tags.split(",").map((tag, i) => (
                  <span key={i} className="image-details-tags-display">
                    {tag}
                  </span>
                ))}
              <p>
                {album && album.photos
                  ? album.photos.length + " Photo(s)"
                  : null}
              </p>
            </div>
          </div>

          <div className="album-all-images-display">
            {album?.photos &&
              album.photos.map((photo) => (
                <div
                  className="pa-album-images-block"
                  key={photo.id}
                  onClick={() => handlePhotoDetails(photo.id)}
                  // style={
                  //   windowWidth > 768 ? { width: photo.metadata.width / 8 } : {}
                  // }
                >
                  {photo.loading === false ? (
                    <img src={albumThumbnail(photo)} alt="pix album images" />
                  ) : (
                    <div className="album-spinner">
                      <Spinner
                        variant="dark"
                        animation="border"
                        role="status"
                      ></Spinner>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Container>

      {/* <PhotoDetailsPopup
        handleGetPhotoDetails={handleGetPhotoDetails}
        fullPhotoDetails={fullPhotoDetails}
        show={photoDetailsShow}
        onHide={() => {
          setPhotoDetailsShow(false);
          setFullPhotoDetails({});
        }}
      /> */}
      <Footer simpleFooter={true} />
    </div>
  );
};

export default ViewThePublicAlbum;
