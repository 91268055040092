import React, { useEffect, useState, Fragment, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import imagesLoaded from "imagesloaded";
import { Spinner } from "react-bootstrap";
import "../../../styles/gallery.css";
import { FeaturedPhotosAPI } from "../../api/FeaturedPhotosAPI";
import {
  MdFileDownload,
  MdRemoveRedEye,
  MdCreateNewFolder,
  MdFavorite,
  MdFavoriteBorder,
  MdThumbUp,
} from "react-icons/md";
import { isAuthenticated } from "../../auth/Authenticate";
import AddToCollectionModal from "../profile/collections/AddToCollectionModal";
import {
  addFavourite,
  getFavourites,
  deleteFavourite,
} from "../../api/FavouriteAPI";

import fallbackUser from "../../../images/user.svg";
import LoginModal from "../login/LoginModal";

const axios = require("axios").default;
const API = "https://api.unsplash.com";
const CLIENT_ID =
  "8e31e45f4a0e8959d456ba2914723451b8262337f75bcea2e04ae535491df16d";
const DEFAULT_IMAGE_COUNT = 24;

export const Gallery: React.FC = () => {
  const [images, setImages] = useState<any>([]);
  const [clickedImage, setClickedImage] = useState<object>({});
  const [isModalActive, setModalActive] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);

  const [state, setState] = useState<string>("loading");

  const getRandomPhotos = () => {
    setState("loading");

    FeaturedPhotosAPI(
      (response: any) => {
        let len = response.data;
        let photos = gettingImages(len);
        setImages(photos);
      },
      (error: any) => {
        console.log("error");
      }
    );
  };

  const gettingImages = (len: any) => {
    const photos = [];

    for (let i = 0; i < len.length; i++) {
      let obj = {
        id: len[i].id,
        urls: {
          regular: `${len[i].public_urls.thumbnail}?fullsize=false`,
        },
        uploader: {
          name: len[i].uploader.first_name + " " + len[i].uploader.last_name,
          username: len[i].uploader.username,
          profile_photo: len[i].uploader.profile_photo,
        },
        view_count: len[i].view_count,
        download_count: len[i].download_count,
        like_count: len[i].like_count,
      };
      photos.push(obj);
    }

    return photos;
  };

  useEffect(getRandomPhotos, [failedToLoad]);

  useEffect(waitForImages, [images]);

  useEffect(() => {
    let loaded = 0;
    let cards = document.getElementsByClassName("image-card");
    for (let i = 0; i < cards.length; i++) {
      // eslint-disable-next-line no-loop-func
      imagesLoaded(cards[i], (instance: any) => {
        if (instance.isComplete) loaded++;
        if (loaded) setState("loaded");
      });
    }
  }, [images]);

  const handleImageClick = (img: any) => {
    setClickedImage(img);
    setModalActive(true);
  };

  return (
    <Fragment>
      {(!failedToLoad && (
        <main>
          <ImageCardList
            images={images}
            onImageClicked={(img: any) => handleImageClick(img)}
            loadingState={state}
          />
          <h3
            className="text-info loading-text"
            style={{
              display: state === "loading" ? "block" : "none",
              lineHeight: "250px",
            }}
          >
            <Spinner
              animation="border"
              className="home-page-loading-spinner"
            ></Spinner>
          </h3>
        </main>
      )) ||
        (failedToLoad && (
          <div className="failed-info-container">
            <h1 className="failed-text">Failed To Load</h1>
            <p>check your connection</p>
          </div>
        ))}

      {isModalActive && (
        <MasonryModal
          imageData={clickedImage}
          onModalActive={(isActive: boolean) => setModalActive(isActive)}
        />
      )}
    </Fragment>
  );
};

function resizeMasonryItem(item: any) {
  if (document.getElementsByClassName("masonry")[0] !== undefined) {
    let grid = document.getElementsByClassName("masonry")[0],
      rowGap = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-row-gap") //10
      ),
      rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-auto-rows") //0
      );
    let rowSpan = Math.ceil(
      (item.querySelector(".masonry-content").getBoundingClientRect().height +
        rowGap) /
        (rowHeight + rowGap)
    );
    item.style.gridRowEnd = "span " + rowSpan;
  }
}

function resizeAllMasonryItems() {
  let allItems = document.getElementsByClassName("masonry-brick");
  for (let i = 0; i < allItems.length; i++) {
    resizeMasonryItem(allItems[i]);
  }
}

function waitForImages() {
  let allItems = document.getElementsByClassName("masonry-brick");
  for (let i = 0; i < allItems.length; i++) {
    imagesLoaded(allItems[i], (instance: any) => {
      const item = instance.elements[0];
      const cardForegroundEl =
        instance.images[0].img.parentElement.parentElement.querySelector(
          ".image-card-fg"
        );
      item.style.display = "block";
      let t = setTimeout(() => {
        cardForegroundEl.classList.add("hide");
        clearTimeout(t);
      }, 200 + +cardForegroundEl.parentElement.getAttribute("data-card-index") * 120);
      resizeMasonryItem(item);
    });
  }
}

const events = ["load", "resize"];
events.forEach((event) => {
  window.addEventListener(event, resizeAllMasonryItems);
});

const ImageCard = (obj: {
  imageData: any;
  imageIndex: any;
  onImageClicked: any;
  favouriteImage: any;
  handleAddToFavouritesButton: any;
  handleRemoveFromFavouritesButton: any;
}) => {
  const {
    urls,
    alt_description,
    uploader,
    links,
    color,
    id,
    view_count,
    like_count,
    download_count,
  } = obj.imageData;

  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowLogin, setModalShowLogin] = useState<boolean>(false);

  const [addToCollectionPhotoId, setAddToCollectionPhotoId] = useState("");

  const handleAddToCollectionButton = (imageId: any) => {
    if (isAuthenticated()) {
      setAddToCollectionPhotoId(imageId);
      setModalShow(true);
    } else {
      //history.push("/loginmodal");
      setModalShowLogin(true);
    }
  };

  return (
    <div className="masonry-brick" style={{ display: "none" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          position: "absolute",
        }}
        className="image-icons-div"
      >
        <MdCreateNewFolder
          className="addToCollectionsIcon"
          style={{
            marginTop: "5px",
            marginLeft: "5px",
            color: "#ffff",
            cursor: "pointer",
          }}
          onClick={() => handleAddToCollectionButton(id)}
          title="Add to collections"
        />
        {obj.favouriteImage ? (
          <MdFavorite
            title="Remove from favourites"
            className="addToCollectionsIcon"
            style={{
              marginTop: "5px",
              marginLeft: "5px",
              color: "#e64a19",
              cursor: "pointer",
            }}
            onClick={() => {
              obj.handleRemoveFromFavouritesButton(id);
            }}
          />
        ) : (
          <MdFavoriteBorder
            title="Add to favourites"
            className="addToCollectionsIcon"
            style={{
              marginTop: "5px",
              marginLeft: "5px",
              color: "#ffff",
              cursor: "pointer",
            }}
            onClick={() => obj.handleAddToFavouritesButton(id)}
          />
        )}
      </div>
      <Link to={`/photodetail/${id}`}>
        <div
          className="image-card masonry-content"
          style={{
            background: color,
          }}
          data-card-index={`${obj.imageIndex}`}
        >
          <img className="image" src={urls.regular} alt={alt_description} />
          <div
            className="image-card--clickable-area"
            onClick={() => {
              obj.onImageClicked(obj.imageData);
              // document.title = alt_description;
            }}
          />
          <div className="image-card--options">
            <div className="user-info">
              <div className="photo-detail-icons">
                <div>
                  <img
                    src={
                      uploader?.profile_photo
                        ? `${uploader.profile_photo}?w=35&h=35`
                        : fallbackUser
                    }
                  />
                </div>
                <div className="user-name-div">{uploader.username}</div>
              </div>
              <div className="photo-detail-icons">
                <div>
                  <MdThumbUp /> {like_count}
                </div>
                <div className="download-icon-div">
                  <MdRemoveRedEye /> {view_count}
                </div>
                <div className="download-icon-div">
                  <MdFileDownload /> {download_count}
                </div>
              </div>
            </div>
          </div>

          <div
            className="image-card-fg"
            style={{
              background: color,
            }}
          />
        </div>
      </Link>
      {addToCollectionPhotoId && (
        <AddToCollectionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          photoId={addToCollectionPhotoId}
        />
      )}
      <LoginModal
        show={modalShowLogin}
        onHide={() => {
          setModalShowLogin(false);
        }}
        nav
      ></LoginModal>
    </div>
  );
};

const ImageCardList = (obj: {
  images: any;
  onImageClicked: any;
  loadingState: string;
}) => {
  const [favourites, setFavourites] = useState<any>([]);
  const [modalShowLogin, setModalShowLogin] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated()) {
      getFavourites(
        (response: any) => {
          const favouritesIds = response.data.map((a: any) => {
            return a.id;
          });
          setFavourites(favouritesIds);
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }, []);

  const handleAddToFavouritesButton = (imageId: any) => {
    let imageData = { photo_id: imageId };
    if (isAuthenticated()) {
      setFavourites([...favourites, imageId]);
      addFavourite(
        imageData,
        (response: any) => {},
        (error: any) => {
          let tempFavourites = favourites.filter(
            (el: string) => el !== imageId
          );
          setFavourites(tempFavourites);
        }
      );
    } else {
      setModalShowLogin(true);
    }
  };

  const handleRemoveFromFavouritesButton = (imageId: any) => {
    let imageData = { photo_id: imageId };
    if (isAuthenticated()) {
      deleteFavourite(
        imageId,
        (response: any) => {
          let tempFavourites = favourites.filter(
            (el: string) => el !== imageId
          );
          setFavourites(tempFavourites);
        },
        (error: any) => {}
      );
    } else {
      setModalShowLogin(true);
    }
  };

  return (
    <>
      <div className="masonry">
        {obj.images.map((image: any, index: number) => (
          <ImageCard
            imageData={image}
            key={image.id}
            imageIndex={index}
            onImageClicked={(img: any) => obj.onImageClicked(img)}
            favouriteImage={favourites.includes(image.id)}
            handleRemoveFromFavouritesButton={handleRemoveFromFavouritesButton}
            handleAddToFavouritesButton={handleAddToFavouritesButton}
          />
        ))}
        <LoginModal
          show={modalShowLogin}
          onHide={() => {
            setModalShowLogin(false);
          }}
          nav
        ></LoginModal>
      </div>
    </>
  );
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MasonryModal = (obj: { imageData: any; onModalActive: any }) => {
  const {
    urls,
    alt_description,
    color,
    links,
    height,
    width,
    created_at,
    exif,
  } = obj.imageData;

  const date = new Date(created_at);

  const [dropdownActive, setDropdownActive] = useState(false);
  const [infoActive, setInfoActive] = useState(false);
  const [views, setViews] = useState(0);
  const [downloads, setDownloads] = useState(0);
  const [likes, setLikes] = useState(0);
  const [viewsLastMonth, setViewsLastMonth] = useState(0);
  const [downloadsLastMonth, setDownloadsLastMonth] = useState(0);
  const [likesLastMonth, setLikesLastMonth] = useState(0);

  const modalEl: any = useRef();
  const imageEl: any = useRef();

  // const handleClose = () => {
  //   modalEl.current.style.display = "none";
  //   obj.onModalActive(false);
  //   document.title = "Image Gallery | PIX";
  // };

  const handleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const fetchStats = () => {
    axios(`${API}/photos/${obj.imageData.id}/statistics`, {
      params: {
        client_id: CLIENT_ID,
      },
    }).then((res: any) => {
      // get all the values in an array
      const lastMonthViewValues = res.data.views.historical.values.map(
        (e: any) => e.value
      );
      const lastMonthDownloadValues = res.data.downloads.historical.values.map(
        (e: any) => e.value
      );
      const lastMonthLikeValues = res.data.likes.historical.values.map(
        (e: any) => e.value
      );

      // add up the array
      const lastMonthTotalViews: any = numberFormatter(
        returnTotal(lastMonthViewValues),
        1
      );
      const lastMonthTotalDownloads: any = numberFormatter(
        returnTotal(lastMonthDownloadValues),
        1
      );
      const lastMonthTotalLikes: any = numberFormatter(
        returnTotal(lastMonthLikeValues),
        1
      );

      // set all the values
      setViews(res.data.views.total);
      setDownloads(res.data.downloads.total);
      setLikes(res.data.likes.total);
      setViewsLastMonth(lastMonthTotalViews);
      setDownloadsLastMonth(lastMonthTotalDownloads);
      setLikesLastMonth(lastMonthTotalLikes);
    });
  };

  useEffect(fetchStats, []);

  return <p>x</p>;
};

function numberFormatter(num: number, digits: number) {
  let si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

function beautifyNumber(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function returnTotal(arrOfValues: number[]) {
  return arrOfValues.reduce((a, c) => (a += c));
}
