import React, { useState, useEffect } from "react";
import qs from "qs";
import { BiCheckCircle, BiErrorCircle } from "react-icons/bi";

import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import "../../../styles/purchase.css";
import { useHistory } from "react-router-dom";

import { PhotoDownloadAPI } from "../../api/PhotoDownloadAPI";
import { getParams } from "../../../methods/getParams";

import "../../../styles/success-error.css";

declare global {
  interface Window {
    Stripe: any;
  }
}

const Download = () => {
  const [isSuccess, setIsSuccess] = useState<Boolean>(false);
  const [ref, setRef] = useState<any>("");
  const [photo, setPhoto] = useState<any>("");
  const [loading, setLoading] = useState<Boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const success = getParams("success");
    const ref = getParams("ref");

    if (success) {
      setIsSuccess(JSON.parse(success));
    }

    if (ref) handleDownload(ref);
  }, []);

  const handleDownload = (ref: any) => {
    // if (validate()) {
    setLoading(true);

    PhotoDownloadAPI(
      ref,
      (data: any) => {
        setPhoto(URL.createObjectURL(data.data));
        setLoading(false);
      },
      (error: any) => {
        setLoading(false);
        console.log(error);
      }
    );
    // }
  };

  return (
    <div className="purchase-main-wrapper">
      <Navbar
        alwaysShow={true}
        innerPage={true}
        navDropshadow={true}
        hideSearch={true}
      />
      <div className="purchase-wrapper">
        <Container>
          <div className="success-error-div">
            {isSuccess ? (
              <BiCheckCircle className="icon icon-success" />
            ) : (
              <BiErrorCircle className="icon icon-error" />
            )}

            <p className="msg-heading">
              {isSuccess ? "Payment Successful!" : "Payment Error!"}
            </p>
            {isSuccess ? (
              <p className="msg-body">
                You can download the photo by clicking "Download" button.
              </p>
            ) : (
              <p className="msg-body">
                Your payment has been unsuccessfull. Try again.
              </p>
            )}
            {isSuccess ? (
              <a href={photo} style={{ textDecoration: "none" }} download>
                <button className="btn btn-primary pix-main-button download-btn">
                  {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      variant="light"
                      size="sm"
                    ></Spinner>
                  ) : (
                    <>Download</>
                  )}
                </button>
              </a>
            ) : (
              <button
                className="btn btn-primary pix-main-button download-btn"
                onClick={() => history.push("/")}
              >
                Go to Home
              </button>
            )}
          </div>

          {/* <img src={photo} alt="" /> */}
        </Container>
      </div>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default Download;
