import React, { useContext, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import {
  handleUserSignUp,
  handleCreateAUserSession,
  checkUsernameAvailability,
  checkEmailAvailability,
} from "../../api/SignInAndSignUpAPI";
import { toast } from "react-toastify";
import { UserEmail } from "../../context/ContextProvider";
import "../../../styles/signup.css";
import { useHistory } from "react-router-dom";
import { MdInfoOutline } from "react-icons/md";
import { setAuthenticate } from "../../auth/Authenticate";
import { getGenericCsrf } from "../../api/GenericAPI";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";

import MesonaryGallery from "./MesonaryGallery";
import { MdVisibility, MdVisibilityOff, MdFacebook } from "react-icons/md";

const SignUp = () => {
  const history = useHistory();

  const { availableEmail } = useContext(UserEmail);
  const [errors, setErrors] = useState({
    first_nameError: false,
    last_nameError: false,
    emailError: false,
    usernameError: false,
    passwordError: false,
    confirmPasswordError: false,
    first_nameErrorMsg: "",
    last_nameErrorMsg: "",
    emailErrorMsg: "",
    usernameErrorMsg: "",
    passwordErrorMsg: "",
    confirmPasswordErrorMsg: "",
  });
  interface inputFields {
    first_name: string;
    email: string;
    password: string;
    is_creator: boolean;
    ref_code: string;
    auth_provider: string;
    auth_token: string;
  }

  const [registerFields, setRegisterFields] = useState<inputFields>({
    first_name: "",
    // last_name: null,
    email: "",
    // username: null,
    password: "",
    is_creator: false,
    ref_code: "",
    auth_provider: "EMAIL",
    auth_token: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setRegisterFields({
      ...registerFields,
      email: availableEmail,
    });
  }, []);

  useEffect(() => {
    getGenericCsrf(
      (data: any) => {
        localStorage.setItem("CSRF_token", data.id);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, []);

  // useEffect(() => {
  //   const delayCheckUsernameFn = setTimeout(() => {
  //     checkUsernameAvailability(
  //       registerFields.username,
  //       (response: any) => {
  //         console.log(response);
  //       },
  //       (error: any) => {}
  //     );
  //   }, 3000);

  //   return () => clearTimeout(delayCheckUsernameFn);
  // }, [registerFields.username]);

  const handleOnChange = (event: any) => {
    // @ts-ignore
    setRegisterFields({
      ...registerFields,
      [event.target.name]: event.target.value,
    });
  };

  const handleUsernameOnChange = (event: any) => {
    const newName = event.target.value
      .replace(/[^\w\s-]/gi, "")
      .replace(/ /g, "");
    // @ts-ignore
    setRegisterFields({
      ...registerFields,
      [event.target.name]: newName,
    });
  };

  const validate = () => {
    const mailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const passwordValidate = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    let status = {
      first_nameError: false,
      last_nameError: false,
      emailError: false,
      usernameError: false,
      passwordError: false,
      confirmPasswordError: false,
      first_nameErrorMsg: "",
      last_nameErrorMsg: "",
      emailErrorMsg: "",
      usernameErrorMsg: "",
      passwordErrorMsg: "",
      confirmPasswordErrorMsg: "",
    };

    if (registerFields.first_name === "") {
      status.first_nameError = true;
      status.first_nameErrorMsg = "Please enter your first name";
    } else {
      status.first_nameError = false;
      status.first_nameErrorMsg = "";
    }

    // if (registerFields.last_name === "") {
    //   status.last_nameError = true;
    //   status.last_nameErrorMsg = "Please enter your last name";
    // } else {
    //   status.last_nameError = false;
    //   status.last_nameErrorMsg = "";
    // }

    if (registerFields.email === "") {
      status.emailError = true;
      status.emailErrorMsg = "Please enter your email address";
    } else if (!registerFields.email.match(mailValidate)) {
      status.emailError = true;
      status.emailErrorMsg = "Please enter a valid email address";
    } else {
      status.emailError = false;
      status.emailErrorMsg = "";
    }

    // if (registerFields.username === "") {
    //   status.usernameError = true;
    //   status.usernameErrorMsg = "Please enter a username";
    // } else if (
    //   registerFields.username.length < 3 ||
    //   registerFields.username.length > 10
    // ) {
    //   status.usernameError = true;
    //   status.usernameErrorMsg = "Username must be between 3 to 10 characters";
    // } else {
    //   status.usernameError = false;
    //   status.usernameErrorMsg = "";
    // }

    if (registerFields.password === "") {
      status.passwordError = true;
      status.passwordErrorMsg = "Please enter your password";
    } else if (registerFields.password.length < 8) {
      status.passwordError = true;
      status.passwordErrorMsg = "Password must be at least 8 characters";
    } else {
      status.passwordError = false;
      status.passwordErrorMsg = "";
    }

    // if (confirmPassword === "") {
    //   status.confirmPasswordError = true;
    //   status.confirmPasswordErrorMsg = "Please enter your confirm password";
    // } else if (registerFields.password !== confirmPassword) {
    //   status.confirmPasswordError = true;
    //   status.confirmPasswordErrorMsg = "Passwords don't match";
    // } else {
    //   status.confirmPasswordError = false;
    //   status.confirmPasswordErrorMsg = "";
    // }

    if (
      status.first_nameError ||
      status.last_nameError ||
      status.emailError ||
      status.usernameError ||
      status.passwordError ||
      status.confirmPasswordError
    ) {
      setErrors(status);
      setErrorStatus("");
      return false;
    } else {
      setErrors(status);
      setErrorStatus("");
      return true;
    }
  };

  const handleUserRegister = () => {
    if (validate()) {
      setLoading(true);
      const sessionData = {
        user_id: "",
        password: "",
        auth_provider: "EMAIL",
      };

      handleUserSignUp(
        registerFields,
        (response: any) => {
          setLoading(false);
          sessionData.user_id = registerFields.email;
          sessionData.password = registerFields.password;

          handleCreateAUserSession(
            sessionData,
            (response: any) => {
              setAuthenticate();
              setLoading(false);
              history.push("/");
              toast.success(
                "Please check your inbox for the account verification email."
              );
            },
            (error: any) => {
              setLoading(false);
              console.log(error);
            }
          );
        },
        (error: any) => {
          setLoading(false);
          if (error.response && error.response.data) {
            const errorCode = error.response.data.code;
            if (errorCode === 1107) {
              setErrors({
                ...errors,
                usernameError: true,
                usernameErrorMsg: error.response.data.description,
              });
            } else if (errorCode) {
              setErrorStatus(error.response.data.description);
            }
          } else {
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        }
      );
    }
  };

  const responseGoogle = (response: any) => {
    if (response && response.profileObj) {
      setLoading(true);
      let token = response.tokenId;
      const registerFields = {
        first_name: response.profileObj.givenName,
        email: response.profileObj.email,
        last_name: response.profileObj.familyName,
        auth_token: token,
        auth_provider: "GOOGLE",
      };
      const sessionData = {
        auth_token: token,
        auth_provider: "GOOGLE",
      };
      checkEmailAvailability(
        response.profileObj.email,
        (response: any) => {
          if (response.data.value) {
            //signup
            handleUserSignUp(
              registerFields,
              (response: any) => {
                handleCreateAUserSession(
                  sessionData,
                  (response: any) => {
                    setAuthenticate();
                    setLoading(false);
                    history.push("/");
                  },
                  (error: any) => {
                    setLoading(false);
                    console.log(error);
                  }
                );
              },
              (error: any) => {
                setLoading(false);
                if (error.response && error.response.data) {
                  const errorCode = error.response.data.code;
                  if (errorCode === 1107) {
                    setErrors({
                      ...errors,
                      usernameError: true,
                      usernameErrorMsg: error.response.data.description,
                    });
                  } else if (errorCode) {
                    setErrorStatus(error.response.data.description);
                  }
                } else {
                  toast.error(
                    `Something went wrong. Please try again. ${error}`
                  );
                }
              }
            );
          } else {
            //login
            handleCreateAUserSession(
              sessionData,
              (response: any) => {
                setAuthenticate();
                setLoading(false);
                history.push("/");
              },
              (error: any) => {
                setLoading(false);
                console.log(error);
              }
            );
          }
        },
        (error: any) => {
          // setLoading(false);
          toast.error(
            error.response && error.response.data
              ? error.response.data.description +
                  ` Error: ` +
                  error.response.data.code
              : `Something went wrong. Please try again. ${error}`
          );
        }
      );
    } else {
      toast.error(`Something went wrong. Please try again.`);
    }
  };

  const responseFacebook = (response: any) => {
    if (response && response.accessToken) {
      setLoading(true);
      let token = response.accessToken;
      const registerFields = {
        first_name: response.name.split(" ")[0],
        email: response.email,
        last_name: response.name.split(" ")[response.name.split(" ") - 1],
        auth_token: token,
        auth_provider: "FACEBOOK",
      };
      const sessionData = {
        auth_token: token,
        auth_provider: "FACEBOOK",
      };
      checkEmailAvailability(
        response.email,
        (response: any) => {
          if (response.data.value) {
            //signup
            handleUserSignUp(
              registerFields,
              (response: any) => {
                handleCreateAUserSession(
                  sessionData,
                  (response: any) => {
                    setAuthenticate();
                    setLoading(false);
                    history.push("/");
                  },
                  (error: any) => {
                    setLoading(false);
                    console.log(error);
                  }
                );
              },
              (error: any) => {
                setLoading(false);
                if (error.response && error.response.data) {
                  const errorCode = error.response.data.code;
                  if (errorCode === 1107) {
                    setErrors({
                      ...errors,
                      usernameError: true,
                      usernameErrorMsg: error.response.data.description,
                    });
                  } else if (errorCode) {
                    setErrorStatus(error.response.data.description);
                  }
                } else {
                  toast.error(
                    `Something went wrong. Please try again. ${error}`
                  );
                }
              }
            );
          } else {
            //login
            handleCreateAUserSession(
              sessionData,
              (response: any) => {
                setAuthenticate();
                setLoading(false);
                history.push("/");
              },
              (error: any) => {
                setLoading(false);
                console.log(error);
              }
            );
          }
        },
        (error: any) => {
          // setLoading(false);
          toast.error(
            error.response && error.response.data
              ? error.response.data.description +
                  ` Error: ` +
                  error.response.data.code
              : `Something went wrong. Please try again. ${error}`
          );
        }
      );
    } else {
      toast.error(`Something went wrong. Please try again.`);
    }
  };

  return (
    <div className="signup-page-wrapper">
      <Navbar
        alwaysShow={true}
        innerPage={true}
        hideSearch={true}
        navDropshadow={true}
      />

      <div className="signup-page">
        <Container>
          <Row>
            <Col lg="5">
              <h4 className="signup-title">Join the creators' community</h4>
              <h5 className="signup-sub-title">
                Start monetizing your passion
              </h5>

              {errorStatus !== "" ? (
                <Alert variant="danger">
                  <MdInfoOutline /> {errorStatus}
                </Alert>
              ) : null}

              <div className="signup-form">
                <Form noValidate>
                  <Form.Group>
                    <Form.Control
                      name="first_name"
                      placeholder="First Name"
                      required
                      type="text"
                      autoComplete="off"
                      isInvalid={errors.first_nameError}
                      value={registerFields.first_name}
                      onChange={handleOnChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide first name
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* <Form.Group>
                    <Form.Control
                      name="last_name"
                      placeholder="Last Name"
                      required
                      type="text"
                      autoComplete="off"
                      isInvalid={errors.last_nameError}
                      value={registerFields.last_name}
                      onChange={handleOnChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide last name
                    </Form.Control.Feedback>
                  </Form.Group> */}

                  <Form.Group>
                    <Form.Control
                      placeholder="Email"
                      required
                      type="text"
                      name="email"
                      autoComplete="off"
                      isInvalid={errors.emailError}
                      value={registerFields.email}
                      onChange={handleOnChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.emailErrorMsg}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* <Form.Group>
                    <Form.Control
                      placeholder="Username"
                      required
                      type="text"
                      name="username"
                      autoComplete="off"
                      isInvalid={errors.usernameError}
                      value={registerFields.username}
                      onChange={handleUsernameOnChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.usernameErrorMsg}
                    </Form.Control.Feedback>
                  </Form.Group> */}

                  <Form.Group className="password-input-main">
                    <Form.Control
                      className="pix-passowrd-input"
                      placeholder="Password"
                      name="password"
                      required
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      isInvalid={errors.passwordError}
                      value={registerFields.password}
                      onChange={handleOnChange}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="password-eye-icon-div"
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.passwordErrorMsg}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* <Form.Group>
                    <Form.Control
                      placeholder="Confirm Password"
                      name="confirmpassword"
                      required
                      type="password"
                      autoComplete="new-password"
                      isInvalid={errors.confirmPasswordError}
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPasswordErrorMsg}
                    </Form.Control.Feedback>
                  </Form.Group> */}

                  <Form.Group>
                    <Button
                      className="pix-main-button"
                      type="button"
                      onClick={!loading ? handleUserRegister : () => {}}
                      disabled={loading ? true : false}
                      style={{ height: "45px" }}
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          role="status"
                          variant="light"
                          size="sm"
                        ></Spinner>
                      ) : (
                        "Create Account"
                      )}
                    </Button>
                  </Form.Group>
                </Form>
                <Form.Group>
                  <GoogleLogin
                    clientId={
                      process.env.REACT_APP_API_URL == "https://api.pix.lk"
                        ? "201324916082-r2dq9pm1pn5snrd381nam535a90ibloq.apps.googleusercontent.com"
                        : "201324916082-gn1r8kr99lol59ej1isg9krgtn62i9st.apps.googleusercontent.com"
                    }
                    buttonText="Login with google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="google-login-btn"
                  />
                </Form.Group>
                <Form.Group>
                  <FacebookLogin
                    appId="6648352261901868"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="facebook-login-btn"
                    textButton="Login with facebook"
                    icon={
                      <MdFacebook
                        style={{
                          marginRight: "10px",
                          marginTop: "-4px",
                          paddingTop: "0px",
                          fontSize: "27px",
                        }}
                      />
                    }
                  />
                </Form.Group>
                <div className="user-sign-page-change">
                  <span>
                    Have an Account? <Link to="/login">Sign in</Link>
                  </span>
                </div>

                <div className="pix-agreement">
                  <p>
                    By signing up with Pix.lk you are agreeing to our{" "}
                    <Link to="/terms-conditions">Terms of Service</Link> and{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <div className="signup-gallery-wrapper">
                <MesonaryGallery />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default SignUp;
