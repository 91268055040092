import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import {isAuthenticated} from "../auth/Authenticate"
interface ProtectedRouteProps {
  path: string,
  exact: boolean,
  component: any
}

const PrivateRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}: any) => {

  return (
    <Route
      {...rest}
      component={(props: any) =>
        isAuthenticated() === true ? (
          <Component {...props} />
        ) : (
          <Redirect from="" to="/login" />
        )
      }
    />
  );
};
export default PrivateRoute;
