import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import qs from "qs";
import NumberFormat from "react-number-format";
import {
  MdFileDownload,
  MdRemoveRedEye,
  MdKeyboardArrowLeft,
  MdFavorite,
  MdFavoriteBorder,
  MdCreateNewFolder,
  MdShare,
  MdThumbUp
} from "react-icons/md";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { ReactComponent as CameraIcon } from "../../../images/icons/camera.svg";
import { Helmet } from "react-helmet";

import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

import { getGenericCsrf } from "../../api/GenericAPI";
import { PhotosDetailAPI } from "../../api/PhotosDetailAPI";
import { PhotoWithWaterMarkAPI } from "../../api/PhotoWithWaterMarkAPI";
import { CheckoutSessionAPI } from "../../api/CheckoutSessionAPI";
import { getParams } from "../../../methods/getParams";
import { getCSRFToken, isAuthenticated } from "../../auth/Authenticate";
import {
  addFavourite,
  getFavourites,
  deleteFavourite,
} from "../../api/FavouriteAPI";
import AddToCollectionModal from "../profile/collections/AddToCollectionModal";
import axios from "axios";
import ShareModal from "../common/ShareModal";
import BrokenImage from "../../../images/broken-img.jpg";
import LoginModal from "../login/LoginModal";

const PhotoDetail = () => {
  const { photoId } = useParams<{ photoId: string | undefined }>();

  const [photoDetail, setPhotoDetail] = useState<any>({});
  const [photo, setPhoto] = useState<any>("");
  const [price, setPrice] = useState<Number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [imgLoading, setImgLoading] = useState<boolean>(false);
  const [albumName, setAlbumName] = useState("");
  const [name, setName] = useState<any>("");
  const [favourites, setFavourites] = useState<any>([]);
  const [isFavourite, setIsFavourite] = useState<boolean>(false);
  const [addFavouriteModalShow, setAddFavouriteModalShow] = useState(false);
  const [addToCollectionPhotoId, setAddToCollectionPhotoId] = useState("");
  const [isShare, setIsShare] = useState(false);
  const [modalShowLogin, setModalShowLogin] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    getGenericCsrf(
      (data: any) => {
        localStorage.setItem("CSRF_token", data.id);

        getPrice(photoId);
      },
      (error: any) => {
        console.log(error);
      }
    );

    // photo details
    PhotosDetailAPI(
      photoId,
      (data: any) => {
        if (data.data?.public_urls?.full) {
          const url = data.data?.public_urls?.full;
          const { hostname } = new URL(url);
          if (hostname === "api.pix.lk" || hostname === "api-dev.pix.lk") {
            getPhotoWithWaterMark(photoId);
          } else {
            setPhoto(data.data?.public_urls?.full);
          }
        } else {
          getPhotoWithWaterMark(photoId);
        }
        setPhotoDetail(data.data);
        data.data?.name
          ? setName(data.data.name)
          : setName(data.data?.category?.name);
        setLoading(false);
      },
      (error: any) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      getFavourites(
        (response: any) => {
          const favouritesIds = response.data.map((a: any) => {
            return a.id;
          });
          setFavourites(favouritesIds);
          setIsFavourite(favouritesIds.includes(getParams("id")));
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (photoDetail?.album_id) {
      getAlbumData();
    }
  }, [photoDetail?.album_id]);

  const getAlbumData = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let getAlbum = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/public/albums/${photoDetail.album_id}`,
      header
    );
    const albumData = await getAlbum.data;
    setAlbumName(albumData.name);
  };

  // get price
  const getPrice = (id: any) => {
    const reqPayload = qs.stringify({
      price_check: true,
    });

    // price
    CheckoutSessionAPI(
      id,
      reqPayload,
      (data: any) => {
        setPrice(data.data.amount);
      },
      (error: any) => {
        console.log(error);
      }
    );
  };

  // full photo with watermark
  const getPhotoWithWaterMark = (id: any) => {
    setImgLoading(true);
    PhotoWithWaterMarkAPI(
      id,
      (data: any) => {
        const full = URL.createObjectURL(data.data);
        setPhoto(full);
        setImgLoading(false);
      },
      (error: any) => {
        console.log(error);
        setImgLoading(false);
      }
    );
  };

  const getFocalLen = (lens: any) => {
    if (lens) {
      return `• ${lens}mm`;
    } else {
      return false;
    }
  };

  const getFnum = (fnum: any) => {
    if (fnum) {
      return `f/${fnum} `;
    } else {
      return false;
    }
  };

  const getISO = (iso: any) => {
    if (iso) {
      return `• ISO ${iso} `;
    } else {
      return false;
    }
  };

  const calcExposureTime = (expoTime: any) => {
    if (expoTime && expoTime >= 1) {
      return `• ${expoTime}`;
    } else if (expoTime && expoTime < 1) {
      let time = (1 / expoTime).toFixed();
      return `• 1/${time}s `;
    } else {
      return false;
    }
  };

  const hanldePurchaseItem = (photoId: any) => {
    history.push(`/purchase?id=${photoId}`);
  };

  const handleViewPublicGallery = () => {
    history.push(`/public/albums/${photoDetail.uploader.username}`);
  };

  // const handleRemoveFromFavourites = (imageId: any) => {
  //   if (isAuthenticated()) {
  //     setIsFavourite(false);
  //     deleteFavourite(
  //       imageId,
  //       (response: any) => {},
  //       (error: any) => {
  //         setIsFavourite(true);
  //       }
  //     );
  //   } else {
  //     setModalShowLogin(true);
  //   }
  // };

  // const handleAddToFavourites = () => {
  //   if (isAuthenticated()) {
  //     setIsFavourite(true);
  //     let imageData = { photo_id: photoDetail.id };
  //     addFavourite(
  //       imageData,
  //       (response: any) => {},
  //       (error: any) => {
  //         setIsFavourite(false);
  //       }
  //     );
  //   } else {
  //     setModalShowLogin(true);
  //   }
  // };

  const handleAddToFavouritesButton = (imageId: any) => {
    let imageData = { photo_id: imageId };
    if (isAuthenticated()) {
      setFavourites([...favourites, imageId]);
      addFavourite(
        imageData,
        (response: any) => {},
        (error: any) => {
          let tempFavourites = favourites.filter(
            (el: string) => el !== imageId
          );
          setFavourites(tempFavourites);
        }
      );
    } else {
      setModalShowLogin(true);
    }
  };

  const handleRemoveFromFavouritesButton = (imageId: any) => {
    let imageData = { photo_id: imageId };
    if (isAuthenticated()) {
      deleteFavourite(
        imageId,
        (response: any) => {
          let tempFavourites = favourites.filter(
            (el: string) => el !== imageId
          );
          setFavourites(tempFavourites);
        },
        (error: any) => {}
      );
    } else {
      setModalShowLogin(true);
    }
  };

  const handleAddToCollectionButton = (imageId: any) => {
    if (isAuthenticated()) {
      setAddToCollectionPhotoId(imageId);
      setAddFavouriteModalShow(true);
    } else {
      setModalShowLogin(true);
    }
  };

  return (
    <div className="photo-detail-wrapper">
      <Helmet>
        <meta
          property="og:image"
          content={photoDetail?.public_urls?.thumbnail}
        />
      </Helmet>

      <Navbar
        alwaysShow={true}
        innerPage={true}
        navDropshadow={true}
        hideSearch={true}
      />
      <div className="photo-detail-inner-wrapper">
        <Container>
          <span
            className="back-to-results-btn"
            onClick={() => history.goBack()}
          >
            <MdKeyboardArrowLeft
              style={{ fontSize: 26, marginLeft: "-10px" }}
            />{" "}
            Back
          </span>
          {loading ? (
            <div className="image-loading-display" style={{ border: "none" }}>
              <Spinner
                animation="border"
                className="home-page-loading-spinner"
              ></Spinner>
            </div>
          ) : (
            <div className="photo-detail-inner">
              <div
                style={{ fontSize: "22px", fontWeight: "bold" }}
                className="photo-details-title"
              >
                {name}
              </div>
              <h6 className="photo-details-uploader">
                {`Image uploaded by 
                ${
                  photoDetail?.uploader?.first_name
                    ? photoDetail.uploader.first_name
                    : ""
                } 
                ${
                  photoDetail?.uploader?.last_name
                    ? photoDetail.uploader.last_name
                    : ""
                }`}
                (
                <span onClick={handleViewPublicGallery} className="span-link">
                  View Gallery
                </span>
                {", "}
                <span
                  onClick={() =>
                    history.push(`/public/albums/view/${photoDetail.album_id}`)
                  }
                  className="span-link"
                >
                  View Album
                </span>
                )
              </h6>

              <Row>
                <Col lg={7} md={12}>
                  <div className="photo-detail-header-info">
                    <div className="photo-detail-using-count">
                      {photoDetail.download_count ? (
                        <div className="download-count">
                          <span>
                            <MdFileDownload title="Download Count"/>
                          </span>
                          {photoDetail.download_count}
                        </div>
                      ) : null}
                      {photoDetail.view_count ? (
                        <div className="download-count">
                          <span>
                            <MdRemoveRedEye title="View Count"/>
                          </span>
                          {photoDetail.view_count}
                        </div>
                      ) : null}
                      {photoDetail.like_count ? (
                        <div className="download-count">
                          <span>
                            <MdThumbUp title="Like Count"/>
                          </span>
                          {photoDetail.like_count}
                        </div>
                      ) : null}
                    </div>

                    <div className="share_feature">
                      <Button
                        className="share-photo-button"
                        onClick={() => setIsShare(true)}
                      >
                        <MdShare /> Share
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={7} md={12}>
                  {loading ? (
                    <div className="image-loading-display">
                      <Spinner
                        animation="border"
                        className="home-page-loading-spinner"
                      ></Spinner>
                    </div>
                  ) : (
                    <div
                      className="image-display"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        style={{
                          fontSize: "26px",
                          display: "flex",
                          justifyContent: "right",
                          position: "absolute",
                          top: "2px",
                          left: "17px",
                        }}
                      >
                        <MdCreateNewFolder
                          className="addToCollectionsIcon"
                          style={{
                            marginTop: "5px",
                            marginLeft: "5px",
                            color: "#ffff",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleAddToCollectionButton(photoDetail.id)
                          }
                          title="Add to collections"
                        />
                        {/* //isFavourite */}
                        {favourites.includes(photoDetail.id) ? (
                          <MdFavorite
                            title="Remove from favourites"
                            className="addToCollectionsIcon"
                            style={{
                              marginTop: "5px",
                              marginLeft: "5px",
                              color: "#e64a19",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleRemoveFromFavouritesButton(photoDetail.id)
                            }
                          />
                        ) : (
                          <MdFavoriteBorder
                            title="Add to favourites"
                            className="addToCollectionsIcon"
                            style={{
                              marginTop: "5px",
                              marginLeft: "5px",
                              color: "#ffff",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleAddToFavouritesButton(photoDetail.id)
                            }
                          />
                        )}
                      </div>

                      {imgLoading ? (
                        <div className="fill_image_loading">
                          <div className="album-spinner">
                            <Spinner
                              variant="dark"
                              animation="border"
                              role="status"
                            ></Spinner>
                          </div>
                        </div>
                      ) : (
                        <img
                          src={photo}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = BrokenImage;
                          }}
                          className="img-fluid"
                        />
                      )}
                    </div>
                  )}
                  <div className="purchase-image-wrapper">
                    <button
                      type="button"
                      className="button-regular purchase-btn"
                      onClick={() => hanldePurchaseItem(photoDetail.id)}
                    >
                      {photoDetail.free_download
                        ? "Download for free"
                        : "Purchase for Rs. "}
                      {!photoDetail.free_download && (
                        <NumberFormat
                          value={price.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      )}
                    </button>
                    <p className="purchase-license-note">
                      Includes our{" "}
                      <span className="span-link">standard license</span> for
                      royalty free use.
                    </p>
                  </div>

                  <div className="pd-keywords">
                    <h6 style={{ fontWeight: "bold" }}>Keywords</h6>
                    <div className="pd-keywords-wrapper">
                      {photoDetail.tags &&
                        photoDetail.tags.map((el: any, i: any) => (
                          <div className="pd-keyword-item" key={i}>
                            {el}
                          </div>
                        ))}
                    </div>
                  </div>
                </Col>
                <Col lg={5} md={12}>
                  {photoDetail.description && (
                    <div className="pd-description-wrapper">
                      <h6
                        style={{
                          fontWeight: "bold",
                          marginBottom: "0px",
                          fontSize: "15px",
                        }}
                      >
                        Description
                      </h6>
                      <p>{photoDetail.description}</p>
                    </div>
                  )}

                  <div className="pd-category-wrapper">
                    <h6
                      style={{
                        fontWeight: "bold",
                        marginBottom: "0px",
                        fontSize: "15px",
                      }}
                    >
                      Album
                    </h6>
                    <p>{albumName}</p>
                  </div>

                  <div className="pd-metadata-wrapper">
                    {(photoDetail.metadata &&
                      photoDetail.metadata.camera_make) ||
                    (photoDetail.metadata &&
                      photoDetail.metadata.camera_model) ? (
                      <div className="pd-metadata-header">
                        <div className="pd-metadata-header-icon">
                          <CameraIcon />
                        </div>

                        <div className="pd-metadata-header-info">
                          <div className="pd-metadata-header-info-title">
                            {photoDetail.metadata &&
                              photoDetail.metadata.camera_make &&
                              photoDetail.metadata.camera_make}{" "}
                            $
                            {photoDetail.metadata &&
                              photoDetail.metadata.camera_model &&
                              photoDetail.metadata.camera_model}
                          </div>
                          {(photoDetail.metadata &&
                            photoDetail.metadata.focal_length_35) ||
                          (photoDetail.metadata && photoDetail.metadata.fnum) ||
                          (photoDetail.metadata && photoDetail.metadata.iso) ||
                          (photoDetail.metadata &&
                            photoDetail.metadata.exposure_time) ? (
                            <div className="pd-metadata-header-info-subinfo">
                              {getFnum(
                                photoDetail.metadata &&
                                  photoDetail.metadata.fnum &&
                                  photoDetail.metadata.fnum
                              )}
                              {getISO(
                                photoDetail.metadata &&
                                  photoDetail.metadata.iso &&
                                  photoDetail.metadata.iso
                              )}
                              {calcExposureTime(
                                photoDetail.metadata &&
                                  photoDetail.metadata.exposure_time &&
                                  photoDetail.metadata.exposure_time
                              )}
                              {getFocalLen(
                                photoDetail.metadata &&
                                  photoDetail.metadata.focal_length_35 &&
                                  photoDetail.metadata.focal_length_35
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    <div className="pd-metadata-full-details">
                      <table style={{ fontSize: "16px" }}>
                        <tr style={{ borderBottom: "none" }}>
                          <td>Resolution</td>
                          <td>
                            {photoDetail.metadata && photoDetail.metadata.width}{" "}
                            x{" "}
                            {photoDetail.metadata &&
                              photoDetail.metadata.height}
                          </td>
                        </tr>
                        <tr style={{ borderBottom: "none" }}>
                          <td>Image Type</td>
                          <td>JPEG</td>
                        </tr>
                        <tr style={{ borderBottom: "none" }}>
                          <td>Created</td>
                          <td>
                            {moment
                              .utc(photoDetail.created_at)
                              .local()
                              .format("MMM DD, YYYY")}
                          </td>
                        </tr>
                        <tr style={{ borderBottom: "none" }}>
                          <td>Views</td>
                          <td>{photoDetail.view_count}</td>
                        </tr>
                        <tr style={{ borderBottom: "none" }}>
                          <td>Downloads</td>
                          <td>{photoDetail.download_count}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
      {addToCollectionPhotoId && (
        <AddToCollectionModal
          //handleGetCollections={handleGetCollections}
          show={addFavouriteModalShow}
          onHide={() => setAddFavouriteModalShow(false)}
          photoId={addToCollectionPhotoId}
        />
      )}
      <LoginModal
        show={modalShowLogin}
        onHide={() => {
          setModalShowLogin(false);
        }}
      ></LoginModal>
      <ShareModal
        show={isShare}
        onHide={() => setIsShare(false)}
        title="Share"
        size="md"
        photoData={photoDetail}
      />

      <Footer simpleFooter={true} />
    </div>
  );
};

export default PhotoDetail;
