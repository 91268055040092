import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../../images/logo-colored-white.png";

const Footer = (props: any) => {
  return (
    <footer className="footer-wrapper">
      <div className="footer-overlay"></div>
      <Container>
        <Row>
          {props.simpleFooter ? null : (
            <>
              <Col md={12} lg={9}>
                <div className="footer-logo">
                  <img src={Logo} alt="Pix Logo" />
                </div>
                <div className="footer-desc">
                  The only marketplace for Sri Lankan stock images that connects photography enthusiasts, social media campaign managers, content writers or anyone with a need for Sri Lankan stock
                  images with our local professional photographers.
                </div>
              </Col>
              {/* <Col xs={12} sm={6} md={4} lg={{ span: 2, offset: 1 }} className="footer-quicklinks">
                <h3 className="footer-title">Help</h3>
                <div className="footer-link-list">
                  <ul>
                    <li>
                      <a href="">Support</a>
                    </li>
                    <li>
                      <a href="">FaQ</a>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col md={12} lg={3} className="footer-quicklinks">
                <h3 className="footer-title">Company</h3>
                <div className="footer-link-list">
                  <ul>
                    {/* <li>
                      <a href="">About</a>
                    </li>
                    <li>
                      <a href="">Contact Us</a>
                    </li>
                    <li>
                      <a href="">License</a>
                    </li> */}
                    <li>
                      <Link to="/terms-conditions">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </>
          )}
          <Col md={12} className={props.simpleFooter ? "footer-copyright-simple" : "footer-copyright"}>
            Copyright © {new Date().getFullYear()}, <a href="www.pix.lk">www.pix.lk</a>
          </Col>
        </Row>
      </Container>
      {/* <a href="http://www.bestweb.lk/vote/site/www_pix_lk/" target="_blank">
        <img
          src="https://www.bw2021.lk/vote4us.png"
          alt="bestweb logo"
          className="bestweb-logo"
        />
      </a> */}
    </footer>
  );
};

export default Footer;
