import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { VscClose, VscListSelection } from "react-icons/vsc";
import "../../../styles/navbar.css";
import { AuthUser } from "../../context/ContextProvider";
import Logo from "../../../images/logo-colored-white.png";
import LogoWhite from "../../../images/pix_logo-white.png";
import Search from "./Search";
import { Button, Dropdown, DropdownButton, Image, Col } from "react-bootstrap";
import { handleUserLogout } from "../../api/SignInAndSignUpAPI";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../auth/Authenticate";
import userImage from "../../../images/user.svg";
import Avatar from "react-avatar";
import { getUserProfile } from "../../api/ProfileAreaAPIs";

const Navbar = (props: any) => {
  const history = useHistory();

  const { user, setUser } = useContext(AuthUser);
  const [click, setClick] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (click === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [click]);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated() === true) {
      if (localStorage.user !== undefined && user === null) {
        let userData = JSON.parse(localStorage.user);
        setUser(userData);
      } else {
        getUserProfile(
          (response: any) => {
            localStorage.setItem("user", JSON.stringify(response.data));
            setUser(response.data);
          },
          (error: any) => {}
        );
      }
    } else {
      localStorage.removeItem("user");
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      if (localStorage.user) {
        let userData = JSON.parse(localStorage.user);
        setUser(userData);
      }
    } else {
      localStorage.removeItem("user");
    }
  }, [props.changedUser]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const navbarView = () => {
    let classes: string = "";
    let classesDropShadow: string = "";

    if (scrollTop < 1 && width > 960) {
      classes = "navbar";
    } else {
      classes = "navbar navbar-bg";
    }

    if (props.navDropshadow) {
      classesDropShadow = " nav-dropshadow";
    } else {
      classesDropShadow = "";
    }
    return classes + classesDropShadow;
  };

  const handleLogout = () => {
    handleUserLogout(
      (data: any) => {
        setUser(null);
        document.cookie =
          "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        history.push("/");
        window.location.reload();
      },
      (error: any) => {
        toast.error(`Something went wrong. Please try again. ${error}`);
      }
    );
    setClick(false);
  };

  const handleUploadButton = () => {
    if (isAuthenticated()) {
      history.push("/profile/albums");
    } else {
      history.push("/signup");
    }
  };

  return (
    <nav className={navbarView()}>
      <div className="nav-logo-sec">
        <Link to="/">
          <div className="pix-logo">
            <img src={Logo} alt="Pix Logo" />
          </div>
        </Link>

        {/* <ul className={click ? "nav-menu active" : "nav-menu"} style={{justifyContent:"left", width:"auto"}}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu} style={{fontWeight:"bold"}}>
              Home
            </Link>
          </li>
        </ul> */}
      </div>
      {props.hideSearch ? null : props.alwaysShow || scrollTop > 350 ? (
        <Search navSearch={true} />
      ) : null}
      <div className="menu-icon" onClick={handleClick}>
        <span>{click ? <VscClose /> : <VscListSelection />}</span>
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li className="nav-item">
          <Link
            to="/"
            className="nav-links home-item"
            onClick={closeMobileMenu}
          >
            Home
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>
            License
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>
            About
          </Link>
        </li> */}

        {props.history.location.pathname === "/signup" ||
        props.history.location.pathname === "/profile/albums" ? null : (
          <li className="nav-item">
            <Button
              className="pix-main-button nav-button"
              onClick={handleUploadButton}
            >
              Upload
            </Button>
          </li>
        )}

        {isAuthenticated() ? (
          <DropdownButton
            id="user-profile-dropdown"
            title={
              <Image
                src={
                  user && user.profile_photo
                    ? `${user.profile_photo}?fullsize=true`
                    : userImage
                }
                roundedCircle
                style={{
                  height: "35px",
                  width: "35px",
                  marginRight: "10px",
                  objectFit: "cover",
                }}
              />
            }
            className="profile-drop-down-nav-link nav-links"
            style={{ padding: "0.1rem 0.5rem" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "240px",
                marginTop: "10px",
              }}
            >
              <Avatar
                name={
                  (user && user.first_name ? user.first_name : " ") +
                  " " +
                  (user && user.last_name ? user.last_name : "")
                }
                round={true}
                size="40"
                textSizeRatio={1.6}
                color="#e64a19"
              />
              <div style={{ maxWidth: "192px" }}>
                <h5 style={{ marginLeft: "15px" }}>
                  {user && user.first_name ? user.first_name : "Account"}{" "}
                  {user && user.last_name ? user.last_name : " "}
                </h5>
                <p
                  style={{
                    marginLeft: "15px",
                    color: "#736d67",
                    marginBottom: "12px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {user && user.email ? user.email : " "}
                </p>
              </div>
            </div>
            <hr style={{ marginTop: "0", marginBottom: "10px" }} />
            <>
              {props.history.location.pathname === "/profile/albums" ? null : (
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => history.push("/profile/albums")}
                  className="photographer-profile-option"
                >
                  Photographer Profile
                </Dropdown.Item>
              )}
              {props.history.location.pathname === "/profile/albums" ? null : (
                <hr style={{ marginTop: "12px", marginBottom: "2px" }} />
              )}
              <Dropdown.Item
                eventKey="1"
                onClick={() => history.push("/profile/collections")}
              >
                Collections
              </Dropdown.Item>
              <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
              <Dropdown.Item
                eventKey="1"
                onClick={() => history.push("/profile/favourites")}
              >
                Favourites
              </Dropdown.Item>
              <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
              <Dropdown.Item eventKey="1" onClick={handleLogout}>
                Logout
              </Dropdown.Item>
            </>
          </DropdownButton>
        ) : props.history.location.pathname === "/login" ? null : (
          <li className="nav-item">
            <Link to="/login" className="nav-links" onClick={closeMobileMenu}>
              Login
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default withRouter(Navbar);
