// @ts-nocheck
import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";
import qs from "qs";

export const addImageToAlbum = (
  imageFile: any,
  options: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post("https://api.mocki.io/v1/e2bed097", imageFile, options)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getUserProfile = (onSuccess: any, onError: any) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/profile`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const updateProfilePhoto = (
  userPhoto: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/profile/update_photos`,
      qs.stringify(userPhoto),
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const deleteProfilePhoto = (
  onSuccess: any,
  onError: any
) => {
  axios
    .delete(
      `${process.env.REACT_APP_API_URL}/v1/profile/profile_photo`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const deleteCoverPhoto = (
  onSuccess: any,
  onError: any
) => {
  axios
    .delete(
      `${process.env.REACT_APP_API_URL}/v1/profile/cover_photo`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const updateUserName = (
  username: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/profile/update_username`,
      qs.stringify(username),
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const requestVerification = (
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/profile/request_verification`,
      {},
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};