import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import Navbar from "../common/Navbar";
import ProfileHeader from "../common/ProfileHeader";
import { Container, Tabs, Tab, Spinner, Button } from "react-bootstrap";
import "../../../styles/profile.css";
import ProfileAreaAlbums from "./albums/ProfileAreaAlbums";
import Footer from "../common/Footer";
import Statistics from "./Statistics";
import Payouts from "./Payouts";
import { AuthUser } from "../../context/ContextProvider";
import {
  getUserProfile,
  requestVerification
} from "../../api/ProfileAreaAPIs";
import {
  becomeCreator,
} from "../../api/GenericAPI";
import ConfirmDialog from "./BecomeCreatorDialog";
import axios from "axios";
import { getCSRFToken } from "../../auth/Authenticate";
import ChangeUserRoleModal from "../common/ChangeUserRoleModal";

const ProfileArea = () => {
  const [albumCount, setAlbumCount] = useState("0");
  const { user, setUser } = useContext(AuthUser);
  const [changedUser, setChangedUser] = useState(false);
  const [role, setRole] = useState("");
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editUserModal, setEditUserModal] = useState(false);
  const [verifiedAccount, setVerifiedAccount] = useState(true);
  const [photoCount, setPhotoCount] = useState(0);
  const [verifyRequested, setVerifyRequested] = useState(false);
  const [editUserRoleModalShow, setEditUserRoleModalShow] = useState(false);

  useEffect(() => {
    if (localStorage.user !== undefined && user === null) {
      let userData = JSON.parse(localStorage.user);
      setUser(userData);
    }
  }, [user]);

  const handleGetAlbumCount = (count: string) => {
    setAlbumCount(count);
  };

  const chnageUserStatus = () => {
    setChangedUser(!changedUser);
  };

  const handleConfirmationModal = () => {
    setEditUserModal(false);
    if (user.username) {
      setConfirmDialogShow(true);
    } else {
      // toast.error("Add username first !");
      // setEditUserModal(true);
      setEditUserRoleModalShow(true);
    }
  }

  const handleDoneRequest = () => {
    setLoading(true);
    setRole("CREATOR");
    setConfirmDialogShow(false);
    getUserProfile(
      (response: any) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        setLoading(false);
      },
      (error: any) => {
        setLoading(false);
      }
    );
  }

  const handleConfirmation = (yesOrNo: any) => {
    if (yesOrNo == "yes") {
      setLoading(true);
      becomeCreator(
        (response: any) => {
          toast.success("Successfully converted to a photographer profile");
          setRole("CREATOR");
          setConfirmDialogShow(false);
          getUserProfile(
            (response: any) => {
              localStorage.setItem("user", JSON.stringify(response.data));
              setLoading(false);
            },
            (error: any) => {
              setLoading(false);
            }
          );
        },
        (error: any) => {
          toast.error(`Something went wrong. Please try again. ${error}`);
          setConfirmDialogShow(false);
        }
      );
    } else {
      setConfirmDialogShow(false);
    }
  };

  const handleGetVerified = () => {
    requestVerification(
      (response: any) => {
        setVerifyRequested(true);
        toast.success("Request sent to verify your account");
      },
      (error: any) => {
        if (error.response.data.code == 1109 || error.response.data.code == 1110) {
          toast.error(`${error.response.data.description}`);
        } else {
          toast.error(`Something went wrong. Please try again`);
        }
      }
    )
  }

  useEffect(() => {
    getUserProfile(
      (response: any) => {
        setRole(response.data.role);
        setVerifiedAccount(response.data.verified);
        setVerifyRequested(response.data.verify_requested);
        setUser(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
        setLoading(false);
      },
      (error: any) => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (role == "CREATOR") {
      handleGetPrivateAlbums();      
    }
  }, [role])

  const handleGetPrivateAlbums = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/albums`,
      header
    ).then((data) => {
      let albumData = data.data;
      let activeAlbumData = albumData.filter(
        (el: { deleted: boolean; }) => el.deleted === false
      );
      if (activeAlbumData && activeAlbumData[0] ) {
        getAlbumData(activeAlbumData[0].id);
      }
    })
    .catch((error) => {
    });
  };

  const getAlbumData = async (albumId : any) => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/albums/${albumId}`,
      header
    );

    const albumData = await getAlbum.data;
    let unpublishedLength = albumData.photos.filter(
      (el : any) => el.status !== "UNPUBLISHED"
    ).length;
    setPhotoCount(unpublishedLength);
  };

  return (
    <div>
      {role === "CREATOR" ?
        <div className="profile-area-main">
          <Navbar alwaysShow={true} hideSearch={true} navDropshadow={true} changedUser={changedUser} />
          <ProfileHeader changeUser={chnageUserStatus}/>
          <div className="profile-area-wrapper">
            <Container>
              { !verifiedAccount &&
                <div style={{background:"#fce9d7", borderRadius:"8px", fontSize:"14px", padding:"10px", width:"100%", marginTop:"20px", marginBottom:"20px", textAlign:"left"}}>
                  <span style={{fontSize:"22px", color:"#4F4F4F"}}>Unverified Creator Account</span>
                  {verifyRequested ? (<p>Your photographer account verification request is pending.</p>) : <p>Your photographer account is not yet verified. Please upload 5 free photos and get verified to start monetizing your content.</p>}
                  <div style={{display:"flex", justifyContent:"left"}}>
                    {loading ?
                      <div>
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                      :
                      <Button
                        className="pix-main-button nav-button"
                        onClick={handleGetVerified}
                        style={{width:"max-content", borderRadius:"8px", padding:"5px"}}
                        disabled={(photoCount < 4 || verifyRequested) ? true : false}
                      >
                        Get Verified
                      </Button>
                    }
                  </div>
                </div>}
              <Tabs defaultActiveKey="albums" id="user-area-settings">
                <Tab eventKey="albums" title={`Albums (${albumCount})`}>
                  <ProfileAreaAlbums handleGetAlbumCount={handleGetAlbumCount} albumCount={albumCount} />
                </Tab>
                {/* <Tab eventKey="statistics" title="Statistics">
                  <Statistics />
                </Tab>
                <Tab eventKey="payouts" title="Payouts">
                  <Payouts />
                </Tab> */}
              </Tabs>
            </Container>
          </div>
        </div>
        :
        <div className="profile-area-main">
          <Navbar alwaysShow={true} hideSearch={true} navDropshadow={true} changedUser={changedUser} />
          <ProfileHeader changeUser={chnageUserStatus} editUserModal={editUserModal}/>
          <div className="profile-area-wrapper">
            <Container>
              <div style={{display:"flex", justifyContent:"center"}}>
                {loading ?
                  <div>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                  :
                  <Button
                    className="pix-main-button nav-button"
                    onClick={handleConfirmationModal}
                    style={{width:"max-content"}}
                  >
                    Become a Content Creator
                  </Button>
                }
              </div>
            </Container>
            <ConfirmDialog
              title="Become a Content Creator"
              message="Do you want to become a content creator for Pi.lk?"
              confirmBtnName="Confirm"
              show={confirmDialogShow}
              onHide={() => setConfirmDialogShow(false)}
              callback={handleConfirmation}
            />
            <ChangeUserRoleModal
              show={editUserRoleModalShow}
              onHide={() => setEditUserRoleModalShow(false)}
              changedUser={chnageUserStatus}
              handleDoneRequest={handleDoneRequest}
            />
          </div>
        </div>
      }
      <Footer simpleFooter={true} />
    </div>
  );
};

export default ProfileArea;
