// @ts-nocheck
import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  ProgressBar,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  uploadPhotoToAlbum,
  submitPhotosForReview,
} from "../../../api/PrivatePhotosAPI";
import { getUserProfile } from "../../../api/ProfileAreaAPIs";
// import { getAllPhotoCategories } from "../../../api/PublicPhotosAPI";
import { genericFileUpload } from "../../../api/GenericAPI";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { MdInfoOutline, MdHighlightOff } from "react-icons/md";
import { SearchTagsAPI } from "../../../api/SearchAPI";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  // border: "1px solid #eaeaea",
  marginBottom: 15,
  // width: "100%",
  width: "auto",
  height: "auto",
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  height: "370px"
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "contain",
  background: "#e0e0e0"
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  minHeight: "370px",
  justifyContent: "center",
  marginBottom: 15,
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const PhotoUploadPopup = (props: any) => {
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [errors, setErrors] = useState({
    categoryError: false,
    categoryErrorMsg: "",
  });
  const [searchTagsLoading, setSearchTagsLoading] = useState(false);
  const [freeStatus, setFreeStatus] = useState(true);
  const [disabledStatus, setDisabledStatus] = useState(false);

  useEffect(() => {
    getUserProfile(
      (response: any) => {
        setDisabledStatus(!response.data.verified);
        localStorage.setItem("user", JSON.stringify(response.data));
      },
      (error: any) => {
      }
      );
  }, [])

  // useEffect(() => {
  //   getAllPhotoCategories(
  //     (response: any) => {
  //       setCategories(response.data);
  //     },
  //     (error: any) => {}
  //   );
  // }, []);

  const resetValues = () => {
    setFiles([]);
    setTitle("");
    setUploadPercentage(0);
    setTags([]);
    setCategory("");
    setDescription("");
    setErrorStatus("");
    setErrors({
      categoryError: false,
      categoryErrorMsg: "",
    });
  };

  useEffect(() => {
    resetValues();
  }, [props.show]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const Previews = () => {
    const { getRootProps, getInputProps, open } = useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );

        const selectedImage = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        setLoading(true);
        let imageFile = new FormData();
        imageFile.append("file", selectedImage[0]);

        // API
        genericFileUpload(
          imageFile,
          (uploadProgress) => {
            setUploadPercentage(uploadProgress);
          },
          (data: any) => {
            setUploadPercentage(100);
            setLoading(false);
            setUploadedFileName(data.data.name);
          },
          (error: any) => {
            setLoading(false);
            console.log(error);
          }
        );
      },
    });

    return (
      <section className="image-upload-container">
        {!files.length > 0 ? (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} multiple={true} />
            <p>Drag and drop your images here</p>
            <span className="image-upload-or">or</span>
            <Button className="pix-main-button btn-normal-width" onClick={open}>
              Select a Photo
            </Button>
          </div>
        ) : null}
      </section>
    );
  };

  const validate = () => {
    // let status = {
    //   categoryError: false,
    //   categoryErrorMsg: "",
    // };

    // if (category === "") {
    //   status.categoryError = true;
    //   status.categoryErrorMsg = "Please select the category";
    // } else {
    //   status.categoryError = false;
    //   status.categoryErrorMsg = "";
    // }

    // if (status.categoryError) {
    //   setErrors(status);
    //   setErrorStatus("");
    //   return false;
    // } else {
    //   setErrors(status);
    //   setErrorStatus("");
    //   return true;
    // }
  };

  const handleUploadPhotoToAlbum = () => {
    setLoading(true);
    const imageInfo = {
      album: props.albumId,
      filename: uploadedFileName,
      // category_id: parseInt(category),
      tags: tags
        .map((tag: any) => {
          if (tag.customOption === true) {
            return tag.label;
          } else {
            return tag;
          }
        })
        .toString(),
      name: title,
      description: description,
      allow_free: freeStatus
    };

    //API
    uploadPhotoToAlbum(
      imageInfo,
      (data: any) => {
        submitPhotosForReview(
          [data.data.id],
          (response: any) => {
            setLoading(false);

            // toast.dark("Image uploaded successfully");
            setLoading(false);
            // window.location.reload();
            props.onHide();
            props.navigateToReviewTab();
          },
          (error: any) => {
            setLoading(false);
            if (error.response && error.response.data) {
              setErrorStatus(
                error.response.data.description
              );
            } else {
              toast.error(`Something went wrong. Please try again. ${error}`);
            }
          }
        );
      },
      (error: any) => {
        setLoading(false);
        if (error.response && error.response.data) {
          setErrorStatus(
            (error.response.data.code == 1205) ? "Image metadata is missing. Please include all Exif tags when uploading images" : error.response.data.description
          );
        } else {
          toast.error(`Something went wrong. Please try again. ${error}`);
        }
      }
    );
  };

  const handleSearchTags = (query) => {
    setSearchTagsLoading(true);
    SearchTagsAPI(
      query,
      (response: any) => {
        setOptions(response.data);
        setSearchTagsLoading(false);
      },
      (error: any) => {}
    );
  };

  const filterBy = () => true;

  const ref = React.createRef();

  const onInputChange = (query) => {
    if (query.length > 1) {
      if ((query.charAt(query.length-1) == ",") || (query.charAt(query.length-1) == " ")) {
        setTags([...tags, query.substring(0, query.length - 1)]);
        ref.current.clear();
      }
    }
  }

  const handleChangeFreeStatus = (e) => {
    setFreeStatus(e);
  }

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="photo-upload-popup"
    >
      <Modal.Header closeButton>
        <h6>Add New Photo to Album "{props.albumName}"</h6>
      </Modal.Header>
      <Modal.Body>
        {errorStatus !== "" ? (
          <Alert variant="danger">
            <MdInfoOutline /> {errorStatus}
          </Alert>
        ) : null}
        <Row>
          <Col lg={6}>
            <div className="new-image-upload-section">
              <Previews />
              <aside style={thumbsContainer}>
                {files.map((file) => (
                  <div style={thumb} key={file.name}>
                    {uploadPercentage === 100 && (
                      <i className="clear-selected-image" onClick={resetValues}>
                        <MdHighlightOff />
                      </i>
                    )}
                    <div style={thumbInner}>
                      <img src={file.preview} style={img} />
                    </div>
                    {uploadPercentage > 0 && (
                      <div className="uploading-image-progressbar">
                        <span>
                          {uploadPercentage < 100
                            ? "Uploading " + uploadPercentage + "%"
                            : "Upload Complete"}
                        </span>
                        <ProgressBar now={uploadPercentage} />
                      </div>
                    )}
                  </div>
                ))}
              </aside>
            </div>
            <div className="image-upload-note">
              <ul style={{ paddingLeft: 15 }}>
                <li>
                  <p>
                    Includes our{" "}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      standard license
                    </a>{" "}
                    for royalty free use.
                  </p>
                </li>
                <li>
                  <p>
                    Make sure your photo is larger than 1000x1000 pixels and
                    meets our guidlines
                  </p>
                </li>
                <li>
                  <p>
                    Add relevant tags to make it easier for users to find your
                    photo
                  </p>
                </li>
              </ul>
            </div>
          </Col>

          <Col lg={6}>
            <Form>
              <Form.Group>
                <Form.Label>Title (Optional)</Form.Label>
                <Form.Control
                  placeholder="Enter your image title"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Tags (Optional)</Form.Label>
                <AsyncTypeahead
                  ref={ref}
                  allowNew
                  multiple={true}
                  filterBy={filterBy}
                  id="select-photo-tags"
                  isLoading={searchTagsLoading}
                  minLength={2}
                  onSearch={handleSearchTags}
                  options={options}
                  onChange={setTags}
                  selected={tags}
                  placeholder="Select tags"
                  onInputChange={onInputChange}
                  newSelectionPrefix={"Add Tag : "}
                  onKeyDown={(e) => {
                    var a = e.key;
                    if (([",", " "].indexOf(a) > -1) && ((e.target.value).length == 0)) {
                      e.preventDefault();
                    }
                    if (["@", "$", "+", "&", "|", "!", "(", ")", "{", "}", "[", "]", "^", "~", "*", "?", ":"].indexOf(a) > -1) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Group>

              {/* <Form.Group>
                <Form.Label>Categories</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(event) => {
                    setCategory(event.target.value);
                    setErrors({ categoryError: false, categoryErrorMsg: "" });
                  }}
                  value={category}
                  required
                  isInvalid={errors.categoryError}
                >
                  <option value="" disabled selected>
                    Select Category
                  </option>
                  {categories &&
                    categories.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.categoryErrorMsg}
                </Form.Control.Feedback>
              </Form.Group> */}

              <Form.Group>
                <Form.Label>Free / Paid</Form.Label>
                <Form.Check 
                  type="checkbox" 
                  label="Free Photo" 
                  checked={freeStatus}
                  disabled={disabledStatus}
                  onChange={(event) => handleChangeFreeStatus(!freeStatus)}
                />
                <Form.Label>(You need to have minimum of 5 free photos in your account always)</Form.Label>
              </Form.Group>

              <Form.Group>
                <Form.Label>Description (Optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <div className="add-to-album-button">
          <Button
            type="button"
            className="pix-main-button upload-img-btn"
            disabled={uploadPercentage !== 100}
            onClick={!isLoading ? handleUploadPhotoToAlbum : () => {}}
          >
            {isLoading ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                ></Spinner>
                Please wait...
              </>
            ) : (
              "Add to Album"
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PhotoUploadPopup;
