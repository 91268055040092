import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";

export const CheckoutSessionAPI = (
  id: any,
  data: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/public/photos/${id}/checkout`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const PreCheckoutFreeSessionAPI = (
  id: any,
  data: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/photos/${id}/checkout`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const CheckoutFreeSessionAPI = (
  id: any,
  data: any,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/public/photos/${id}/checkout_free`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};