import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { SearchTagsAPI } from "../../../api/SearchAPI";
import { MdInfoOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { updatePhotoDetails } from "../../../api/PrivatePhotosAPI";

const UpdatePhotoDetailsModal = (props: any) => {
  const [updatePhoto, setUpdatePhoto] = useState({
    name: "",
    description: "",
    tags: "",
  });

  const [tags, setTags] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [options, setOptions] = useState<any>([]);
  const [searchTagsLoading, setSearchTagsLoading] = useState(false);

  useEffect(() => {
    if (!props.isEditMode) {
      setUpdatePhoto({
        name: "",
        description: "",
        tags: "",
      });
      setLoading(false);
      setTags([]);
      setErrorStatus("");
    } else {
      setUpdatePhoto({
        name: props.photoData.name,
        description: props.photoData.description,
        tags: "",
      });
      setLoading(false);
      setTags(props.photoData.tags);
      setErrorStatus("");
    }
  }, [props.show]);

  const handleUpdatePhotoInfo = () => {
    setLoading(true);
    let newPhotoData = updatePhoto;
    const tempTags = tags
      .map((tag: any) => {
        if (tag.customOption === true) {
          return tag.label;
        } else {
          return tag;
        }
      })
      .toString();
    newPhotoData.tags = tempTags;

    updatePhotoDetails(
      props.photoData.id,
      newPhotoData,
      (response: any) => {
        setLoading(false);
        toast.dark("Image details uploaded successfully");
        props.handleGetPhotoDetails(props.photoData.id);
        setTimeout(() => {
          props.onHide();
        }, 1000);
      },
      (error: any) => {
        setLoading(false);
        if (error.response && error.response.data) {
          setErrorStatus(
            error.response.data.description
          );
        } else {
          toast.error(`Something went wrong. Please try again. ${error}`);
        }
      }
    );
  };

  const handleOnChange = (event: any) => {
    // @ts-ignore
    setUpdatePhoto({
      ...updatePhoto,
      [event.target.name]: event.target.value,
    });
  };

  const handleSearchTags = (query: any) => {
    setSearchTagsLoading(true);
    SearchTagsAPI(
      query,
      (response: any) => {
        setOptions(response.data);
        setSearchTagsLoading(false);
      },
      (error: any) => {
        setSearchTagsLoading(false);
      }
    );
  };

  const filterBy = () => true;

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload-photo-popup"
    >
      <Modal.Header closeButton>Update {props.photoData.name}</Modal.Header>
      <Modal.Body>
        {errorStatus !== "" ? (
          <Alert variant="danger">
            <MdInfoOutline /> {errorStatus}
          </Alert>
        ) : null}

        <Form noValidate>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              placeholder="Photo Name"
              type="text"
              autoComplete="off"
              value={updatePhoto.name}
              onChange={(event) => handleOnChange(event)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide the Title for your album
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description (Optional)</Form.Label>
            <Form.Control
              name="description"
              placeholder="Photo Description"
              type="text"
              autoComplete="off"
              value={updatePhoto.description}
              onChange={(event) => handleOnChange(event)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Tags (Optional)</Form.Label>
            <AsyncTypeahead
              allowNew
              multiple={true}
              filterBy={filterBy}
              id="select-photo-tags"
              isLoading={searchTagsLoading}
              minLength={2}
              onSearch={handleSearchTags}
              options={options}
              onChange={setTags}
              selected={tags}
              placeholder="Select tags"
            />
          </Form.Group>
        </Form>

        <div className="action-buttons">
          <Button
            className="pix-main-button btn-normal-width"
            onClick={!isLoading ? handleUpdatePhotoInfo : () => {}}
          >
            {isLoading ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                ></Spinner>
                Please wait...
              </>
            ) : (
              "Update Photo"
            )}
          </Button>

          <Button variant="secondary" onClick={() => props.onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePhotoDetailsModal;
