import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddEditAlbumModal from "./AddEditAlbumModal";
import AlbumPlaceholder from "../../../../images/album-placeholder.jpg";
import axios from "axios";
import { getCSRFToken } from "../../../auth/Authenticate";
import { getUserProfile } from "../../../api/ProfileAreaAPIs";
import { toast } from "react-toastify";

const ProfileAreaAlbums = (props: any) => {
  const [modalShow, setModalShow] = useState(false);
  const [loadAlbum, setLoadAlbum] = useState(false);
  const [albums, setAlbums] = useState<any>([]);
  const [verifiedAccount, setVerifiedAccount] = useState(false);

  const componentRef = useRef();

  useEffect(() => {
    setLoadAlbum(true);
    handleGetPrivateAlbums();
  }, []);

  useEffect(() => {
    getUserProfile(
      (response: any) => {
        setVerifiedAccount(response.data.verified);
        localStorage.setItem("user", JSON.stringify(response.data));
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, []);

  const useContainerDimensions = (myRef: any) => {
    const getDimensions = () => ({
      width: myRef.current ? myRef.current.offsetWidth : 0,
      height: myRef.current ? myRef.current.offsetHeight : 0,
    });

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions());
      };

      if (myRef.current) {
        setDimensions(getDimensions());
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [myRef]);

    return dimensions;
  };

  const { width, height } = useContainerDimensions(componentRef);

  const handleGetPrivateAlbums = async () => {
    let header = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    };

    let headerPhoto = {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
      responseType: "blob",
    };

    let getAlbum = await axios.get(`${process.env.REACT_APP_API_URL}/v1/albums`, header);
    let albumData = await getAlbum.data;
    let activeAlbumData = albumData.filter((el: { deleted: boolean }) => el.deleted === false);
    props.handleGetAlbumCount(activeAlbumData.length);

    let tempAlbums = [];
    if (albumData.length > 0) {
      for (const album of albumData) {
        if (!album.deleted) {
          let tempAlbum = album;
          if (album.thumbnail) {
            let getPhotosThumb = await axios.get(
              `${process.env.REACT_APP_API_URL}/v1/photos/${album.thumbnail}/image?fullsize=false`,
              //@ts-ignore
              headerPhoto
            );
            tempAlbum["thumbnail"] = URL.createObjectURL(getPhotosThumb.data);
          }
          tempAlbums.push(tempAlbum);
        }
      }
      setAlbums(tempAlbums);
      setTimeout(() => {
        setLoadAlbum(false);
      }, 500);
    }
  };

  const OpenAddAlmumModal = (yesOrNo: any) => {
    setModalShow(yesOrNo);
  };

  const albumThumbnail = (album: any) => {
    if (album.thumbnail_url) {
      return album.thumbnail_url;
    } else if (album.thumbnail) {
      return album.thumbnail;
    }
    return AlbumPlaceholder;
  };

  return (
    <div>
      {!loadAlbum ? (
        <div className="pa-all-albums-wrapper">
          <div
            className="pa-add-new-album-block"
            onClick={() => OpenAddAlmumModal(true)}
            style={
              width === 0
                ? //@ts-ignore
                  { height: componentRef?.current?.offsetWidth }
                : { height: width }
            }
          >
            + Add New Album
          </div>

          {albums.length > 0
            ? albums.map((album: any) => (
                <Link
                  to={`/profile/album/view/${album.id}`}
                  key={album.id}
                  className={"pa-available-albums-block"}
                  //@ts-ignore
                  ref={componentRef}
                  style={
                    width === 0
                      ? //@ts-ignore
                        { height: componentRef?.current?.offsetWidth }
                      : { height: width }
                  }
                >
                  <div className="pa-available-albums-block-inner">
                    {album.deleted === true && !loadAlbum ? <span className="deleted-album-text">Album Deleted</span> : null}
                    <img src={albumThumbnail(album)} alt="Pix album thumbnail" />
                    {loadAlbum && (
                      <div className="album-spinner">
                        <Spinner variant="dark" animation="border" role="status"></Spinner>
                      </div>
                    )}
                    <span className="album-name-display">{album.name}</span>
                  </div>
                </Link>
              ))
            : null}
          <AddEditAlbumModal handleGetPrivateAlbums={handleGetPrivateAlbums} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      ) : (
        <div className="pa-all-albums-wrapper">
          <div
            className="pa-add-new-album-block"
            onClick={() => OpenAddAlmumModal(true)}
            style={
              width === 0
                ? //@ts-ignore
                  { height: componentRef?.current?.offsetWidth }
                : { height: width }
            }
          >
            + Add New Album
          </div>
          {props.albumCount > 0
            ? Array.apply(null, Array(props.albumCount)).map((album: any) => (
                <div
                  className={"pa-available-albums-block"}
                  //@ts-ignore
                  ref={componentRef}
                  style={
                    width === 0
                      ? //@ts-ignore
                        { height: componentRef?.current?.offsetWidth }
                      : { height: width }
                  }
                >
                  <div className="pa-available-albums-block-inner">
                    <div className="album-spinner">
                      <Spinner variant="dark" animation="border" role="status"></Spinner>
                    </div>
                  </div>
                </div>
              ))
            : null}

          <AddEditAlbumModal handleGetPrivateAlbums={handleGetPrivateAlbums} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      )}
    </div>
  );
};

export default ProfileAreaAlbums;
