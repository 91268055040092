import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import RequestPasswordReset from "./RequestPasswordReset";
import RequestSuccess from "./RequestSuccess";

const ForgotPasswordModal = (props: any) => {
  const [requestScreen, setReqestScreen] = useState<boolean>(true);
  const [sentEmail, setSentEmail] = useState<string>("");

  const handleRequestSuccessful = (email: string) => {
    setSentEmail(email);
    setReqestScreen(false);
  }

  useEffect(() => {
    setSentEmail("");
    setReqestScreen(true);
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="forgot-password-popup"
    >
      <Modal.Header closeButton>
        <h6>&nbsp;</h6>
      </Modal.Header>
      <Modal.Body>
        {requestScreen ? (
          <RequestPasswordReset handleRequestSuccessful={handleRequestSuccessful}/>
        ) : (
          <RequestSuccess retryEmail={sentEmail}/>
        )}

        <div className="back-to-page" onClick={() => props.onHide()}>
          Back To Login
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;
