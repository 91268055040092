import React, { useContext, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";

const TermsAndConditions = () => {
  return (
    <div className="signup-page-wrapper">
      <Navbar alwaysShow={true} innerPage={true} hideSearch={true} navDropshadow={true} />

      <div className="terms-div">
        <Container>
          <div>
            <div className="terms-heading">Terms & Conditions</div>
            <div className="terms-sub-heading">Last updated : 04 April 2022</div>
            <Row xs={12} sm={10} md={8} className="cus-container" style={{ background: "white" }}>
              <div className="terms-section">
                <div>
                  <ol>
                    <li>
                      <h2>AGREEMENT</h2>
                      <p>
                        These Terms and Conditions include Our Privacy Policy. They may be amended from time to time. They will govern your <b>[“You”]</b> access and use of the website Pix.lk{" "}
                        <b>[“Site”/ “Pix.lk]</b> and its related services. By accessing and using Pix.lk and its related services You agree to be legally bound by these Terms and Conditions.
                      </p>
                    </li>

                    <li>
                      <h2>SERVICES</h2>
                      <ol className="alpha">
                        <li>For Content Creators: We provide a space for Sri Lankan content creators/photographers to upload and sell the rights to use their stock images with a Sri Lank touch.</li>
                        <li>For Content Buyers: We provide the best quality, royalty-free stock images with a Sri Lankan touch that could be used for any application.</li>
                      </ol>
                    </li>

                    <li>
                      <h2>ABOUT US</h2>
                      <p>
                        The website Pix.lk is owned by Serendib Systems Pty Ltd which together with its affiliate partner, Serendib Systems Lanka (Pvt) Ltd, <b>[“We”, “Us”, or “Our”]</b> and third-party
                        licensors provide the Services.
                      </p>
                      <p>
                        Serendib Systems Pty Ltd is a company duly incorporated in Australia (ACN 637232038) and having its registered office at 2/15, McEwan Road, Heidelberg Heights, VIC3081,
                        Australia. Serendib Systems Lanka (Pvt) Ltd is a company incorporated in Sri Lanka (PV129221) and having its registered office at No. 1005, Pannipitiya Road, Battaramulla, Sri
                        Lanka.
                      </p>
                    </li>

                    <li>
                      <h2>ELIGIBILITY CRITERIA</h2>
                      <p>The legal age for monetary transactions and licensing agreements of Sri Lanka will be in effect as the Eligibility Criteria.</p>
                    </li>

                    <li>
                      <h2>CONDITIONS GOVERNING USE OF THE SITE</h2>
                      <ol className="alpha">
                        <li>You are permitted to access and use Pix.lk only for the authorized purposes set out in Clause 2.</li>
                        <li>
                          You are solely responsible for ensuring that the Content provided and uploaded by You for inclusion on the Site complies with all applicable laws and regulations and that
                          they do not violate any contractual rights with a third party or any privacy or data protection rights, as applicable.
                        </li>
                        <li>You will promptly update and correct any inaccuracies in the Content uploaded or provided by You and such changes will be verified and changed by Pix.lk.</li>
                      </ol>
                    </li>

                    <li>
                      <h2>REPRESENTATIONS & WARRANTIES</h2>
                      <p>By accessing and using Pix.lk, You represent and warrant that:</p>
                      <ol className="alpha">
                        <li>You, and any third party on whose behalf You are using the Site, satisfy the eligibility criteria in item 2 above;</li>
                        <li>
                          You are not prohibited by applicable laws and regulations and or by any contractual third party rights to consent and authorize Us to collect, process, use, share and or
                          disclose personal information and data.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h2>CONFIDENTIALITY</h2>
                      <p>
                        Without your prior consent, Your personal information and data, will be kept confidential except to the extent provided for in these Terms and Conditions and or if an
                        obligation arises to make disclosure:
                      </p>
                      <ol className="alpha">
                        <li>to comply with the law or any regulations; and or</li>
                        <li>to protect and enforce our rights or the rights of others who use Pix.lk </li>
                      </ol>
                    </li>

                    <li>
                      <h2>PRIVACY POLICY</h2>
                      <p>
                        The Privacy Policy explains how personal information and data are collected, used, shared, disclosed or otherwise processed. The Site uses cookies and similar technologies.
                      </p>
                      <p>
                        By using Pix.lk You consent to the collection, processing, using, sharing, and or disclosing of personal information and data provided by You in line with the Privacy Policy.
                        Please read the Privacy Policy for details.
                      </p>
                    </li>

                    <li>
                      <h2>INTELLECTUAL PROPERTY</h2>
                      <p>
                        Pix.lk, and its databases, text, photographs, logos, trademarks and all other intellectual property created and or used in Pix.lk, including without limitation all software,
                        design work, layout, appearance, graphics etc, are owned by Us or are licensed to Us and are protected by the Intellectual Property laws of Australia. No material on Pix.lk may
                        be copied, downloaded, reproduced, republished, stored, photographed, transmitted, installed, posted, or distributed without Our written permission.
                      </p>
                    </li>

                    <li>
                      <h2>DISCLAIMER</h2>
                      <p>
                        We assume no responsibility for your use of Pix.lk. You agree that We shall not in any way be held liable to You for any loss, damage, or injury resulting from or arising out
                        of or which is in any way related to:
                      </p>

                      <ol className="alpha">
                        <li>Errors on Pix.lk or the Content including, but not limited to technical and typographical errors;</li>
                        <li>Inability to access, or use, or the unavailability of Pix.lk; </li>
                        <li>Your use of Pix.lk or the content; or </li>
                        <li>Your use of any software in connection with Pix.lk</li>
                      </ol>
                    </li>

                    <li>
                      <h2>INDEMNITY</h2>
                      <p>
                        By accessing and using Pix.lk You agree to indemnify and hold harmless Serendib Systems Pty Ltd, its officers, directors, employees and agents from and against all claims or
                        actions by third parties and any losses, expenses, damages and costs including attorneys fees resulting from any violation by You of these Terms and Conditions including
                        negligent or wrongful conduct by You in accessing and using Pix.lk.
                      </p>
                    </li>

                    <li>
                      <h2>TERMINATION / ITS CONSEQUENCES</h2>
                      <p>
                        Consequences of termination include Our right to forthwith and without notice to you or liability terminate your access to and use of Pix.lk. and delete your Content from the
                        Site.
                      </p>
                    </li>

                    <li>
                      <h2>AMENDMENTS TO THE TERMS AND CONDITIONS</h2>
                      <p>
                        These Terms and Conditions may be amended from time to time at our sole discretion. The amended Terms and Conditions will be effective immediately upon posting on Pix.lk and
                        your continued access and use of the Site after the date of posting shall be deemed to constitute your acceptance of the amended Terms and Conditions.
                      </p>
                    </li>
                    <li>
                      <h2>GOVERNING LAW & JURISDICTION</h2>
                      <p>
                        These Terms and Conditions shall be construed and interpreted in accordance with the laws of Australia. The Courts of Australia, with the District Court of Victoria as the
                        Court of first instance, shall have exclusive jurisdiction in relation to all disputes arising under or in relation to this Agreement.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </div>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default TermsAndConditions;
