import React, { useContext, useEffect, useState } from "react";
import "../../../styles/profile.css";
import userImage from "../../../images/user.svg";
import userBlank from "../../../images/user-blank.jpg";
import { FaCamera } from "react-icons/fa";
import UploadProfileImages from "./UploadProfileImages";
import { AuthUser } from "../../context/ContextProvider";
import { Spinner } from "react-bootstrap";
import {
  getUserProfile,
  deleteProfilePhoto,
  deleteCoverPhoto,
} from "../../api/ProfileAreaAPIs";
import { Dropdown } from "react-bootstrap";
import ConfirmDialog from "./ConfirmDialog";
import { toast } from "react-toastify";
import { MdEdit } from "react-icons/md";
import ChangeUserDetailsModal from "./ChangeUserDetailsModal";

const ProfileHeader = (props: any) => {
  const [modalShow, setModalShow] = useState(false);
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [role, setRole] = useState("");
  const [removePhoto, setRemovePhoto] = useState("");
  const [profileLoading, setProfileLoading] = useState(true);
  const [editUserDetailsModalShow, setEditUserDetailsModalShow] =
    useState(false);

  const { user, setUser } = useContext(AuthUser);

  useEffect(() => {
    if (props.editUserModal) {
      setEditUserDetailsModalShow(true);
    }
  }, [props]);

  useEffect(() => {
    if (user === null) {
      getUser();
    } else {
      setProfileLoading(false);
    }
  }, []);

  const handleChangeProfilePictures = (type: string) => {
    setModalShow(true);
    setRole(type);
  };

  const getUser = () => {
    getUserProfile(
      (response: any) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        let userData = JSON.parse(localStorage.user);

        setUser(userData);
        setProfileLoading(false);
      },
      (error: any) => {
        setProfileLoading(false);
      }
    );
  };

  const handleConfirmation = (yesOrNo: any) => {
    if (removePhoto === "profilePhoto") {
      if (yesOrNo === "yes") {
        deleteProfilePhoto(
          (response: any) => {
            toast.dark("Profile Photo Removed successfully");
            getUser();
          },
          (error: any) => {
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        );
      }
    } else if (removePhoto === "coverPhoto") {
      if (yesOrNo === "yes") {
        deleteCoverPhoto(
          (response: any) => {
            toast.dark("Cover Photo Removed successfully");
            getUser();
          },
          (error: any) => {
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        );
      }
    }
    setConfirmDialogShow(!confirmDialogShow);
  };

  const handleRemovePictures = (photoArea: string) => {
    setRemovePhoto(photoArea);
    handleConfirmation("Yes or No");
  };

  return (
    <div className="profile-header-wrapper">
      <div
        className="profile-header-main"
        style={{
          backgroundImage: `url(${
            user && user.cover_photo
              ? `${user.cover_photo}?fullsize=true`
              : null
          })`,
        }}
      >
        <div className="change-header-picture">
          <Dropdown>
            <Dropdown.Toggle>
              <FaCamera />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => handleChangeProfilePictures("coverPhoto")}
              >
                Change Cover Photo
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!user?.cover_photo}
                className="remove-profile-images"
                onClick={() => handleRemovePictures("coverPhoto")}
              >
                Remove Cover Photo
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="profile-user">
        <div className="profile-user-image-main">
          {profileLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner animation="border"></Spinner>
            </div>
          )}
          <img
            src={
              user && user.profile_photo
                ? `${user.profile_photo}?fullsize=true`
                : profileLoading
                ? userBlank
                : userImage
            }
            alt="pix user image"
            className="profile-user-image"
            style={{ background: "#c8c8c8" }}
          />
          <div className="change-profile-picture">
            <Dropdown>
              <Dropdown.Toggle>
                <FaCamera />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleChangeProfilePictures("profilePhoto")}
                >
                  Change Profile Photo
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={!user?.profile_photo}
                  className="remove-profile-images"
                  onClick={() => handleRemovePictures("profilePhoto")}
                >
                  Remove Profile Photo
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <h5 className="profile-user-name">
          {user &&
            user.last_name &&
            user.first_name + " " + user.last_name + " "}
          {user && !user.last_name && user.first_name + " "}
          <MdEdit
            onClick={() => setEditUserDetailsModalShow(true)}
            style={{ cursor: "pointer" }}
          />
        </h5>
      </div>

      <UploadProfileImages
        popupTitle={
          role === "profilePhoto"
            ? "Add profile photo"
            : role === "coverPhoto"
            ? "Add cover photo"
            : "Add Photo"
        }
        getUser={getUser}
        role={role}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ConfirmDialog
        title="Remove Profile Photo"
        message="Are you sure you want to remove this photo?"
        confirmBtnName="Remove"
        callback={handleConfirmation}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
      />
      <ChangeUserDetailsModal
        show={editUserDetailsModalShow}
        onHide={() => setEditUserDetailsModalShow(false)}
        changedUser={props.changeUser}
      />
    </div>
  );
};

export default ProfileHeader;
