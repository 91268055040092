import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import RequestVerificationResend from "./RequestVerificationResend";

const ResendActivationMailModal = (props: any) => {
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="forgot-password-popup"
    >
      <Modal.Header>
        <h6>&nbsp;</h6>
      </Modal.Header>
      <Modal.Body>
        <RequestVerificationResend close={props.close} emailVal={props.emailVal} />
        <div className="back-to-page" onClick={() => props.close()}>
          Back To Login
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ResendActivationMailModal;
