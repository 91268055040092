import React, { useContext, useEffect, useState } from "react";
//import Navbar from "../common/Navbar";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
// import Footer from "../common/Footer";
import {
  handleCreateAUserSession,
  checkEmailAvailability,
  handleUserSignUp,
} from "../../api/SignInAndSignUpAPI";
import "../../../styles/signup.css";
import { toast } from "react-toastify";
import { UserEmail, AuthUser } from "../../context/ContextProvider";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ResendActivationMailModal from "./ResendActivationMailModal";
// import MesonaryGallery from "../signup/MesonaryGallery";

import { useHistory } from "react-router-dom";
import { MdInfoOutline } from "react-icons/md";
import { setAuthenticate } from "../../auth/Authenticate";
import { getGenericCsrf } from "../../api/GenericAPI";
import { GoogleLogin } from "react-google-login";
// import ReactDOM from "react-dom";
import FacebookLogin from "react-facebook-login";
// import { env } from "process";
import { MdFacebook } from "react-icons/md";
import { Modal } from "react-bootstrap";
// import { type } from "os";

type ModalProps = {
  show: boolean;
  onHide: any;
  nav?: boolean;
};

function LoginModal(props: ModalProps) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [resendModalShow, setResendModalShow] = useState(false);
  const [errors, setErrors] = useState({
    usernameError: false,
    passwordError: false,
    usernameErrorMsg: "",
    passwordErrorMsg: "",
    authErrorMsg: "",
  });
  const { availableEmail } = useContext(UserEmail);

  const [credentials, setCredentials] = useState({
    user_id: "",
    password: "",
    auth_provider: "EMAIL",
  });
  const [errorStatus, setErrorStatus] = useState<string>("");

  useEffect(() => {
    // @ts-ignore
    setCredentials({
      ...credentials,
      user_id: availableEmail,
    });
  }, []);

  const handleOnChange = (event: any) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "user_id") {
      setErrors({
        ...errors,
        usernameError: false,
        usernameErrorMsg: "",
      });
    } else if (event.target.name === "password") {
      setErrors({
        ...errors,
        passwordError: false,
        passwordErrorMsg: "",
      });
    }
  };

  const hideResendModal = (event: any) => {
    setResendModalShow(false);
  };

  const responseGoogle = (response: any) => {
    if (response && response.profileObj) {
      setLoading(true);
      let token = response.tokenId;
      const registerFields = {
        first_name: response.profileObj.givenName,
        email: response.profileObj.email,
        last_name: response.profileObj.familyName,
        auth_token: token,
        auth_provider: "GOOGLE",
      };
      const sessionData = {
        auth_token: token,
        auth_provider: "GOOGLE",
      };
      setLoading(true);
      getGenericCsrf(
        (data: any) => {
          localStorage.setItem("CSRF_token", data.id);
        },
        (error: any) => {
          setLoading(false);
          console.log(error);
        }
      );
      checkEmailAvailability(
        response.profileObj.email,
        (response: any) => {
          if (response.data.value) {
            //signup
            handleUserSignUp(
              registerFields,
              (response: any) => {
                handleCreateAUserSession(
                  sessionData,
                  (response: any) => {
                    setAuthenticate();
                    setLoading(false);
                    if (props.nav) history.push("/");
                  },
                  (error: any) => {
                    setLoading(false);
                    console.log(error);
                  }
                );
              },
              (error: any) => {
                setLoading(false);
                if (error.response && error.response.data) {
                  const errorCode = error.response.data.code;
                  if (errorCode === 1107) {
                    setErrors({
                      ...errors,
                      usernameError: true,
                      usernameErrorMsg: error.response.data.description,
                    });
                  } else if (errorCode) {
                    setErrorStatus(error.response.data.description);
                  }
                } else {
                  toast.error(
                    `Something went wrong. Please try again. ${error}`
                  );
                }
              }
            );
          } else {
            //login
            handleCreateAUserSession(
              sessionData,
              (response: any) => {
                setAuthenticate();
                setLoading(false);
                toast.success("You are successfully logged in");
                props.onHide(true);
                if (props.nav) {
                  history.push("/");
                }
                window.location.reload();
              },
              (error: any) => {
                setLoading(false);
                console.log(error);
              }
            );
          }
        },
        (error: any) => {
          setLoading(false);
          toast.error(
            error.response && error.response.data
              ? error.response.data.description +
                  ` Error: ` +
                  error.response.data.code
              : `Something went wrong. Please try again. ${error}`
          );
        }
      );
    } else {
      toast.error(`Something went wrong. Please try again.`);
    }
  };

  const responseFacebook = (response: any) => {
    if (response && response.accessToken) {
      setLoading(true);
      let token = response.accessToken;
      const registerFields = {
        first_name: response.name.split(" ")[0],
        email: response.email,
        last_name: response.name.split(" ")[response.name.split(" ") - 1],
        auth_token: token,
        auth_provider: "FACEBOOK",
      };
      const sessionData = {
        auth_token: token,
        auth_provider: "FACEBOOK",
      };
      getGenericCsrf(
        (data: any) => {
          localStorage.setItem("CSRF_token", data.id);
        },
        (error: any) => {
          setLoading(false);
          console.log(error);
        }
      );
      checkEmailAvailability(
        response.email,
        (response: any) => {
          if (response.data.value) {
            //signup
            handleUserSignUp(
              registerFields,
              (response: any) => {
                handleCreateAUserSession(
                  sessionData,
                  (response: any) => {
                    setAuthenticate();
                    setLoading(false);

                    if (props.nav) history.push("/");
                  },
                  (error: any) => {
                    setLoading(false);
                    console.log(error);
                  }
                );
              },
              (error: any) => {
                setLoading(false);
                if (error.response && error.response.data) {
                  const errorCode = error.response.data.code;
                  if (errorCode === 1107) {
                    setErrors({
                      ...errors,
                      usernameError: true,
                      usernameErrorMsg: error.response.data.description,
                    });
                  } else if (errorCode) {
                    setErrorStatus(error.response.data.description);
                  }
                } else {
                  toast.error(
                    `Something went wrong. Please try again. ${error}`
                  );
                }
              }
            );
          } else {
            //login
            handleCreateAUserSession(
              sessionData,
              (response: any) => {
                setAuthenticate();
                setLoading(false);
                toast.success("You are successfully logged in");
                props.onHide(true);
                if (props.nav) history.push("/");
                window.location.reload();
              },
              (error: any) => {
                setLoading(false);
                console.log(error);
              }
            );
          }
        },
        (error: any) => {
          // setLoading(false);
          toast.error(
            error.response && error.response.data
              ? error.response.data.description +
                  ` Error: ` +
                  error.response.data.code
              : `Something went wrong. Please try again. ${error}`
          );
        }
      );
    } else {
      toast.error(`Something went wrong. Please try again.`);
    }
  };

  const validate = () => {
    let status = {
      usernameError: false,
      passwordError: false,
      usernameErrorMsg: "",
      passwordErrorMsg: "",
      authErrorMsg: "",
    };

    if (credentials.user_id === "") {
      status.usernameError = true;
      status.usernameErrorMsg = "Please enter your Email or Phone number";
    } else if (
      !credentials.user_id.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      )
    ) {
      status.usernameError = true;
      status.usernameErrorMsg = "Please enter a valid Email";
    } else {
      status.usernameError = false;
      status.usernameErrorMsg = "";
    }

    if (credentials.password === "") {
      status.passwordError = true;
      status.passwordErrorMsg = "Please enter your password";
    } else {
      status.passwordError = false;
      status.passwordErrorMsg = "";
    }

    if (status.usernameError || status.passwordError) {
      setErrors(status);
      setErrorStatus("");
      return false;
    } else {
      setErrors(status);
      setErrorStatus("");
      return true;
    }
  };

  const handleUserLogIn = (e: any) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);

      getGenericCsrf(
        (data: any) => {
          if (
            window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1"
          ) {
            localStorage.setItem("CSRF_token", data.id);
          }

          handleCreateAUserSession(
            credentials,
            (data: any) => {
              setAuthenticate();
              setLoading(false);
              toast.success("You are successfully logged in");
              props.onHide(true);
              if (props.nav) {
                history.push("/");
              }
              window.location.reload();
            },
            (error: any) => {
              setLoading(false);

              if (
                error.response.data.code === 1102 ||
                error.response.data.code === 1103
              ) {
                setErrors({
                  ...errors,
                  authErrorMsg: "Incorrect email or/and password!",
                });
              } else if (error.response.data.code) {
                setErrorStatus(error.response.data.description);
                if (error.response.data.code === 1104) {
                  setResendModalShow(true);
                }
              }
            }
          );
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  };

  return (
    <Modal
      {...props}
      // size="lg"
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container>
        <h4 className="signup-title mt-3 mb-2" style={{ textAlign: "center" }}>
          Login to your account
        </h4>
        <h5
          className="signup-sub-title my-0"
          style={{ textAlign: "center", color: "GrayText" }}
        >
          Start monetizing your passion
        </h5>

        {errorStatus !== "" ? (
          <Alert variant="danger">
            <MdInfoOutline /> {errorStatus}
          </Alert>
        ) : null}
        <Modal.Body className="my-0">
          <div className="signup-form my-0">
            <p style={{ fontSize: 14, color: "#dc3545" }}>
              {errors.authErrorMsg}
            </p>
            <Form noValidate>
              <Form.Group>
                <Form.Control
                  placeholder="Email"
                  required
                  type="text"
                  name="user_id"
                  autoComplete="off"
                  isInvalid={errors.usernameError}
                  value={credentials.user_id}
                  onChange={handleOnChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.usernameErrorMsg}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  placeholder="Password"
                  name="password"
                  required
                  type="password"
                  autoComplete="new-password"
                  isInvalid={errors.passwordError}
                  value={credentials.password}
                  onChange={handleOnChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordErrorMsg}
                </Form.Control.Feedback>
              </Form.Group>

              <div
                className="forgot-password"
                onClick={() => setModalShow(true)}
                // onClick={f2}
              >
                Forgot Password
              </div>

              <Form.Group>
                <Button
                  className="pix-main-button"
                  type="submit"
                  onClick={!loading ? handleUserLogIn : () => {}}
                  disabled={loading ? true : false}
                  style={{ height: "45px" }}
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      variant="light"
                      size="sm"
                    ></Spinner>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Form.Group>
              <Form.Group>
                <GoogleLogin
                  clientId={
                    process.env.REACT_APP_API_URL == "https://api.pix.lk"
                      ? "201324916082-r2dq9pm1pn5snrd381nam535a90ibloq.apps.googleusercontent.com"
                      : "201324916082-gn1r8kr99lol59ej1isg9krgtn62i9st.apps.googleusercontent.com"
                  }
                  buttonText="Login with google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  className="google-login-btn"
                  autoLoad={false}
                />
              </Form.Group>
              <Form.Group>
                <FacebookLogin
                  appId="6648352261901868"
                  autoLoad={false}
                  fields="name,email,picture"
                  // onClick={componentClicked}
                  callback={responseFacebook}
                  cssClass="facebook-login-btn"
                  textButton="Login with facebook"
                  isMobile={false}
                  icon={
                    <MdFacebook
                      style={{
                        marginRight: "10px",
                        marginTop: "-4px",
                        paddingTop: "0px",
                        fontSize: "27px",
                      }}
                    />
                  }
                />
              </Form.Group>
            </Form>
            <div className="user-sign-page-change">
              <span>
                Don't have an Account? <Link to="/signup">Sign up</Link>
              </span>
            </div>

            <div className="pix-agreement">
              <p>
                By signing up with Pix.lk you are agreeing to our{" "}
                <Link to="/terms-conditions">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link to="/privacy-policy">
                  Privacy Policy
                </Link>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Container>
      <ForgotPasswordModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ResendActivationMailModal
        show={resendModalShow}
        close={hideResendModal}
        emailVal={credentials.user_id}
      />
    </Modal>
  );
}

export default LoginModal;
