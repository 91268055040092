import React from "react";
import Search from "../common/Search";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Image01 from '../../../images/home-slider/01.jpg'
import Image02 from '../../../images/home-slider/02.jpg'
import Image03 from '../../../images/home-slider/03.jpg'
import Image04 from '../../../images/home-slider/04.jpg'
import Image05 from '../../../images/home-slider/05.jpg'
import Image06 from '../../../images/home-slider/06.jpg'

const MainComponent = () => {
  return (
    <div className="home-main-wrapper">
      <div className="search-main-wrapper">
        <h1 className="main-wrapper-title">The exclusive platform for buying and selling Sri Lankan stock images</h1>
        <Search />
      </div>

      <Carousel interval={5000} autoPlay infiniteLoop showIndicators={false} showThumbs={false} showArrows={false} dynamicHeight={false} showStatus={false} swipeable={false}>
        <div className="pix-carousel-item">
          <img alt="" src={Image01} />
        </div>
        <div className="pix-carousel-item">
          <img alt="" src={Image02} />
        </div>
        <div className="pix-carousel-item">
          <img alt="" src={Image03} />
        </div>
        <div className="pix-carousel-item">
          <img alt="" src={Image04} />
        </div>
        <div className="pix-carousel-item">
          <img alt="" src={Image05} />
        </div>
        <div className="pix-carousel-item">
          <img alt="" src={Image06} />
        </div>
      </Carousel>
    </div>
  );
};

export default MainComponent;
