import React from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../auth/Authenticate";

const JoinUs = () => {
  const history = useHistory();

  return (
    <Container>
      <div className="about-desc">
        <h2 className="about-desc-title">Become a Creator</h2>
        <p>
          Pix.lk offers the best quality royalty free stock images with a Sri Lankan touch that could be used in any application. From wildlife to traditional events to historical places to current
          events, when you need the perfect stock image for your website or blog, we got you covered!
        </p>
      </div>
      {isAuthenticated() === true ? null : (
        <div className="join-us-wrapper">
          <h2 className="about-desc-title">Join Us</h2>
          <p>
            Why not create a side income from all the photos you have on your hard drive? Pix.lk is the only Sri Lankan stock images website that connects you to a range of users from content writers
            to social media campaign managers!
          </p>
          <button type="button" className="button-regular" onClick={() => history.push("/signup")}>
            Sign Up for Free
          </button>
        </div>
      )}
    </Container>
  );
};

export default JoinUs;
