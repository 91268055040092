import axios from "axios";
import { getCSRFToken } from "../auth/Authenticate";
import qs from "qs";

export const createANewAlbum = (
  albumData: object,
  onSuccess: any,
  onError: any
) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/albums`, qs.stringify(albumData), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getPrivateAlbums = (
  onSuccess: any,
  onError: any
) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/albums`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getPrivateAlbumDetail = (
  albumId: string,
  onSuccess: any,
  onError: any
) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/albums/${albumId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const updateAlbum = (
  albumId: string,
  albumData: object,
  onSuccess: any,
  onError: any
) => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/v1/albums/${albumId}`, qs.stringify(albumData), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const deleteAlbum = (
  albumId: string,
  onSuccess: any,
  onError: any
) => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/v1/albums/${albumId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};