// @ts-nocheck
import React, { useEffect, useState, useMemo } from "react";
import { Button, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { MdHighlightOff } from "react-icons/md";
import { genericFileUpload } from "../../api/GenericAPI";
import { updateProfilePhoto } from "../../api/ProfileAreaAPIs";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 15,
  width: "100%",
  height: "auto",
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  minHeight: "300px",
  justifyContent: "center",
  marginBottom: 15,
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const UploadProfileImages = (props: any) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [uploadedFileName, setUploadedFileName] = useState("");

  useEffect(() => {
    setFiles([]);
  }, [props.show]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const Previews = () => {
    const { getRootProps, getInputProps, open } = useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );

        const selectedImage = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        setLoading(true);
        let imageFile = new FormData();
        imageFile.append("file", selectedImage[0]);

        // API
        genericFileUpload(
          imageFile,
          (uploadProgress) => {
            setUploadPercentage(uploadProgress);
          },
          (data: any) => {
            setUploadPercentage(100);
            setLoading(false);
            setUploadedFileName(data.data.name);
          },
          (error: any) => {
            setLoading(false);
            console.log(error);
          }
        );
      },
    });

    return (
      <section className="image-upload-container">
        {!files.length > 0 ? (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Drag and drop your images here</p>
            <span className="image-upload-or">or</span>
            <Button className="pix-main-button btn-normal-width" onClick={open}>
              Select a Photo
            </Button>
          </div>
        ) : null}
      </section>
    );
  };

  const handleUploadProfilePhoto = () => {
    if (files.length > 0) {
      setLoading(true);
      const profileImageFile = {
        profile_photo: props.role === "profilePhoto" ? uploadedFileName : null,
        cover_photo: props.role === "coverPhoto" ? uploadedFileName : null,
      };
      let filterProfileImageFile = Object.fromEntries(
        Object.entries(profileImageFile).filter(([_, v]) => v != null)
      );

      updateProfilePhoto(
        filterProfileImageFile,
        (response: any) => {
          setLoading(false);
          props.onHide();
          props.getUser();
          toast.dark("Image uploaded successfully");
        },
        (error: any) => {
          setLoading(false);
          if (error.response && error.response.data) {
            setErrorStatus(
              error.response.data.description
            );
          } else {
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        }
      );
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload-photo-popup"
    >
      <Modal.Header closeButton>{props.popupTitle}</Modal.Header>
      <Modal.Body>
        <div className="image-upload-wrapper-dnd">
          <Previews />
          <aside style={thumbsContainer}>
            {files.map((file) => (
              <div style={thumb} key={file.name}>
                {uploadPercentage === 100 && (
                  <i
                    className="clear-selected-image"
                    onClick={() => {
                      setFiles([]);
                      setUploadPercentage(0);
                    }}
                  >
                    <MdHighlightOff />
                  </i>
                )}
                <div style={thumbInner}>
                  <img src={file.preview} style={img} />
                </div>
                {uploadPercentage > 0 && (
                  <div className="uploading-image-progressbar">
                    <span>
                      {uploadPercentage < 100
                        ? "Uploading " + uploadPercentage + "%"
                        : "Upload Complete"}
                    </span>
                    <ProgressBar now={uploadPercentage} />
                  </div>
                )}
              </div>
            ))}
          </aside>
        </div>

        <div className="action-buttons">
          {files.length > 0 ? (
            <Button
              className="pix-main-button btn-normal-width"
              onClick={!isLoading ? handleUploadProfilePhoto : () => {}}
            >
              {isLoading ? (
                <>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="light"
                    size="sm"
                  ></Spinner>
                </>
              ) : (
                "Save"
              )}
            </Button>
          ) : null}

          <Button variant="secondary" onClick={() => props.onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadProfileImages;
