import React, { useEffect, useState } from "react";
import { Spinner, Form, Button, Container, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { handlePasswordReset } from "../../api/SignInAndSignUpAPI";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { MdInfoOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";

const PasswordReset = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [newToken, setNewToken] = useState<string>("");
  const [errors, setErrors] = useState({
    passwordError: false,
    confirmPasswordError: false,
    passwordErrorMsg: "",
    confirmPasswordErrorMsg: "",
  });
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const status: string | null = url.searchParams.get("t");

    if (status !== null) {
      setIsSuccess(true);
      setNewToken(status);
    }
  }, []);

  const validate = () => {
    // const passwordValidate = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    let status = {
      passwordError: false,
      confirmPasswordError: false,
      passwordErrorMsg: "",
      confirmPasswordErrorMsg: "",
    };

    if (password === "") {
      status.passwordError = true;
      status.passwordErrorMsg = "Please enter your password";
    } else if (password.length < 8) {
      status.passwordError = true;
      status.passwordErrorMsg =
        "Password must be at least 8 characters";
    } else {
      status.passwordError = false;
      status.passwordErrorMsg = "";
    }

    if (confirmPassword === "") {
      status.confirmPasswordError = true;
      status.confirmPasswordErrorMsg = "Please enter your confirm password";
    } else if (password !== confirmPassword) {
      status.confirmPasswordError = true;
      status.confirmPasswordErrorMsg = "Passwords don't match";
    } else {
      status.confirmPasswordError = false;
      status.confirmPasswordErrorMsg = "";
    }

    if (status.passwordError) {
      setErrors(status);
      return false;
    } else {
      setErrors(status);
      return true;
    }
  };

  const handleForgotPassword = () => {
    if (validate()) {
      setLoading(true);

      const newPasswordData = {
        token: newToken,
        password: password,
      };

      handlePasswordReset(
        newPasswordData,
        (response: any) => {
          setLoading(false);
          toast.dark(
            "Your password has been successfully updated. Please login."
          );
          history.push("/login");
        },
        (error: any) => {
          setLoading(false);
          if (error.response && error.response.data) {
            const errorCode = error.response.data.code;
            setErrors({
              ...errors,
              passwordError: true,
              passwordErrorMsg: "",
              confirmPasswordErrorMsg: "",
            });
            setErrorStatus(
              error.response.data.description
            );
          } else {
            toast.error(`Something went wrong. Please try again. ${error}`);
          }
        }
      );
    }
  };

  return (
    <div className="password-reset-wrapper">
      <Navbar
        alwaysShow={true}
        innerPage={true}
        hideSearch={true}
        navDropshadow={true}
      />

      <Container>
        {isSuccess ? (
          <div className="password-reset-inner">
            <h5>Create a New Password</h5>
            <p>Type and confirm a secure new password for the account.</p>

            {errorStatus !== "" ? (
              <Alert variant="danger">
                <MdInfoOutline /> {errorStatus}
              </Alert>
            ) : null}

            <Form noValidate>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  placeholder="Enter password"
                  name="password"
                  required
                  type="password"
                  autoComplete="new-password"
                  isInvalid={errors.passwordError}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordErrorMsg}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  placeholder="Re-enter Password"
                  name="confirmPassword"
                  required
                  type="password"
                  autoComplete="new-password"
                  isInvalid={
                    errors.confirmPasswordError || errors.passwordError
                  }
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPasswordErrorMsg}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                className="pix-main-button"
                type="button"
                onClick={!isLoading ? handleForgotPassword : () => {}}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="light"
                      size="sm"
                    ></Spinner>
                    Please wait...
                  </>
                ) : (
                  "Change Password"
                )}
              </Button>
            </Form>
          </div>
        ) : (
          <div className="password-reset-inner">
            <div className="password-reset-invalid">
              <h5>Invalid Activation Link</h5>
              <p>
                The activation link you followed is no longer valid. This
                probably becouse the link expired. You can request a new
                activation linkl. Thank you.
              </p>
            </div>
            <Button
              className="pix-main-button"
              type="button"
              onClick={() => history.push("/login")}
            >
              Re-send the activation email
            </Button>
          </div>
        )}
      </Container>
      <Footer simpleFooter={true} />
    </div>
  );
};

export default PasswordReset;
